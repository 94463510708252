import { useEffect, useState } from 'react';
import { CallWithAuth } from '../../../pages/service/apiActions';
import { GET_POLYGONS } from '../../action/apiPath';
import { secondsToHhMm } from '../../utils/helper';
import logo from './../../assets/icons/logo.png';

const Header = ({ selectedDate, showDesc }) => {
  return (
    <div className="d-flex pady-2 padx-2  justify-content-between">
      <div className="col-10 d-flex align-items-end ">
        <div className="d-flex align-items-end">
          <img
            src={logo}
            style={{ height: '4.3rem' }}
            alt="SteelServ"
            className="report-logo pb-1"
          />
          {showDesc && (
            <div
              className=" ps-2  "
              style={{ fontSize: '16px', fontWeight: '800' }}
            >
              Material Movements History
            </div>
          )}
        </div>
      </div>
      <div className="col-2 d-flex justify-content-end  align-items-end">
        {selectedDate?.substring(8, 10)}/{selectedDate?.substring(5, 7)}/
        {selectedDate?.substring(0, 4)}
      </div>
    </div>
  );
};

const MaterialMovementReport = ({ propFilters, selectedDate }) => {
  const search = new URLSearchParams(document.location.search);
  const trucksType = search.get('trucks')
    ? search.get('trucks').split(',')
    : propFilters?.trucks;
  const excavatorsType = search.get('excavators')
    ? search
        .get('excavators')
        .split(',')
        .map((item) => item)
    : propFilters?.excavators;
  const operators = search.get('operators')
    ? search
        .get('operators')
        .split(',')
        .map((item) => item)
    : propFilters?.operators;
  const sourcePoints = search.get('sourcepoint')
    ? search
        .get('sourcepoint')
        .split(',')
        .map((item) => item)
    : propFilters?.sourcepoint;
  const dumpPoints = search.get('dumppoint')
    ? search
        .get('dumppoint')
        .split(',')
        .map((item) => item)
    : propFilters?.dumppoint;
  const materials = search.get('materials')
    ? search
        .get('materials')
        .split(',')
        .map((item) => item)
    : propFilters?.materials;
  const searchQuery = search.get('search')
    ? search.get('search')
    : propFilters?.searchQuery;

  const [loading, setLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [tablesData, setTablesData] = useState({});
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    date: search.get('date') ? search.get('date') : selectedDate,
    searchQuery: searchQuery ?? '',
    trucks: trucksType ?? [],
    excavators: excavatorsType ?? [],
    operators: operators ?? [],
    dumppoint: dumpPoints ?? [],
    sourcepoint: sourcePoints ?? [],
    materials: materials ?? [],
  });
  const [loadWiseData, setLoadWiseData] = useState([]);

  useEffect(() => {
    setFilters({
      date: search.get('date') ? search.get('date') : selectedDate,
      searchQuery: searchQuery ?? propFilters?.searchQuery,
      trucks: trucksType ?? propFilters?.trucks,
      excavators: excavatorsType ?? propFilters?.excavators,
      operators: operators ?? propFilters?.operators,
      dumppoint: dumpPoints ?? propFilters?.dumppoint,
      sourcepoint: sourcePoints ?? propFilters?.sourcepoint,
      materials: materials ?? propFilters?.materials,
    });
  }, [
    propFilters?.searchQuery,
    propFilters?.trucks,
    propFilters?.excavators,
    propFilters?.operators,
    propFilters?.dumppoint,
    propFilters?.sourcepoint,
  ]);

  const formatDate = (timestamp, defaultValue = '00:00 AM') => {
    if (isNaN(Date.parse(timestamp))) return defaultValue;
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getLocalTime = (time) => {
    const timeString = formatDate(time);
    var lastTwoCharacters = timeString.slice(-2); // Get the last two characters
    var modifiedTime =
      timeString.slice(0, -2) + lastTwoCharacters.toLowerCase(); // Convert the last two characters to lowercase and replace them
    return modifiedTime;
  };

  const generateTables = ({ preData, maxHeight = 400 }) => {
    let sourcePoints = Object.keys(preData);

    const remaining = {};

    sourcePoints.forEach((item) => {
      remaining[item] = [];
    });

    let tableHtml = '<div class = "py-2 padx-2">'; // initialize HTML content
    let currentHeight = 0; // Track the current height of the page

    if (Object?.keys(preData)?.length > 0) {
      tableHtml += `<div class="table_manage_prestart mb-3"> <table>`;

      if (currentHeight + 30 < maxHeight) {
        tableHtml +=
          '<tr valign="top" style="border-top: 2px solid #707070; border-bottom: 2px solid #707070;">';
        tableHtml += '<th>Time</th>';
        tableHtml +=
          '<th>Truck/ <br/> <span style = "color :#707070;" >Operator</span></th>';
        tableHtml += '<th>Material</th>';
        tableHtml +=
          '<th>Excavator/ <br/> <span style = "color :#707070;" >Operator</span></th>';
        tableHtml += '<th>Dump point</th>';
        tableHtml += '<th>Delays</th>';
        tableHtml += '<th>Dump Time</th>';
        tableHtml += '</tr>';
        currentHeight += 30;
      }

      for (const [asset, prestart] of Object.entries(preData)) {
        let assetInserted = false;

        prestart.forEach((entry, index) => {
          if (currentHeight + 30 < maxHeight && !assetInserted) {
            if (
              entry?.assetInserted == false ||
              entry?.assetInserted == undefined
            ) {
              tableHtml += `<tr>`;
              tableHtml += `<td colspan="10"><strong>${asset}</strong></td>`;
              tableHtml += '</tr>';
              currentHeight += 30;
              assetInserted = true;
            }
          }

          if (currentHeight + 30 < maxHeight) {
            tableHtml += `<tr>`;
            tableHtml += `<td >${
              entry.loadtime ? getLocalTime(entry.loadtime) : '-'
            }</td>`;
            tableHtml += `<td > ${
              entry.objectTitle ?? 'No Truck'
            } / <br/> <span style = "color :#707070;" >${
              entry.tr_operator ?? '-'
            } </span></th></td>`;
            tableHtml += `<td >${entry?.material ?? '-'}</td>`;
            tableHtml += `<td > ${
              entry.contactTitle ??
              `<span style = "color:#909090">No Exc</span>`
            } / <br/> <span style = "color :#707070;" >${
              entry.ex_operator ?? '-'
            } </span></th></td>`;
            tableHtml += `<td> ${entry?.dumpPoint ?? '-'}</td>`;
            tableHtml += `<td> ${
              entry?.delay_time
                ? entry?.delay_reason
                  ? entry?.delay_reason + ''
                  : ''
                : '-'
            }
            ${
              entry?.delay_time
                ? ' (' + secondsToHhMm(entry?.delay_time) + ')'
                : ''
            }</td>`;
            tableHtml += `<td >${
              entry.dumpTime ? getLocalTime(entry.dumpTime) : '-'
            }</td>`;

            tableHtml += '</tr>';
            currentHeight += 45;
          } else {
            remaining[asset]?.push(entry);
          }
        });
      }

      tableHtml += '</table></div>';
    }

    tableHtml += '</div>';

    return { tableHtml, remaining };
  };

  const checkAllSourcePointHaveEntries = (remaining) => {
    let sourcePoints = Object.keys(remaining);
    let counter = [];
    sourcePoints.forEach((item) => {
      if (!remaining[item]?.length) {
        counter?.push(item);
      }
    });
    if (counter?.length == sourcePoints?.length) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const localTablesVariable = [];
    let remainingData = tablesData;
    while (
      Object?.keys(remainingData)?.length > 0 &&
      checkAllSourcePointHaveEntries(remainingData)
    ) {
      const { tableHtml, remaining } = generateTables({
        preData: remainingData,
        maxHeight: localTablesVariable?.length == 0 ? 500 : 665,
      });
      localTablesVariable.push(tableHtml);
      setTables(localTablesVariable);
      remainingData = remaining;
    }
  }, [tablesData]);

  const fetchLoadWiseData = (loadData) => {
    let loadArray = [];
    loadData?.map((entry) => {
      entry?.loads?.map((load) => {
        loadArray?.push({ sourceName: entry?.name, ...load });
      });
    });
    return loadArray;
  };

  const fetchAllSourceDumps = async () => {
    try {
      const response = await CallWithAuth(
        'GET',
        GET_POLYGONS({ module: 'source', date: selectedDate }),
      );
      const geofenceData = response?.res?.data?.data;

      if (geofenceData?.length > 0) {
        // setData(geofenceData)
        const loadData = fetchLoadWiseData(geofenceData);
        setLoadWiseData(loadData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllSourceDumps();
  }, []);

  useEffect(() => {
    const structurdeData = {};
    data?.forEach((geofence) => {
      const objectTitle = geofence?.name;
      structurdeData[objectTitle] = structurdeData[objectTitle] || [];
      geofence?.loads?.forEach((load) => {
        structurdeData[objectTitle].push(load);
      });
    });
    setTablesData(structurdeData);
  }, [data]);

  const makeSourcePointWiseData = (filteredData) => {
    const originalData = [];

    let groupedData = {};
    filteredData.forEach((item) => {
      const sourceName = item.sourceName;
      if (!groupedData[sourceName]) {
        groupedData[sourceName] = [];
      }
      groupedData[sourceName].push(item);
    });

    for (const sourceName in groupedData) {
      originalData.push({
        name: sourceName,
        loads: groupedData[sourceName],
      });
    }

    return originalData;
  };

  useEffect(() => {
    const handleSearch = ({ data, searchQuery }) => {
      if (searchQuery === '' || !searchQuery) {
        return data;
      }
      return data.filter((item) => {
        return item?.sourceName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
      });
    };

    const handleTruckName = ({ data, trucks }) => {
      if (trucks && trucks.length > 0) {
        const filteredData = data.filter((d) =>
          trucks?.includes(d.objectTitle),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const handleExcavatorName = ({ data, excavators }) => {
      if (excavators && excavators.length > 0) {
        const filteredData = data.filter((d) =>
          excavators?.includes(d?.contactTitle ?? '-'),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const handleOperators = ({ data, operators }) => {
      if (operators && operators.length > 0) {
        const filteredData = data.filter(
          (item) =>
            operators.includes(item.tr_operator) ||
            operators.includes(item.ex_operator),
        );

        return filteredData;
      } else {
        return data;
      }
    };

    const handleSourcePoint = ({ data, sourcepoint }) => {
      if (sourcepoint && sourcepoint.length > 0) {
        const filteredData = data.filter((d) =>
          sourcepoint?.includes(d?.sourceName),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const handleDumpPoint = ({ data, dumppoint }) => {
      if (dumppoint && dumppoint.length > 0) {
        const filteredData = data.filter((d) =>
          dumppoint?.includes(d?.dumpPoint),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const filterData = () => {
      let unFilteredData = loadWiseData;
      unFilteredData = handleSearch({
        data: unFilteredData,
        searchQuery: filters?.searchQuery,
      });
      unFilteredData = handleTruckName({
        data: unFilteredData,
        trucks: filters?.trucks,
      });
      unFilteredData = handleExcavatorName({
        data: unFilteredData,
        excavators: filters?.excavators,
      });
      unFilteredData = handleOperators({
        data: unFilteredData,
        operators: filters?.operators,
      });
      unFilteredData = handleSourcePoint({
        data: unFilteredData,
        sourcepoint: filters?.sourcepoint,
      });
      unFilteredData = handleDumpPoint({
        data: unFilteredData,
        dumppoint: filters?.dumppoint,
      });
      setData(makeSourcePointWiseData(unFilteredData));
    };

    try {
      filterData();
    } catch (error) {
      console.log('-----', error);
    }
  }, [
    filters?.searchQuery,
    filters?.trucks,
    filters?.excavators,
    filters?.operators,
    filters?.dumppoint,
    filters?.sourcepoint,
    filters?.date,
    loadWiseData,
  ]);

  return (
    <>
      {loading ? (
        <div>Loading....</div>
      ) : (
        <div id="print-report" className="content-loaded">
          <div style={{ position: 'relative' }} className="report-view-pdf p-0">
            <Header
              selectedDate={
                search.get('date') ? search.get('date') : selectedDate
              }
            />

            <div
              className="pb-2 padx-2"
              style={{ fontSize: '16px', fontWeight: '800' }}
            >
              Material Movements History
            </div>

            <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
              <div
                style={{ background: '#FAFAFA' }}
                className="d-flex py-2 padx-2 border-y "
              >
                <div className=" col-12 d-flex flex-column">
                  <div className=" d-flex">
                    <strong className="col-2">Source Points:</strong>
                    <div className="col-10">
                      <strong>
                        {filters?.sourcepoint?.length > 0
                          ? filters?.sourcepoint?.join(', ')
                          : 'All'}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Dump Points: </strong>
                    <div className="col-6">
                      <strong>
                        {filters?.dumppoint?.length > 0
                          ? filters?.dumppoint?.join(', ')
                          : 'All'}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Trucks:</strong>
                    <div className="col-6">
                      <strong>
                        {filters?.excavators?.length > 0
                          ? filters?.trucks?.join(', ')
                          : 'All'}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Excavators:</strong>
                    <div className="col-6">
                      <strong>
                        {filters?.excavators?.length > 0
                          ? filters?.excavators?.join(', ')
                          : 'All'}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Operators:</strong>
                    <div className="col-6">
                      <strong>
                        {filters?.operators?.length > 0
                          ? filters?.operators?.join(', ')
                          : 'All'}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Materials:</strong>
                    <div className="col-6">
                      <strong>
                        {filters?.materials?.length > 0
                          ? filters?.materials?.join(', ')
                          : 'All'}{' '}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="  mt-3  padx-2 ">
                <div style={{ fontSize: '14px', fontWeight: '600' }}>
                  Details
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: tables[0] }}></div>
            </div>

            <div
              style={{ position: 'absolute ', bottom: 10, right: 10 }}
              className="d-flex "
            >
              Page: 1/{tables?.length}
            </div>
          </div>

          {tables?.map((table, index) => {
            if (index > 0) {
              return (
                <div
                  style={{ position: 'relative' }}
                  className="report-view-pdf p-0"
                >
                  <Header
                    showDesc
                    selectedDate={
                      search.get('date') ? search.get('date') : selectedDate
                    }
                  />
                  <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: tables[index] }}
                    ></div>
                  </div>
                  <div
                    style={{ position: 'absolute ', bottom: 10, right: 10 }}
                    className="d-flex "
                  >
                    Page: {index + 1}/{tables?.length}
                  </div>
                </div>
              );
            }
          })}

          {search.get('date') && (
            <div
              style={{ position: 'relative' }}
              className="report-view-pdf p-0"
            >
              <Header
                showDesc
                selectedDate={
                  search.get('date') ? search.get('date') : selectedDate
                }
              />

              <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: tables[tables?.length - 1],
                  }}
                ></div>
              </div>
              <div
                style={{ position: 'absolute ', bottom: 10, right: 10 }}
                className="d-flex "
              >
                Page: {tables?.length}/{tables?.length}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MaterialMovementReport;
