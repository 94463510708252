import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { BinWithPickup } from '../utils/types';

type ScrapBinsContextType = {
  bins: BinWithPickup[];
  setBins: Dispatch<SetStateAction<BinWithPickup[]>>;
};

const ScrapBinsContext = createContext<ScrapBinsContextType | undefined>(
  undefined,
);

type ScrapBinsProviderProps = {
  children: ReactNode;
};

export const ScrapBinsProvider: FC<ScrapBinsProviderProps> = ({ children }) => {
  const [bins, setBins] = useState<BinWithPickup[]>([]);

  return (
    <ScrapBinsContext.Provider value={{ bins, setBins }}>
      {children}
    </ScrapBinsContext.Provider>
  );
};

export const useScrapBins = () => {
  const context = useContext(ScrapBinsContext);
  if (!context) {
    throw new Error('useScrapBins must be used within a ScrapBinsProvider');
  }
  return context;
};
