import React, { useState, useEffect } from 'react';
import BasicButton from '../BasicButton';
import ImageUploader from '../ImageUploader';

const AddSign = ({
  closeModal = () => {},
  onChange = () => {},
  props = {},
}) => {
  const [data, setData] = useState({
    signedName: props?.signedName,
    file: props?.signature,
    f: '',
  });
  const [file, setFile] = useState('');
  const handleCancelClick = () => {
    closeModal(false);
  };

  useEffect(() => {
    console.log(file);
  }, [file]);
  const handleSaveClick = () => {
    onChange({ ...data, f: file });
    closeModal(false);
  };

  return (
    <div
      style={{
        marginTop: '50px',
        borderRadius: '10px',
        width: '365px',
        boxShadow: '0 0 10px rgba(0,0,0,0.15)',
      }}
      className="pdf-view d-flex justify-content-center p-4 align-items-start flex-column  "
    >
      <div className=" d-flex w-100 justify-content-start align-items-start flex-column">
        <div className="mb-3 ">
          <strong>Add a Signature</strong>
        </div>
        <div className="mb-2">Signature's name*</div>
        <input
          type="text"
          className="w-100"
          placeholder="Your Name"
          style={{ border: '1px solid #EBEBEB' }}
          value={data?.signedName}
          onChange={(e) => {
            setData({ ...data, signedName: e.target.value });
          }}
        />
      </div>
      <div>
        <ImageUploader
          selectedImage={data?.file}
          setSelectedImage={(imageData) =>
            setData({ ...data, file: imageData })
          }
          setFile={(file) => {
            setFile(file);
          }}
        />
      </div>
      <div className="mt-3  d-flex w-100 justify-content-end align-items-end ">
        <BasicButton
          name="Cancel"
          className="me-3"
          style={{ backgroundColor: 'white', color: 'black' }}
          onClick={handleCancelClick}
        >
          Cancel
        </BasicButton>
        <BasicButton
          name="Save"
          disabled={!(data?.signedName && data?.file)}
          style={{ backgroundColor: 'black', color: 'white' }}
          onClick={handleSaveClick}
        >
          Save
        </BasicButton>
      </div>
    </div>
  );
};

export default AddSign;
