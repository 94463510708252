import React, { useCallback, useEffect, useState } from 'react';
import PieChartGraph from '../PieChart';
import { STATUS_DATA, ZONE_DATA } from '../../utils/constants';
import { Image } from 'react-bootstrap';
import FailedIcon from '../../assets/icons/failed-icon.svg';
import PausedIcon from '../../assets/icons/paused-icon.svg';
import IncompleteIcon from '../../assets/icons/incomplete_icon.svg';

const SummaryWidget = ({ data }) => {
  let [chartData, setChartData] = useState([]);
  const mapStatusData = (data) => {
    let zoneData = [];
    Object.entries(STATUS_DATA)?.forEach(([key, zone]) => {
      if (data[zone?.name]) {
        zoneData.push(data[zone.name]);
      } else {
        zoneData.push(0);
      }
    });

    setChartData(zoneData);
  };

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        mapStatusData({
          passed: data?.passed,
          failed: data?.failed,
          incomplete: data?.incomplete,
        });
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="widget-container4 triangle-div  p-3">
      <div className="header d-flex justify-content-between align-items-start py-0 px-3">
        <div>SUMMARY</div>
      </div>
      <div className="body d-flex justify-content-between">
        <div style={{ minWidth: '240px' }} className="d-flex">
          <div className="d-flex flex-column justify-content-center align-items-center px-3">
            <p
              style={{
                font: ' normal normal  35px/47px Open Sans',
                fontSize: '35px',
                fontWeight: 'bolder',
                color: '#9E2929',
              }}
            >
              {chartData[0]}
            </p>

            <Image style={{ height: '45px' }} src={FailedIcon} alt="down" />

            <p className="text-2">Failed</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center px-3">
            <p
              style={{
                color: '#2A7B39',
                font: ' normal normal  35px/47px Open Sans',
                fontSize: '35px',
                fontWeight: 'bolder',
              }}
            >
              {chartData[1]}
            </p>

            <Image style={{ height: '45px' }} src={PausedIcon} alt="down" />

            <p className="text-2">Passed</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center px-3">
            <p
              style={{
                color: '#325D88',
                font: ' normal normal  35px/47px Open Sans',
                fontSize: '35px',
                fontWeight: 'bolder',
              }}
            >
              {chartData[2]}
            </p>

            <Image style={{ height: '45px' }} src={IncompleteIcon} alt="down" />

            <p className="text-2">Incomplete</p>
          </div>
        </div>

        <div className="body w-100  d-flex flex-column align-items-center justify-content-between">
          {chartData[0] + chartData[1] + chartData[2] ? (
            <PieChartGraph
              key={1}
              data={chartData}
              outerRadius={58}
              chartData={[
                { name: 'passed', value: chartData[1] },
                { name: 'failed', value: chartData[0] },
                { name: 'incomplete', value: chartData[2] },
              ]}
            />
          ) : (
            <div style={{ marginTop: '80px', color: '#07070788' }}>
              No Data Found
            </div>
          )}

          <div className="mt-2" style={{ fontSize: '12px', color: '#707070' }}>
            All equipments
          </div>
        </div>

        <div className="zone-container ">
          <div>
            {Object.keys(STATUS_DATA).map((data) => {
              return (
                <span key={data}>
                  <div className=" d-flex flex-row justify-content-end">
                    <div
                      className="circle col-4"
                      style={{ backgroundColor: STATUS_DATA[data].color }}
                    ></div>{' '}
                    <div className="col-8 "> {STATUS_DATA[data]?.label}</div>
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryWidget;
