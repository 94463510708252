import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { CgClose } from 'react-icons/cg';
import * as toast from '../../../dustControl/components/toast';

import truck from './../../assets/images/trucks.svg';
import './index.css';
import _ from 'lodash';
import { GET_POLYGONS, GET_TRUCK_LOADS } from '../../action/apiPath';
import { CallWithAuth } from '../../../pages/service/apiActions';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';

const TruckLoads = ({
  setSelectedTruckForLoadMarkers,
  selectedTruckForLoadMarkers,
  onClose,
  getMaterialColor,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const formatDataIntoArray = (data) => {
    let entries = [];
    Object.entries(data)?.map(([key, value], index) => {
      let unknownLoads = [];
      let knownLoads = [];
      let materials = [];
      value?.map((load) => {
        if (!(load?.sourcePoint || load?.dumpPoint)) {
          unknownLoads.push(load);
        } else {
          knownLoads?.push(load);
        }
        if (load?.material) {
          if (!materials?.includes[load?.material]) {
            materials?.push(load?.material);
          }
        }
      });
      let entry = {
        truck: key,
        totalLoads: value?.length,
        unknownLoads: unknownLoads,
        knownLoads: knownLoads,
        materials: materials,
      };
      entries.push(entry);
    });
    return entries;
  };

  const fetchAllTruckLoads = async () => {
    try {
      const response = await CallWithAuth(
        'GET',
        GET_TRUCK_LOADS({ date: moment().format('yyyy-MM-DD') }),
      );
      const loadsData = response?.res?.data?.data;
      const formttedData = formatDataIntoArray(loadsData);
      setData(formttedData);
    } catch (error) {
      console.log('error', error);
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchAllTruckLoads();
  }, []);

  const filterData = (unFilteredData) => {
    if (searchQuery === '') {
      return unFilteredData;
    }
    return unFilteredData.filter((item) => {
      return item?.truck?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
  };

  useEffect(() => {
    let filteredData = data;
    filteredData = filterData(filteredData);
    setFilteredData(filteredData);
  }, [searchQuery, data]);

  return (
    <div className="modal-container p-0">
      <div style={{ borderRadius: '0.8rem 0.8rem 0 0', background: '#FAFAFA' }}>
        <div style={{ position: 'absolute', right: 0 }}>
          <CgClose
            onClick={() => {
              setSelectedTruckForLoadMarkers(null);
              onClose();
            }}
            style={{
              cursor: 'pointer',
              borderRight: '0px',
            }}
            size={'2.3rem'}
            className="px-2"
          />
        </div>
        <div
          className="d-flex pt-4 pb-2"
          style={{
            font: 'normal normal 600 1rem/1.375rem Open Sans',
            marginLeft: '1.5rem',
          }}
        >
          <Image
            src={truck}
            style={{
              marginBottom: '0.625rem',
              height: '1.7rem',
              width: '2rem',
            }}
          />
          <div style={{ marginLeft: '0.879rem', marginBottom: '0.625rem' }}>
            TRUCKS
          </div>
        </div>
      </div>

      <div style={{ marginLeft: '1.5rem', marginRight: '1.5rem' }}>
        <div
          style={{
            width: '100%',
            marginTop: '1.25rem',
            marginBottom: '0.3rem',
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #dde5f3',
                padding: '0.25rem 0.8rem',
                borderRadius: '15px',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <i
                style={{ fontSize: '0.8rem', color: '#707070' }}
                className="mr-2 fa-regular fa-magnifying-glass"
              ></i>
              <div style={{ width: '0.5rem' }}></div>
              <input
                type="search"
                className="shadow-none w-100"
                value={searchQuery}
                placeholder="Search for a truck"
                style={{
                  border: 'none',
                  outline: '0px',
                  font: 'normal normal normal 0.8rem Open Sans',
                }}
                onChange={(e) => setSearchQuery(e?.target?.value)}
              />
            </div>
          </div>
        </div>
        <table
          style={{
            borderTop: '0.1rem solid #707070',
            borderBottom: '0.1rem solid #707070',
          }}
          className="mt-4 custom-table-truck-loads"
        >
          <thead>
            <th></th>
            <th>Truck</th>
            <th>Materials</th>
            <th>Truck Loads</th>
            <th>Unknown</th>
          </thead>
        </table>
        <table style={{ width: '100%' }} className="custom-table-truck-loads">
          <div
            className="scroll-div"
            style={{
              position: 'relative',
              maxHeight: 'calc(100vh - 26.5rem)',
              minHeight: 'calc(100vh - 26.5rem)',
              overflowY: 'scroll',
            }}
          >
            {filteredData?.length == 0 && (
              <div className="d-flex text-muted mt-2 justify-content-center align-items-center">
                No Record Found
              </div>
            )}

            <tbody className="">
              {filteredData?.map((truck, index) => (
                <tr
                  style={{
                    backgroundColor:
                      selectedTruckForLoadMarkers == truck?.truck
                        ? '#05795F1A'
                        : '#FFF',
                    borderBottom:
                      selectedTruckForLoadMarkers == truck?.truck
                        ? '0.1rem solid #05795F'
                        : ' 0px solid #EBEBEB ',
                    borderTop:
                      selectedTruckForLoadMarkers == truck?.truck
                        ? '0.1rem solid #05795F'
                        : ' 1px solid #EBEBEB ',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTruckForLoadMarkers(truck?.truck);
                  }}
                  key={index}
                >
                  <td></td>
                  <td>{truck?.truck}</td>
                  <td>
                    {truck?.materials?.length ? (
                      <a href="#" className={`my-truck-element-${index}`}>
                        {truck?.materials?.length}
                      </a>
                    ) : (
                      <div>-</div>
                    )}
                    <Tooltip
                      style={{
                        backgroundColor: '#fff',
                        color: 'black',
                        boxShadow: '1px 6px 10px #00000029',
                      }}
                      anchorSelect={`.my-truck-element-${index}`}
                      place="top"
                    >
                      <ul className="px-2 py-0 m-0">
                        {truck?.materials?.map((e, i) => (
                          <li
                            className="d-flex justify-content-start align-items-center"
                            style={{ fontSize: '0.8rem' }}
                            key={i}
                          >
                            <span
                              className="mm-dot-1"
                              style={{ backgroundColor: getMaterialColor(e) }}
                            ></span>{' '}
                            {e}
                          </li>
                        ))}
                      </ul>
                    </Tooltip>
                  </td>

                  <td>{truck?.totalLoads}</td>
                  <td>{truck?.unknownLoads?.length}</td>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>

      <div
        style={{
          borderRadius: '0 0 0.8rem 0.8rem ',
          backgroundColor: '#F9FAFB',
        }}
      >
        <div
          className="pb-2 px-4 d-flex w-100 justify-content-end"
          style={{ fontSize: '0.9rem', color: '#387FF3' }}
        >
          {selectedTruckForLoadMarkers && (
            <div
              onClick={() => {
                setSelectedTruckForLoadMarkers(null);
              }}
            >
              Show all loads
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TruckLoads;
