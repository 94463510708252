import { FunctionComponent } from 'react';

type HoursglassProps = {
  height: number;
  width: number;
};
export const HourglassIcon: FunctionComponent<HoursglassProps> = (
  props: HoursglassProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.75 46"
    data-name="Group 57679"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          stroke="#2b2d2f"
          strokeWidth={2.5}
          d="M0 0h28.75v46H0z"
          data-name="Rectangle 21173"
        />
      </clipPath>
    </defs>
    <g
      fill="none"
      stroke="#2b2d2f"
      strokeWidth={2.5}
      clipPath="url(#a)"
      data-name="Group 57669"
    >
      <path
        d="M24.917 40.25v-6.011a8.625 8.625 0 0 0-2.526-6.1l-6.1-6.1 6.1-6.1a8.625 8.625 0 0 0 2.526-6.1V5.75"
        data-name="Path 34825"
      />
      <path
        d="M3.833 5.75v4.094a8.625 8.625 0 0 0 2.526 6.1l6.1 6.1-6.1 6.1a8.625 8.625 0 0 0-2.526 6.095v6.011"
        data-name="Path 34826"
      />
      <path
        d="M27.791.958H.958v2.875A1.916 1.916 0 0 0 2.875 5.75h23a1.916 1.916 0 0 0 1.917-1.917Z"
        data-name="Path 34827"
      />
      <path
        d="M25.875 40.25h-23a1.916 1.916 0 0 0-1.917 1.917v2.875h26.833v-2.875a1.916 1.916 0 0 0-1.916-1.917Z"
        data-name="Path 34828"
      />
    </g>
  </svg>
);
