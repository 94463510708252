export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const ZONE_DATA = {
  ZONE1: { color: '#138BAA', name: 'zone1', label: 'Zone 1' },
  ZONE2: { color: '#FAF265', name: 'zone2', label: 'Zone 2' },
  ZONE3: { color: '#4C4B66', name: 'zone3', label: 'Zone 3' },
  ZONE4: { color: '#FFC850', name: 'zone4', label: 'Zone 4' },
  ZONE5: { color: '#00C48C', name: 'zone5', label: 'Zone 5' },
  ZONE6: { color: '#6C63FF', name: 'zone6', label: 'Zone 6' },
};

export const ENDPOINTS = {
  login: 'auth/login',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'users/reset-password',
  getZones: 'zone/',
  getDailyZones: 'jobs/get-zones',
  getActiveOperators: 'users/active-operators',
  logout: 'auth/logout',
  editHazards: 'zone/edit-hazard',
  getTrucks: 'trucks',
  changeZoneStatus: 'zone/change-status',
  getAllUsers: 'users/users-list',
  addUser: 'users/add-user',
  resendInvitation: 'users/resend-invitation',
  cancelInvitation: 'users/cancel-invitation',
  editUser: 'users/edit-user',
  deactivateDeleteUsers: 'users/deactivate-delete',
  getDepartments: 'departments/',
  getLicenses: 'users/licenses',
  getNewToken: 'auth/refresh-token',
  setTimer: (zone: string, reminderTime: string) =>
    `zone/set-timer?zone=${zone}&timer=${reminderTime}`,
  getWaterConsumption: 'water-usage',
  changePassword: 'users/change-password',
  authChangePassword: 'auth/change-password',
  dismissAlert: (zone: string) => `zone/dismiss-alert?zone=${zone}`,
  getJobs: 'jobs/jobs-list',
  getJob: 'jobs/get-job',
  getJobDailyReport: 'reports/jobs-daily-report',
  getZonesReport: 'reports/zone-daily-report',
  getSingleJob: 'jobs/single-job',
  getRefills: 'waterRefill/',
  validateToken: 'auth/validate-token',
  generatePin: 'users/generate-pin',
  getPrestartChecks: 'prestart/',
  getsummarypdf: 'prestart/summary-pdf',
  getMaterialPdf: 'prestart/material-pdf',
  getImage: 'prestart/image',
  getReportPdf: 'prestart/report-pdf',
  signPrestartCheck: 'prestart/sign',
  getCheckPoints: 'checkpoints/',
  getSteelServTrucks: 'trucks/steel-serv-trucks',
  updateEngineHours: 'engine-hour/update',
  getEngineHours: '/engine-hours/calculate/multiple-trucks',
};

export const SOCKETS = {
  water: 'waterUsage',
  zones: 'zoneStatus',
  trucks: 'truckStatus',
};

export const headerText = [
  'User Name',
  'Status',
  'User Type',
  'Email',
  'Department',
  'Equipment License',
  'PIN No',
  'Added by',
  'Actions',
];

export const headerText2 = [
  'Asset ID',
  'Department',
  'Status',
  'Ref No.',
  'Operator',
  'Login/Engin On',
  'Check Time',
  'Failed Checks',
  'Engine Hours',
  "Supervisor's Signature",
];
export const headerText3 = ['', 'Checklist', 'Answer'];
export const UserType = {
  admin: { value: 'Admin', name: 'Super Admin' },
  supervisor: { value: 'Supervisor', name: 'Supervisor' },
  operator: { value: 'Operator', name: 'Operator' },
};
export const UserStatus = {
  invited: 'Invited',
  inactive: 'Inactive',
  active: 'Active',
};

export const ZoneStatus = {
  Open: 'Open',
  Completed: 'Completed',
  Alerted: 'Alerted',
  Progress: 'In progress',
};
export const sections = {
  100: 'Dust Control',
  200: 'Material Movements',
  300: 'Maintenance',
  400: 'Scrap Bins',
  500: 'KPI Dashboard',
  600: 'Pre-Start Checks',
  700: 'Manage Users',
  800: 'Asset Tracking',
};

// The text here is displayed on the topmost header (in line with the section dropdown)
export const subSections: Record<number, string[]> = {
  100: [
    'Dust control progress',
    'Reports',
    'Jobs history',
    'Water towers interactions',
  ],
  200: ['KPI', 'Source & Dump Points', 'Shift Loader'],
  300: ['Workshop', 'Engine Hours'],
  400: [],
  500: [
    'Asset Tracking',
    'Scrap Bins',
    'Dust Control',
    'Material Movements',
    'Pre-Start Checks',
    'Maintenance',
    'Utilisation',
    'Reports',
  ],
  600: [],
  700: [],
  800: ['Vehicles'],
};

export const links = {
  100: '/dust-control',
  200: '/material-movements',
  300: '/maintenance',
  400: '/scrap-bins',
  500: '/kpi-dashboard',
  600: '/pre-start-check',
  700: '/user-management',
  800: '/asset-tracking',
};

export const desiredOrder = [500, 400, 100, 200, 600, 800, 300, 700];

export const userType = {
  Supervisor: 'Supervisor',
  Admin: 'Super Admin',
  Operator: 'Operator',
};
