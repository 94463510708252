import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import SubHeader from '../../components/SubHeader';
import { Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingScreen from '../../components/loading';
import ModalView from '../../components/ModalView';
import { formatDateToDDMMYYYY, groupByDate } from '../../utils/helper';
import moment from 'moment';
import * as toast from '../../components/toast';
import { API_METHODS, ENDPOINTS } from '../../utils/constants';
import { getTime } from '../../utils/helper';
import BasicButton from '../../components/BasicButton';
import { UserContext } from '../../../pages/service/userContext';
import { useDebounce } from '../../../hooks/useDebounce';

const tableHeader = [
  'Time',
  'Truck',
  'Job Ref No.',
  'Tower',
  'Water consumption',
  'Operator',
];

const WaterTowerInteraction = () => {
  const { ApiHandler } = useContext(UserContext);
  const [filteredData, setFilteredData] = useState({
    trucks: [],
    startDate: '',
    endDate: '',
    towers: [],
  });
  const [searchText, setSearchText] = useState('');
  const [waterTowerData, setWaterTowerData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offsetDate, setOffsetDate] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const isFirstLoad = useRef(true); // used to get skip the offset on first render
  const [showDownload, setShowDownload] = useState(false);
  const [loading, setLoading] = useState(false);

  const getRefills = useCallback(
    async (controller, offsetEndDate, search, appliedFilters = {}) => {
      offsetEndDate = offsetEndDate ?? moment();
      let offsetStartDate = moment(offsetEndDate).subtract(6, 'd');
      try {
        setLoading(true);
        if (
          appliedFilters.startDate &&
          offsetEndDate < moment(appliedFilters.startDate)
        )
          return setHasMore(false);
        if (
          appliedFilters.startDate &&
          offsetStartDate < moment(appliedFilters.startDate)
        ) {
          offsetStartDate = moment(appliedFilters.startDate);
        }
        if (
          appliedFilters.endDate &&
          offsetEndDate > moment(appliedFilters.endDate)
        ) {
          offsetEndDate = moment(appliedFilters.endDate);
        }
        const response = await ApiHandler({
          endPoint: ENDPOINTS.getRefills,
          method: API_METHODS.POST,
          reqParam: {
            ...appliedFilters,
            startDate: offsetStartDate,
            endDate: offsetEndDate,
            search,
          },
          signal: controller,
        });
        setWaterTowerData((prevData) => {
          const currentData = [...prevData];
          response.data.forEach((item) => {
            const existingItem = currentData.find(
              (dataItem) => dataItem._id === item._id,
            );
            if (!existingItem) {
              currentData.push(item);
            }
          });
          return currentData;
        });

        if (response.status === 202) {
          setHasMore(false);
        } else {
          setHasMore(true);
          isFirstLoad.current = false;
          if (response.data.length === 0)
            await getRefills(
              controller,
              moment(offsetStartDate).subtract(1, 'd'),
              search,
              appliedFilters,
            );
        }
        setLoading(false);
        setOffsetDate(moment(offsetStartDate).subtract(1, 'd'));
      } catch (error) {
        toast.error(error?.message);
        setLoading(false);
      }
    },
    [ApiHandler],
  );

  const fetchMore = async () => {
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    await getRefills(
      newAbortController.signal,
      offsetDate,
      searchText,
      filteredData,
    );
  };

  // handle initial data load
  useEffect(() => {
    const controller = new AbortController();
    if (isFirstLoad.current) {
      //pass in start date
      getRefills(controller.signal, moment());
    }
    return () => {
      //like to point to state?
      controller.abort();
    };
  }, [getRefills]);

  // ensure enough data is displayed
  useEffect(() => {
    const controller = new AbortController();
    if (waterTowerData.length < 15 && hasMore && !loading && offsetDate) {
      getRefills(controller.signal, offsetDate, searchText, filteredData);
    }
  }, [
    getRefills,
    waterTowerData,
    hasMore,
    loading,
    offsetDate,
    filteredData,
    searchText,
  ]);

  const debouncedRequest = useDebounce(() => {
    getRefills(
      abortController.signal,
      filteredData.endDate ? moment(filteredData.endDate) : moment(),
      searchText,
      filteredData,
    );
  });

  const onSearchChange = (event) => {
    const { value } = event.target;
    if (abortController) {
      abortController.abort();
    }
    setOffsetDate(null); // prevents useEffect from running when search text is changed
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setSearchText(value);
    setWaterTowerData([]);
    debouncedRequest();
  };
  const onFilterChange = (filterData) => {
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setOffsetDate(null); // prevents useEffect from running when filters are changed
    setAbortController(newAbortController);
    setWaterTowerData([]);
    setFilteredData(filterData);
    getRefills(
      newAbortController.signal,
      filterData.endDate ? moment(filterData.endDate) : moment(),
      searchText,
      filterData,
    );
  };

  const groupedData = useMemo(
    () => groupByDate(waterTowerData, 'start_time'),
    [waterTowerData],
  );

  return (
    <div className="water-tower-container">
      <div className="align-self-end mb-3">
        <SubHeader
          waterTowerFilter
          SubHeaderVisible={true}
          toShowFilter={true}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          onSearchChange={onSearchChange}
          onSaveClick={onFilterChange}
          showDownload
          onDownloadClick={() => setShowDownload(true)}
        />
      </div>
      <Table>
        <thead className="water-tower-table-header">
          <tr>
            {tableHeader.map((item, index) => (
              <th
                align="left"
                className={`table-text text-start col${index}-width`}
                key={item}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
      </Table>

      <div id="job-history-report-container">
        <InfiniteScroll
          className="infiniteScroll"
          scrollableTarget={'job-history-report-container'}
          dataLength={waterTowerData.length}
          next={fetchMore}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>End of available data reached</b>
            </p>
          }
          hasMore={hasMore}
          loader={loading && <LoadingScreen />}
        >
          <Table
            responsive
            className="table-hover table overflow-scroll table-fixed"
          >
            <tbody>
              {Object.keys(groupedData).map((date) => (
                <Fragment key={date}>
                  <tr key={date}>
                    <td colSpan={tableHeader.length}>
                      <p className="water-tower-date-container">
                        {formatDateToDDMMYYYY(new Date(date))}
                      </p>
                    </td>
                  </tr>
                  {groupedData[date].map((refill) => (
                    <tr key={refill._id} className="water-tower-row">
                      <td>{getTime(refill.start_time)}</td>
                      <td>{refill.truck.name}</td>
                      <td>{refill.job_ref_no ?? '-'}</td>
                      <td>{refill.tower === -1 ? 'Unknown' : refill.tower}</td>
                      <td>{refill.water_used} L</td>
                      <td>
                        {refill.operator?.first_name ?? '-'}{' '}
                        {refill.operator?.last_name ?? ''}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>
      {showDownload && (
        <ModalView
          onClose={() => setShowDownload(false)}
          backgroundColor={'white'}
          hide
        >
          <div
            style={{
              background: 'white',
              width: '400px',
              height: '400px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              borderRadius: '20px',
            }}
          >
            <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
              Coming soon...
            </span>

            <div>
              <BasicButton
                outlined={true}
                onClick={() => setShowDownload(false)}
              >
                Cancel
              </BasicButton>
            </div>
          </div>
        </ModalView>
      )}
    </div>
  );
};

export default WaterTowerInteraction;
