import {
  BinLoad,
  DateRange,
  SelectedFilters,
  FilterSections,
  BinPickupJob,
  JobStatus,
  JobTypeFilters,
} from './types';
import {
  BinHistory,
  BinPickup,
  PickupStatus,
  PickupType,
} from '../../utils/types';
import { searchAllProperties } from '../../utils/helper';
import { filter, forEach, isUndefined, map } from 'lodash';
import { PieChartLegendData } from '../PieChart/types';

export const appendBinHistoriesToState = (
  setBinHistories: React.Dispatch<React.SetStateAction<BinHistory[]>>,
  apiReponseBinHistories: BinHistory[],
): void => {
  setBinHistories((currentBinHistories: BinHistory[]) => [
    ...currentBinHistories,
    ...getUniqueBinHistories(currentBinHistories, apiReponseBinHistories),
  ]);
};

/*
  On hot reloads, of the components where the hook state values are retained calling the 
  bin history api again adds duplicate values to bin history. The blow function makes  sure that 
  only unique binHisotry data is added
*/
const getUniqueBinHistories = (
  currentBinHistories: BinHistory[],
  apiReponseBinHistories: BinHistory[],
): BinHistory[] =>
  apiReponseBinHistories.filter(
    (incomingBinHistory: BinHistory) =>
      !currentBinHistories.some(
        (binHistory: BinHistory) => incomingBinHistory.date === binHistory.date,
      ),
  );

/*
 The below function computed the bin loads for each bin fetched in the bin history
 This functionality considers a bin load if a pickEvent exists in the binPickup object
 of a bin
*/
export const getBinLoads = (binHistories: BinHistory[]): BinLoad[] => {
  const binPickups: BinPickup[] = [];
  binHistories.forEach((binsHistory: BinHistory) => {
    binPickups.push(...binsHistory.binHistories);
  });

  /*
   The below array after consturction would look somthing like this
   [{binName: '21', scrapType: 'S01', binPickups: []},{.....}]
  */
  const binLoads: BinLoad[] = [];

  binPickups.forEach((binPickup: BinPickup) => {
    const correspondingBinLoadForBinPickup: BinLoad | undefined = binLoads.find(
      (binLoad: BinLoad) => binLoad.binName === binPickup.bin.name,
    );
    /*
     When the details of the bin being iterated is not yet in the binLoad array, it needs to be directly 
     pushed into the binLoads array. The blow condition checks that. The else condition does the opposite
     if the bin already exists in the bin loads array, the number of binPickup events are increased
    */
    if (isUndefined(correspondingBinLoadForBinPickup)) {
      binLoads.push({
        binName: binPickup.bin.name,
        scrapType: binPickup.bin.scrapType,
        binPickups: [{ ...binPickup }],
      });
    } else {
      correspondingBinLoadForBinPickup.binPickups.push(binPickup);
    }
  });
  return binLoads;
};

export const getTruckJobs = (
  allBinHistories: BinHistory[],
): PieChartLegendData[] => {
  let jobCountsByTruck: { [key: string]: number } = {};
  forEach(allBinHistories, (binHistory) => {
    forEach(binHistory.binHistories, (binPickup) => {
      jobCountsByTruck[binPickup.truckName as string] =
        (jobCountsByTruck[binPickup.truckName] || 0) + 1;
    });
  });

  return map(jobCountsByTruck, (value, key) => ({
    key,
    value: value.toString(),
  }));
};

export const getCompleteJobs = (
  allBinHistories: BinHistory[],
): PieChartLegendData[] => {
  let jobCountsByTruck: { [key: string]: number } = {};
  forEach(allBinHistories, (binHistory) => {
    forEach(binHistory.binHistories, (binPickup) => {
      if (binPickup.status === PickupStatus.COMPLETE)
        jobCountsByTruck[binPickup.truckName as string] =
          (jobCountsByTruck[binPickup.truckName] || 0) + 1;
    });
  });

  return map(jobCountsByTruck, (value, key) => ({
    key,
    value: value.toString(),
  }));
};

export const getIncompleteJobs = (
  allBinHistories: BinHistory[],
): PieChartLegendData[] => {
  let jobCountsByTruck: { [key: string]: number } = {};
  forEach(allBinHistories, (binHistory) => {
    forEach(binHistory.binHistories, (binPickup) => {
      if (binPickup.status === PickupStatus.INCOMPLETE)
        jobCountsByTruck[binPickup.truckName as string] =
          (jobCountsByTruck[binPickup.truckName] || 0) + 1;
    });
  });

  return map(jobCountsByTruck, (value, key) => ({
    key,
    value: value.toString(),
  }));
};

export const getScheduledJobs = (
  allBinHistories: BinHistory[],
): PieChartLegendData[] => {
  let jobCountsByTruck: { [key: string]: number } = {};
  forEach(allBinHistories, (binHistory) => {
    forEach(binHistory.binHistories, (binPickup) => {
      if (binPickup.type === PickupType.SCHEDULED)
        jobCountsByTruck[binPickup.truckName as string] =
          (jobCountsByTruck[binPickup.truckName] || 0) + 1;
    });
  });

  return map(jobCountsByTruck, (value, key) => ({
    key,
    value: value.toString(),
  }));
};

export const getUnscheduledJobs = (
  allBinHistories: BinHistory[],
): PieChartLegendData[] => {
  let jobCountsByTruck: { [key: string]: number } = {};
  forEach(allBinHistories, (binHistory) => {
    forEach(binHistory.binHistories, (binPickup) => {
      if (binPickup.type === PickupType.UNSCHEDULED)
        jobCountsByTruck[binPickup.truckName as string] =
          (jobCountsByTruck[binPickup.truckName] || 0) + 1;
    });
  });

  return map(jobCountsByTruck, (value, key) => ({
    key,
    value: value.toString(),
  }));
};

export const getInitialDateRangeForFilter = (): DateRange => {
  const yesterday: Date = new Date();
  const today: Date = new Date();

  yesterday.setDate(today.getDate() - 2);
  yesterday.setHours(0, 0, 0, 0);

  return {
    from: yesterday,
    to: today,
  };
};

const historySearchFieldNames: string[] = [
  'operator.firstName',
  'operator.lastName',
  'pickupRef',
  'truckName',
];

// Function to filter binHistories array by PickupStatus
export const filterHistoriesBySearchQuery = (
  binPickups: BinPickup[],
  searchQuery: string,
): BinPickup[] =>
  binPickups.filter((binPickup) =>
    searchAllProperties(binPickup, searchQuery, historySearchFieldNames),
  );

export const getBinValueByFilterSection = (
  pickup: BinPickup,
  filterSection: FilterSections,
): string => {
  switch (filterSection) {
    case FilterSections.bin:
      return pickup.bin.name;
    case FilterSections.truckName:
      return pickup.truckName;
    case FilterSections.status:
      return pickup.status;
    case FilterSections.type:
      return pickup.type;
    default:
      return '';
  }
};

export const filterAndSearchHistories = (
  histories: BinHistory[],
  searchQuery: string,
  selectedFilters: SelectedFilters,
): BinHistory[] => {
  return histories.map((historiesByDate) => {
    let filteredBinHistories = historiesByDate.binHistories;
    if (searchQuery) {
      filteredBinHistories = filterHistoriesBySearchQuery(
        historiesByDate.binHistories,
        searchQuery,
      );
    }
    // Go through each filter type and apply it
    Object.entries(selectedFilters).forEach(
      ([filterType, selectedOptions]: [string, string[]]) => {
        if (selectedOptions.length) {
          if (filterType === FilterSections.type) {
            // for job type filter must two fields: pickup.type and pickup.request.dropOffOnly
            filteredBinHistories = filteredBinHistories.filter(
              (pickup: BinPickup) => {
                const isScheduled = pickup.type === PickupType.SCHEDULED;
                const isNoDump = pickup.bin.pickupRequest?.dropoffOnly;

                // go through each selected option and find return true or false
                // by using some, if true is return at least one, true will be return
                return selectedOptions.some((status) => {
                  switch (status) {
                    case JobTypeFilters.Scheduled:
                      return isScheduled && !isNoDump;
                    case JobTypeFilters.Unscheduled:
                      return !isScheduled && !isNoDump;
                    case 'ScheduledNoDump':
                      return isScheduled && isNoDump;
                    case 'UnscheduledNoDump':
                      return !isScheduled && isNoDump;
                    default:
                      return false;
                  }
                });
              },
            );
          } else {
            filteredBinHistories = filteredBinHistories.filter(
              (history: BinPickup) =>
                // check if a specific bin field (determined by which filter section is being handled)
                // is included in the list selected filters
                selectedOptions.includes(
                  getBinValueByFilterSection(
                    history,
                    filterType as FilterSections,
                  ),
                ),
            );
          }
        }
      },
    );
    return {
      ...historiesByDate,
      binHistories: filteredBinHistories,
    };
  });
};

export const emptyFilters: SelectedFilters = {
  status: [],
  bin: [],
  truckName: [],
  type: [],
};

export const getBinJobs = (binHistories: BinHistory[]): BinPickupJob[] => {
  const scheduledBinPickups: BinPickup[] = [];
  const unscheduledBinPickups: BinPickup[] = [];

  binHistories.forEach((binHistory: BinHistory) => {
    scheduledBinPickups.push(
      ...filter(binHistory.binHistories, { type: PickupType.SCHEDULED }),
    );
  });
  /*
     Not sure if we could just subtract the the scheduled pickups from the total pickups to get the number of 
     unscheduled pickups. Computing below just to be sure. Can be removed if not required and be derived from
     subtracting scheduled pickups from total pickups
   */
  binHistories.forEach((binHistory: BinHistory) => {
    unscheduledBinPickups.push(
      ...filter(binHistory.binHistories, { type: PickupType.UNSCHEDULED }),
    );
  });

  return [
    {
      key: PickupType.SCHEDULED,
      value: scheduledBinPickups.length,
      colors: '#7E54A7',
    },
    {
      key: PickupType.UNSCHEDULED,
      value: unscheduledBinPickups.length,
      colors: '#39A0A8',
    },
  ];
};

export const getJobStatus = (binHistories: BinHistory[]): JobStatus[] => {
  const completedBinPickups: BinPickup[] = [];
  const incompleteBinPickups: BinPickup[] = [];

  binHistories.forEach((binHistory: BinHistory) => {
    completedBinPickups.push(
      ...filter(binHistory.binHistories, { status: PickupStatus.COMPLETE }),
    );
  });

  binHistories.forEach((binHistory: BinHistory) => {
    incompleteBinPickups.push(
      ...filter(binHistory.binHistories, { status: PickupStatus.INCOMPLETE }),
    );
  });

  return [
    {
      key: PickupStatus.COMPLETE,
      value: completedBinPickups.length,
      color: '#4DA67E',
    },
    {
      key: PickupStatus.INCOMPLETE,
      value: incompleteBinPickups.length,

      color: '#CB5358',
    },
  ];
};

export const getHistoryCount = (histories: BinHistory[]): number => {
  return histories.reduce((total, currentHistory) => {
    return total + currentHistory.binHistories.length;
  }, 0);
};
