import React from 'react';
import CircularProgressBar from '../../../../components/CircularProgressBar/CircularProgressBar';
import { Spinner } from 'react-bootstrap';
import { ShiftLoaderProgressBarProps } from './types';

const ShiftLoaderProgressBar: React.FC<ShiftLoaderProgressBarProps> = ({
  loading = false,
  totalTonnes = 0,
  totalLoadCount = 0,
  takeFullHeight = false,
}) => {
  return (
    <div
      style={{ height: takeFullHeight ? 335 : 259 }}
      className={
        'container d-flex flex-column rounded-3 shadow-sm px-4 py-2 ' +
        (takeFullHeight ? 'mb-0' : ' mb-2')
      }
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner />
        </div>
      ) : (
        <>
          <div style={{ fontSize: '14px' }} className="py-2 fw-bold fs-6">
            Shift Totals
          </div>
          <div className=" flex-grow-1 col-12 d-flex">
            <div className="d-flex flex-column justify-content-evenly col-6 py-2">
              <div
                style={{ fontSize: '12px' }}
                className="fst-italic fw-bold mb-2 text-muted"
              >
                Loads
              </div>
              <CircularProgressBar target={3 * 24} current={totalLoadCount} />
            </div>
            <div className="d-flex flex-column justify-content-evenly col-6 py-2">
              <div
                style={{ fontSize: '12px' }}
                className="fst-italic fw-bold mb-2 text-muted"
              >
                Tonnes
              </div>
              <CircularProgressBar target={30 * 24} current={totalTonnes} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShiftLoaderProgressBar;
