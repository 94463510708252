import { FunctionComponent } from 'react';

export const DumpIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="9.909"
      viewBox="0 0 7 9.909"
      color="black"
    >
      <path
        id="Icon_material-location-on"
        data-name="Icon material-location-on"
        d="M10.75,3A3.248,3.248,0,0,0,7.5,6.25c0,2.437,3.25,6.036,3.25,6.036S14,8.688,14,6.25A3.248,3.248,0,0,0,10.75,3Zm0,4.411A1.161,1.161,0,1,1,11.911,6.25,1.161,1.161,0,0,1,10.75,7.411Z"
        transform="translate(-7.25 -2.75)"
        fill="none"
        stroke="#e5e5e7"
        stroke-width="0.5"
      />
    </svg>
  );
};
