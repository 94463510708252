import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import * as toast from '../dustControl/components/toast';
import { API_METHODS, ENDPOINTS } from '../dustControl/utils/constants';
import { UserContext } from '../pages/service/userContext';
import CategoryCard from './Components/CategoryWidget/CategoryCard';
import logo from './assets/icons/logo.png';
import FailedIcon from './assets/icons/report-failed.svg';
import IncompleteIcon from './assets/icons/report-incomplete.svg';
import PassedIcon from './assets/icons/report-passed.svg';
import { STATUS_DATA } from './utils/constants';
import _ from 'lodash';
import moment from 'moment';

const Header = ({ selectedDate }) => {
  return (
    <div className="d-flex pady-2 padx-2  justify-content-between">
      <div className="col-8 d-flex align-items-end ">
        <div>
          <img
            src={logo}
            style={{ height: '50px' }}
            alt="SteelServ"
            className="report-logo"
          />
        </div>
      </div>
      <div className="col-4 d-flex justify-content-end  align-items-end">
        {moment().format('YYYY-MM-DD') == selectedDate
          ? moment().format('hh:mm A')
          : ''}{' '}
        {selectedDate?.substring(8, 10)}/{selectedDate?.substring(5, 7)}/
        {selectedDate?.substring(0, 4)}
      </div>
    </div>
  );
};

const Summary = ({ selectedDate, status, dept, category, searchq }) => {
  const { ApiHandler, departments, licenses, getDepartmentsAndLicenses } =
    useContext(UserContext);

  const getCategoryName = (truckType) => {
    if (licenses?.length <= 0) {
      getDepartmentsAndLicenses();
    }
    const license = licenses.find((license) => license.code === truckType);

    if (license) {
      return license.name;
    } else {
      return 'cat_not_found';
    }
  };

  const getDeptname = (truckType) => {
    if (!licenses?.length) {
      return 'dept_not_found';
    }
    const license = licenses.find((license) => license.code === truckType);
    const dept = departments.find(
      (dept) => license.departmentCode === dept.code,
    );
    if (dept) {
      return { name: dept.name, code: dept.code };
    } else {
      return 'dept_not_found';
    }
  };

  const getDepartMentsName = (deptArray, depts, licenses) => {
    if (licenses?.length <= 0) {
      getDepartmentsAndLicenses();
    }

    let actualLength = 0;
    const departmentsWithLiscences = depts?.map((dept) => {
      const lIndex = licenses.findIndex(
        (license) => license?.departmentCode === dept?.code,
      );
      if (lIndex !== -1) {
        actualLength++;
        if (deptArray?.includes(dept?.code)) {
          return dept?.name;
        }
      }
    });

    const departmentsList = departmentsWithLiscences?.filter(
      (d) => d != undefined,
    );

    if (deptArray?.length == actualLength) {
      return 'All Departments';
    }
    const deptNames = departmentsList?.join(', ');
    return deptNames;
  };

  const getCategoriesName = (categoryArray, licenses) => {
    if (licenses?.length <= 0) {
      getDepartmentsAndLicenses();
    }
    const filteredData = licenses.filter((l) =>
      categoryArray?.includes(l?.code),
    );
    const categories = filteredData?.map((liscs) => {
      return liscs?.name;
    });
    const categoryList = categories?.filter((d) => d != undefined);
    if (categoryArray?.length == licenses?.length) {
      return 'All Categories';
    }
    const categoryNames = categoryList?.join(', ');
    return categoryNames;
  };

  const search = new URLSearchParams(document.location.search);

  const [data, setData] = useState([]);
  const [tables, setTables] = useState([]);
  const [chartData, setChartData] = useState([]);

  const statusType = search.get('status')
    ? search.get('status').split(',')
    : status;
  const deptType = search.get('dept')
    ? search
        .get('dept')
        .split(',')
        .map((item) => Number(item))
    : dept;
  const categoryType = search.get('category')
    ? search
        .get('category')
        .split(',')
        .map((item) => Number(item))
    : category;
  const searchQuery = search.get('search') ? search.get('search') : searchq;

  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({
    failed: 0,
    passed: 0,
    incomplete: 0,
  });

  const [categoryData, setCategoryData] = useState();

  const handleChange = async (dm, source) => {
    let t_summary = [0, 0, 0]; //[failed, passed, incomplete]
    let catData = [];

    const temp = dm.map((d, i) => {
      let fault = 0;
      function getStatusIndex(status) {
        return ['passed', 'failed', 'incomplete'].indexOf(status);
      }
      for (const status of ['failed', 'passed', 'incomplete']) {
        if (d.status === status) {
          t_summary[getStatusIndex(status)]++;
          const categoryName = getCategoryName(d?.truck?.type, licenses);
          const categoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );

          if (categoryIndex === -1) {
            catData.push({ name: categoryName, data: [0, 0, 0] });
          }
          const updatedCategoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );
          catData[updatedCategoryIndex].data[getStatusIndex(status)]++;
        }
      }
      d?.checkpoints?.map((c) => {
        if (c.answer == 'faulty') fault++;
      });
      const dept = getDeptname(d?.truck?.type, departments, licenses);
      return {
        ...d,
        assetID: d?.truck?.name,
        failedChecks: `${fault}/${d?.checkpoints?.length}`,
        supervisorSignature: d?.supervisor?.signedName,
        department: dept.name,
        deptCode: dept.code,
        category: getCategoryName(d?.truck?.type, licenses),
      };
    });

    setSummary({
      failed: t_summary[0],
      passed: t_summary[1],
      incomplete: t_summary[2],
    });

    setCategoryData(catData);
    if (source != true) {
      setData(temp);
    }
    setLoading(false);
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getPrestartChecks,
        method: API_METHODS.PUT,
        reqParam: {
          date: search.get('date') ? search.get('date') : selectedDate,
        },
      });

      await handleChange(response.data);
    } catch (err) {
      console.log(err);

      toast.error(err?.message);
      setLoading(false);
    }
  };

  const mapStatusData = (data) => {
    let zoneData = [];
    Object.entries(STATUS_DATA)?.forEach(([key, zone]) => {
      if (data[zone?.name]) {
        zoneData.push(data[zone.name]);
      } else {
        zoneData.push(0);
      }
    });

    setChartData(zoneData);
  };

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        mapStatusData({
          passed: summary?.passed,
          failed: summary?.failed,
          incomplete: summary?.incomplete,
        });
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchStatus();
  }, [summary]);

  const filterStatusData = (data, statusType) => {
    if (statusType && statusType.length > 0) {
      const filteredData = data.filter((d) => statusType?.includes(d.status));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterDeptData = (data, deptType) => {
    if (deptType && deptType.length > 0) {
      const filteredData = data.filter((d) => deptType?.includes(d?.deptCode));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterCategoryData = (data, categoryType) => {
    if (categoryType && categoryType.length > 0) {
      const filteredData = data.filter((d) =>
        categoryType?.includes(d?.truck?.type),
      );
      return filteredData;
    } else {
      return data;
    }
  };

  const filteredData = (status) => {
    return filterCategoryData(
      filterDeptData(filterStatusData(data, status), deptType),
      categoryType,
    ).filter(
      (p) =>
        !searchQuery ||
        p.assetID.includes(searchQuery) ||
        `${p.operator?.first_name} ${p.operator?.last_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
    );
  };

  const [tablesData, setTablesData] = useState({});

  useEffect(() => {
    const statusTypes = ['passed', 'failed', 'incomplete'];
    const updatedData = {};
    statusTypes.forEach((status) => {
      if (statusType?.includes(status)) {
        const statusWiseFilteredfData = filteredData(status);
        updatedData[status] = statusWiseFilteredfData;
      }
    });
    setTablesData(updatedData);
    const filterData = filteredData(statusType);
    const f = async (filterData) => {
      await handleChange(filterData, true);
    };
    f(filterData);
  }, [data]);

  const formatDate = (timestamp, defaultValue = '00:00 AM') => {
    if (isNaN(Date.parse(timestamp))) return defaultValue;
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getLocalTime = (time) => {
    const timeString = formatDate(time);
    var lastTwoCharacters = timeString.slice(-2); // Get the last two characters
    var modifiedTime =
      timeString.slice(0, -2) + lastTwoCharacters.toLowerCase(); // Convert the last two characters to lowercase and replace them
    return modifiedTime;
  };

  const getSortedData = (data) => {
    const sortedData = {};
    data?.forEach((entry) => {
      if (!sortedData[entry?.department]) {
        sortedData[entry?.department] = {};
      }
      if (!sortedData[entry?.department][entry?.category]) {
        sortedData[entry?.department][entry?.category] = [];
      }
      if (!sortedData[entry?.department][entry?.category][entry?.truck?.name]) {
        sortedData[entry?.department][entry?.category][entry?.truck?.name] = [];
      }
      sortedData[entry?.department][entry?.category][entry?.truck?.name].push(
        entry,
      );
    });
    return sortedData;
  };

  const generateTables = ({ preData, maxHeight = 400 }) => {
    const remaining = { passed: [], failed: []};
    const statuses = ['failed', 'passed'];
    let tableHtml = '<div class = "py-2 padx-2">'; // initialize HTML content
    let currentHeight = 0; // Track the current height of the page

    statuses.forEach((status) => {
      if (preData[status]?.length) {
        const sortedData = getSortedData(preData[status]);
        tableHtml += `<div class="table_manage_prestart mb-3"> ${
          currentHeight + 60 < maxHeight
            ? ` <u style="font-size: 14px; font-weight: 600"> ${status?.toUpperCase()} </u> `
            : ' '
        }<table>`;

        if (currentHeight + 60 < maxHeight) {
          tableHtml += '<thead class="users_header">';
          tableHtml +=
            '<tr style="border-top: 2px solid #707070; border-bottom: 2px solid #707070;" align="left" valign="top" class="users_header">';
          tableHtml += '<th>Ref No.</th>';
          tableHtml += '<th colspan="2">Operator</th>';
          tableHtml += '<th>Check time</th>';
          tableHtml += '<th>Failed checks</th>';
          tableHtml += '<th>Engine hours</th>';
          tableHtml += '<th>Sup. Signature</th>';
          tableHtml += '</tr>';
          tableHtml += '</thead>';
          currentHeight += 30;
        } else {
          currentHeight += 30;
        }

        for (const [key, value] of Object.entries(sortedData)) {
          let deptInserted = false;

          for (const [cat, entries] of Object.entries(value)) {
            let categoryInserted = false;

            for (const [asset, prestart] of Object.entries(entries)) {
              let assetInserted = false;

              prestart.forEach((entry, index) => {
                if (currentHeight + 30 < maxHeight && !deptInserted) {
                  if (
                    entry?.deptInserted == false ||
                    entry?.deptInserted == undefined
                  ) {
                    tableHtml += `<tr align="left" valign="top">`;
                    tableHtml += `<td colspan="10"><strong>${key}</strong></td>`;
                    tableHtml += '</tr>';
                    currentHeight += 30;
                    deptInserted = true;
                  }
                }

                if (currentHeight + 30 < maxHeight && !categoryInserted) {
                  if (
                    entry?.categoryInserted == false ||
                    entry?.categoryInserted == undefined
                  ) {
                    tableHtml += `<tr align="left" valign="top">`;
                    tableHtml += `<td style="color : #707070" colspan="10"><i>${cat}</i></td>`;
                    tableHtml += '</tr>';
                    currentHeight += 30;
                    categoryInserted = true;
                  }
                }

                if (currentHeight + 30 < maxHeight && !assetInserted) {
                  if (
                    entry?.assetInserted == false ||
                    entry?.assetInserted == undefined
                  ) {
                    tableHtml += `<tr align="left" valign="top">`;
                    tableHtml += `<td colspan="10"><strong>${asset}</strong></td>`;
                    tableHtml += '</tr>';
                    currentHeight += 30;
                    assetInserted = true;
                  }
                }

                if (currentHeight + 30 < maxHeight) {
                  tableHtml += `<tr align="left" valign="top">`;
                  tableHtml += `<td >${entry.refNo ? entry.refNo : '-'}</td>`;
                  tableHtml += `<td colspan="2">${entry.operator?.first_name} ${entry.operator?.last_name}</td>`;
                  tableHtml += `<td >${
                    entry.status !== 'incomplete'
                      ? getLocalTime(entry.checkTime)
                      : '-'
                  }</td>`;
                  tableHtml += `<td>${
                    entry.status !== 'incomplete' ? entry.failedChecks : '-'
                  }</td>`;
                  tableHtml += `<td>${
                    entry.engineHours == '0' ? '00000' : entry.engineHours
                  } hrs</td>`;
                  tableHtml += `<td>${
                    entry.supervisorSignature
                      ? entry.supervisorSignature
                      : entry.status === 'incomplete'
                        ? 'N/A'
                        : '-'
                  }</td>`;
                  tableHtml += '</tr>';
                  currentHeight += 30;
                } else {
                  remaining[status]?.push({
                    ...entry,
                    assetInserted,
                    categoryInserted,
                    deptInserted,
                  });
                }
              });
            }
          }
        }
        tableHtml += '</table></div>';
      }
    });
    tableHtml += '</div>';

    return { tableHtml, remaining };
  };

  useEffect(() => {
    const localTablesVariable = [];
    let remainingData = tablesData;
    while (
      remainingData?.passed?.length > 0 ||
      remainingData?.incomplete?.length > 0 ||
      remainingData?.failed?.length > 0
    ) {
      const { tableHtml, remaining } = generateTables({
        preData: remainingData,
        maxHeight: localTablesVariable?.length == 0 ? 210 : 660,
      });
      localTablesVariable.push(tableHtml);
      setTables(localTablesVariable);
      remainingData = remaining;
    }
  }, [tablesData]);

  useEffect(() => {
    fetch();
  }, [selectedDate]);

  return (
    <>
      {loading ? (
        <div>Loading....</div>
      ) : (
        <div
          id="print-report"
          className="content-loaded"
          // style={{ pageBreakAfter: 'always' }}
        >
          <div style={{ position: 'relative' }} className="report-view-pdf p-0">
            <Header
              selectedDate={
                search.get('date') ? search.get('date') : selectedDate
              }
            />

            <div
              className=" py-2 padx-2 "
              style={{ fontSize: '16px', fontWeight: '600' }}
            >
              {' '}
              Pre-Start Checks
            </div>

            <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
              <div
                style={{ background: '#FAFAFA' }}
                className="d-flex py-2 padx-2 border-y "
              >
                <div className=" col-12 d-flex flex-column">
                  <div
                    style={{
                      fontSize: '13.5px',
                      borderBottom: '1px solid #EBEBEB',
                    }}
                    className="py-2 mb-2 d-flex"
                  >
                    <strong className="">Specifications </strong>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Department:</strong>
                    <div className="col-10">
                      <strong>
                        {getDepartMentsName(deptType, departments, licenses)}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Category: </strong>
                    <div className="col-6">
                      <strong>
                        {getCategoriesName(categoryType, licenses)}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Status:</strong>
                    <div className="col-6">
                      <strong>
                        {statusType?.length == 0 || statusType?.length == 3
                          ? 'All'
                          : statusType?.map((status, index) => (
                              <span>
                                {_.capitalize(status)}
                                <span>
                                  {index + 1 == statusType?.length ? '' : ', '}
                                </span>
                              </span>
                            ))}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Assets:</strong>
                    <div className="col-6">
                      <strong>{searchQuery ? searchQuery : 'All'} </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Operators:</strong>
                    <div className="col-6">
                      <strong>{searchQuery ? searchQuery : 'All'} </strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex  py-2 padx-2 flex-column align-items-start">
                <div className="">
                  <div
                    className="mt-2"
                    style={{ fontSize: '12px', fontWeight: '600' }}
                  >
                    Summary
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-flex  justify-content-center align-items-center ">
                    <Image src={FailedIcon} className="" alt="down" />
                    <p className="text-2 me-2"> {chartData[0]} Failed</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <Image className="" src={PassedIcon} alt="down" />
                    <p className="text-2 me-2"> {chartData[1]} Passed</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center ">
                    <Image className="" src={IncompleteIcon} alt="down" />
                    <p className="text-2 me-2"> {chartData[2]} Incomplete</p>
                  </div>
                </div>
              </div>

              <div style={{ height: '100%' }}>
                <CategoryCard data={categoryData} />
              </div>
              <div className="  mt-4  mb-2 padx-2 ">
                <div style={{ fontSize: '14px', fontWeight: '600' }}>
                  Details
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: tables[0] }}></div>
            </div>

            <div
              style={{ position: 'absolute ', bottom: 10, right: 10 }}
              className="d-flex "
            >
              Page: 1/{tables?.length}
            </div>
          </div>

          {tables?.map((table, index) => {
            if (index > 0) {
              return (
                <div
                  style={{ position: 'relative' }}
                  className="report-view-pdf p-0"
                >
                  <Header
                    selectedDate={
                      search.get('date') ? search.get('date') : selectedDate
                    }
                  />
                  <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: tables[index] }}
                    ></div>
                  </div>
                  <div
                    style={{ position: 'absolute ', bottom: 10, right: 10 }}
                    className="d-flex "
                  >
                    Page: {index + 1}/{tables?.length}
                  </div>
                </div>
              );
            }
          })}

          {search.get('date') && (
            <div
              style={{ position: 'relative' }}
              className="report-view-pdf p-0"
            >
              <Header
                selectedDate={
                  search.get('date') ? search.get('date') : selectedDate
                }
              />
              <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: tables[tables?.length - 1],
                  }}
                ></div>
              </div>
              <div
                style={{ position: 'absolute ', bottom: 10, right: 10 }}
                className="d-flex "
              >
                Page: {tables?.length}/{tables?.length}
              </div>
            </div>
          )}
        </div>
      )}{' '}
    </>
  );
};

export default Summary;
