import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  GET_ALL_OPERATORS,
  GET_ALL_SOURCE_AND_DUMPS,
  GET_ALL_TRUCKS_EXCAVATORS,
  GET_MATERIALS,
} from '../../action/apiPath';
import { CallWithAuth } from '../../../pages/service/apiActions';
import BasicButton from '../BasicButton';
import DropdownField from '../FormDropdown/DropdownField';

const HistoryFilterModal = (props) => {
  const {
    modalOpen,
    modalToggle,
    filteredData = {},
    setFilteredData = () => {},
    saveHandler,
    fetchTrucks,
  } = props;

  // const [initialFilteredData, setFilteredData] = useState(initialFilteredData);
  const [optionsForTrucks, setOptionsforTrucks] = useState([]);
  const [optionsForExcavators, setOptionsforExcavators] = useState([]);
  const [optionsForOperator, setOptionForOperator] = useState([]);
  const [optionsfordump, setOptionForDump] = useState([]);
  const [optionsforsource, setOptionForSource] = useState([]);
  const [materials, setMaterials] = useState([]);

  const fetchData = async () => {
    try {
      const response = await CallWithAuth(
        'GET',
        `${GET_ALL_TRUCKS_EXCAVATORS}`,
      );
      if (response?.res?.data?.status === 200) {
        setOptionsforTrucks(response?.res?.data?.data?.trucks);
        setOptionsforExcavators(response?.res?.data?.data?.excavators);
      } else throw response?.res;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllPoints = async () => {
    try {
      const response = await CallWithAuth('GET', `${GET_ALL_SOURCE_AND_DUMPS}`);
      if (response?.res?.data?.status === 200) {
        setOptionForDump(response?.res?.data?.data?.dumps);
        setOptionForSource(response?.res?.data?.data?.sources);
      }
    } catch (error) {
      console.log('sourceAndDumps', error);
    }
  };
  const fetchOperatorData = async () => {
    try {
      const response = await CallWithAuth('GET', `${GET_ALL_OPERATORS}`);
      if (response?.res?.data?.status === 200) {
        setOptionForOperator(response?.res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await CallWithAuth('GET', GET_MATERIALS);
      if (response?.res?.data?.status === 200) {
        setMaterials(response?.res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchOperatorData();
    fetchData();
    fetchAllPoints();
    fetchMaterials();
  }, []);

  const onCheckboxClick = (fieldName, value, multiselect) => {
    console.log({ fieldName, value, multiselect, filteredData });
    if (multiselect) {
      const filters = { ...filteredData };
      if (filters[fieldName]?.includes(value)) {
        filters[fieldName] = filters[fieldName].filter(
          (selectedValue) => selectedValue !== value,
        );
      } else {
        filters[fieldName] = [...(filters[fieldName] || []), value];
      }
      console.log(filters);
      setFilteredData(filters);
    } else {
      setFilteredData((prev) => ({ ...prev, [fieldName]: value }));
    }
  };

  const clearFilter = () => {
    setFilteredData({});
  };

  return (
    <div className="test-class" style={{ borderRadius: '4px 0px 0px 0px' }}>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          // backdrop="static"
          keyboard={false}
          size="lg"
          dialogClassName="mm-filter-modal-position "
          className="modal-transparent"
        >
          <Modal.Header>
            <div
              style={{ borderBottom: '1px solid #EBEBEB' }}
              className="d-flex justify-content-between w-100 align-items-center ms-4 me-2 my-3 font-13 mb-3"
            >
              <b
                className="ps-1 px-3 mb-4"
                style={{ font: 'normal normal 600 16px/22px Open Sans' }}
              >
                FILTER BY
              </b>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="pt-0 font-13 d-flex flex-column justify-content-between h-100"
              style={{
                font: 'normal normal 14px/19px Open Sans',
                borderRadius: '10px 0px 0px 0px',
              }}
            >
              <div className="mx-4 px-2 d-flex flex-column gap-2">
                <div className=" mb-4">
                  <DropdownField
                    name={'Materials'}
                    key={'materials'}
                    fieldKey={'materials'}
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={filteredData['materials']}
                    allOptions={materials?.map((e) => {
                      return { name: e?.MaterialType, value: e?.MaterialType };
                    })}
                    multiselect
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    handleChange={(e) => {
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                      );
                    }}
                  />
                </div>
                <div className=" mb-4">
                  <DropdownField
                    name={'Operators'}
                    key={'operators'}
                    fieldKey={'operators'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={filteredData['operators']}
                    allOptions={optionsForOperator?.map((e) => {
                      return {
                        name: e?.first_name + ' ' + e?.last_name,
                        value: e?.first_name + ' ' + e?.last_name,
                      };
                    })}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                      )
                    }
                  />
                </div>
                <div className=" mb-4">
                  <DropdownField
                    name={'Source Point'}
                    key={'sourcepoint'}
                    fieldKey={'sourcepoint'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={filteredData['sourcepoint']}
                    allOptions={optionsforsource?.map((e) => {
                      return { name: e?.name, value: e?.name };
                    })}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    handleChange={(e) => {
                      console.log(e, 'Inisfe materials dropdown');
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                      );
                    }}
                  />
                </div>

                <div className=" mb-4">
                  <DropdownField
                    name={'Dump Points'}
                    key={'dumppoint'}
                    fieldKey={'dumppoint'}
                    multiselect
                    selectedOptions={filteredData['dumppoint']}
                    allOptions={optionsfordump?.map((e) => {
                      return { name: e?.name, value: e?.name };
                    })}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                      )
                    }
                  />
                </div>

                <div className=" mb-4">
                  <DropdownField
                    name={'Trucks'}
                    key={'trucks'}
                    fieldKey={'trucks'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={filteredData['trucks']}
                    allOptions={optionsForTrucks?.map((e) => {
                      return { name: e?.name, value: e?.name };
                    })}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                      )
                    }
                  />
                </div>
                <div className=" mb-5 pb-5 ">
                  <DropdownField
                    name={'Excavators'}
                    key={'excavators'}
                    fieldKey={'excavators'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={filteredData['excavators']}
                    allOptions={optionsForExcavators?.map((e) => {
                      return { name: e?.name, value: e?.name };
                    })}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between pt-3 p-4"
            style={{
              backgroundColor: '#FAFAFA',
              backgroundRepeat: 'no-repeat',
              padding: 'padding-box',
            }}
          >
            <div>
              <button
                className="border border-0 bg-transparent px-3 btn-focus"
                onClick={clearFilter}
                style={{ color: '#707070', marginBottom: '20px' }}
              >
                <u>Clear all</u>
              </button>
            </div>
            <div className=" d-flex">
              <div className=" px-3">
                <BasicButton
                  style={{ border: '1px solid black', marginBottom: '20px' }}
                  onClick={() => modalToggle(false)}
                  variant="white"
                >
                  <div style={{ fontSize: '0.8rem' }}> Cancel</div>
                </BasicButton>
              </div>
              <div>
                <BasicButton
                  className="px-4 btn-focus"
                  onClick={saveHandler}
                  variant="dark"
                  style={{ marginBottom: '20px' }}
                >
                  <div style={{ fontSize: '0.8rem' }}> Apply</div>
                </BasicButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HistoryFilterModal;
