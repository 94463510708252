import moment from 'moment';
import {
  DateTimeFormats,
  DefaultTimes,
  TrafficLightColor,
} from '../../constants';
import { FilterValues } from '../../types';
import { getDateRangeType } from '../../utils';
import {
  ShiftLoaderDateRangeType,
  ShiftLoadsGraphOption,
} from '../../../../utils/constants';
import {
  HourlyFetchedGraphData,
  MonthlyFetchedGraphData,
  Ranges,
} from './types';
import { Segment } from './types';

export const convertHoursToTimeString = (hours: number): string => {
  const h = Math.floor(hours).toString().padStart(2, '0');
  const m = Math.round((hours % 1) * 60)
    .toString()
    .padStart(2, '0');
  return `${h}h ${m}m`;
};

export const hourlyGraphFormattor = (index: number, start: number) =>
  index == 0 ? '' : (start + index).toString().padStart(2, '0');

export const monthlyGraphFormattor = (
  index: number,
  filters: FilterValues,
  dateTimeRanges = { dateRange: 0, timeRange: 0 },
) =>
  index % 24 != 0
    ? ''
    : (filters.dateRange.to
        ? moment(filters.dateRange.to, DateTimeFormats.DATE)
        : filters.dateRange.from != moment().format(DateTimeFormats.DATE)
          ? moment(filters.dateRange.from, DateTimeFormats.DATE)
          : moment()
      )
        .add(index - (dateTimeRanges.dateRange - 1) * 24, 'hour')
        .format('DD/MM');

export const getRanges = (filters: FilterValues) => {
  let firstDay = 0;
  let lastDay = 0;

  let firstHour = 0;
  let lastHour = 0;

  if (filters.dateRange.to) {
    firstDay = moment(filters.dateRange.from, DateTimeFormats.DATE)
      .startOf('day')
      .unix();
    lastDay = moment(filters.dateRange.to, DateTimeFormats.DATE)
      .endOf('day')
      .unix();

    firstHour = moment(
      filters.dateRange.from +
        ' ' +
        (filters.from || DefaultTimes.DEFAULT_FROM_TIME),
      DateTimeFormats.TIMESTAMP,
    )
      .startOf('hour')
      .unix();
    lastHour = moment(
      filters.dateRange.to + ' ' + (filters.to || DefaultTimes.DEFAULT_TO_TIME),
      DateTimeFormats.TIMESTAMP,
    )
      .endOf('hour')
      .unix();
  } else {
    firstDay = moment(filters.dateRange.from, DateTimeFormats.DATE)
      .subtract(14, 'day')
      .startOf('day')
      .unix();
    lastDay = moment(filters.dateRange.from, DateTimeFormats.DATE)
      .endOf('day')
      .unix();
    firstHour = moment(
      filters.dateRange.from +
        ' ' +
        (filters.from || DefaultTimes.DEFAULT_FROM_TIME),
      DateTimeFormats.TIMESTAMP,
    )
      .startOf('hour')
      .unix();
    lastHour = moment(
      filters.dateRange.from +
        ' ' +
        (filters.to || DefaultTimes.DEFAULT_TO_TIME),
      DateTimeFormats.TIMESTAMP,
    )
      .endOf('hour')
      .unix();
  }

  return { firstDay, lastDay, firstHour, lastHour };
};

export const processDailyGraphData = (
  data: HourlyFetchedGraphData,
  ranges: Ranges,
) => {
  return data.timelineData.map((d: any) => ({
    status: d.status == TrafficLightColor.GREEN ? true : false,
    start: (moment(d.startTime).unix() - ranges.firstDay) / 3600,
    end: (moment(d.endTime).unix() - ranges.firstDay) / 3600,
  }));
};

export const processHourlyGraphData = (
  data: HourlyFetchedGraphData,
  ranges: Ranges,
) => {
  return data.timelineData.map((d: any) => ({
    status: d.status == TrafficLightColor.GREEN ? true : false,
    start: (moment(d.startTime).unix() - ranges.firstHour) / 3600,
    end: (moment(d.endTime).unix() - ranges.firstHour) / 3600,
  }));
};

export const processMonthlyGraphData = (data: MonthlyFetchedGraphData) => {
  return data.timelineData.map((d: any) => ({
    Day: d.day,
    runtimeHours: d.runtimeMinutes / 60,
    stoppedHours: d.stoppedMinutes / 60,
  }));
};

export const getMetaData = (
  filters: FilterValues,
  selectedTabValue: string | null,
) => {
  const dateRangeType = getDateRangeType(filters);
  const momomentObj1 = moment(
    `${filters.dateRange.from} ${filters.from || DefaultTimes.DEFAULT_FROM_TIME}`,
    DateTimeFormats.TIMESTAMP,
  );
  const momomentObj2 = moment(
    `${filters.dateRange.to || filters.dateRange.from} ${filters.to || DefaultTimes.DEFAULT_TO_TIME}`,
    DateTimeFormats.TIMESTAMP,
  );
  const hoursDifference = Math.ceil(
    momomentObj2.diff(momomentObj1, 'hours', true),
  );
  console.log(momomentObj2.diff(momomentObj2, 'day'));

  const daysDifference = Math.ceil(
    momomentObj2.endOf('day').diff(momomentObj1.startOf('day'), 'day', true),
  );

  const dateTimeRanges = {
    timeRange: hoursDifference,
    dateRange:
      dateRangeType <= ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE
        ? 15
        : daysDifference,
  };
  switch (selectedTabValue) {
    case ShiftLoadsGraphOption.HOURS:
      if (dateRangeType === ShiftLoaderDateRangeType.CURRENT_DAY)
        return { dateTimeRanges, metaData: 'Last 24 hours' };
      if (
        dateRangeType >= ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE &&
        dateRangeType <= ShiftLoaderDateRangeType.RANGE_48
      )
        return {
          dateTimeRanges,
          metaData:
            Math.ceil(hoursDifference) +
            ' hour' +
            (hoursDifference > 1 ? 's' : ''),
        };
      break;
    case ShiftLoadsGraphOption.DAYS:
      if (dateRangeType === ShiftLoaderDateRangeType.CURRENT_DAY)
        return { dateTimeRanges, metaData: 'Last 15 days' };
      if (dateRangeType <= ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE)
        return { dateTimeRanges, metaData: '15 days' };
      return {
        dateTimeRanges,
        metaData:
          (momomentObj2.format(DateTimeFormats.DATE) ==
          moment().format(DateTimeFormats.DATE)
            ? 'Last '
            : '') +
          daysDifference +
          ' day' +
          (daysDifference > 1 ? 's' : ''),
      };
    default:
      break;
  }
  return { dateTimeRanges, metaData: '' };
};

export const calculateUnixTimeForTrafficLightGraphToolTipBasedOnDateRangeType =
  (
    startDayUnix: number,
    dateRangeType: ShiftLoaderDateRangeType,
    start: number,
  ): number => {
    const adjustment =
      dateRangeType <= ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE
        ? 14 * 3600 * 24
        : 0;
    return startDayUnix - adjustment + Math.round(start * 3600);
  };

export const formatDate = (unixTime: number, format: string): string =>
  moment.unix(unixTime).format(format);

export const getDateForTrafficLightGraphToolTip = (
  popupData: Segment,
  startDayUnix: number,
  dateRangeType: ShiftLoaderDateRangeType,
): string => {
  const startDate = formatDate(
    calculateUnixTimeForTrafficLightGraphToolTipBasedOnDateRangeType(
      startDayUnix,
      dateRangeType,
      popupData.start,
    ),
    'DD/MM/YYYY',
  );

  const endDate = formatDate(
    calculateUnixTimeForTrafficLightGraphToolTipBasedOnDateRangeType(
      startDayUnix,
      dateRangeType,
      popupData.end,
    ),
    'DD/MM/YYYY',
  );

  if (startDate == endDate) return startDate;
  return startDate + ' - ' + endDate;
};

export const getTimeIntervalForDaysTrafficLightGraphToolTip = (
  popupData: any,
  startDayUnix: number,
  dateRangeType: ShiftLoaderDateRangeType,
): string => {
  const startUnixTime =
    calculateUnixTimeForTrafficLightGraphToolTipBasedOnDateRangeType(
      startDayUnix,
      dateRangeType,
      popupData.start,
    );
  const endUnixTime =
    calculateUnixTimeForTrafficLightGraphToolTipBasedOnDateRangeType(
      startDayUnix,
      dateRangeType,
      popupData.end,
    );
  return `${formatDate(startUnixTime, DateTimeFormats.TIME_L)} - ${formatDate(
    endUnixTime,
    DateTimeFormats.TIME_L,
  )}`;
};

export const getTimeIntervalForHourlyTrafficLightGraphToolTip = (
  popupData: any,
  startHourUnix: number,
): string => {
  const startUnixTimeNew = startHourUnix + Math.round(popupData.start * 3600);
  const endUnixTimeNew = startHourUnix + Math.round(popupData.end * 3600);
  return `${formatDate(startUnixTimeNew, DateTimeFormats.TIME_L)} - ${formatDate(
    endUnixTimeNew,
    DateTimeFormats.TIME_L,
  )}`;
};

export const getDateForHourlyTrafficLightGraphToolTip = (
  popupData: Segment,
  startTimeUnix: number,
): string => {
  const startDate = formatDate(
    startTimeUnix + Math.round(popupData.start * 3600),
    DateTimeFormats.DATE,
  );

  const endDate = formatDate(
    startTimeUnix + Math.round(popupData.end * 3600),
    DateTimeFormats.DATE,
  );

  if (startDate == endDate) return startDate;
  return startDate + ' - ' + endDate;
};
