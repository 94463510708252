import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Modal, Spinner } from 'react-bootstrap';

import {
  API_METHODS,
  ENDPOINTS,
  SOCKETS,
  ZoneStatus,
} from '../../utils/constants';
import BasicButton from '../BasicButton';
import ManageZone from '../ManageZone';
import * as toast from '../../components/toast';
import { SocketContext } from '../../utils/socket';
import StatusDot from '../StatusDot';
import { capitaliseFirstLetter } from '../../utils/helper';
import moment from 'moment';
import { UserContext } from '../../../pages/service/userContext';

const tableHeader = [
  'Zone',
  'Priority',
  'Last time watered',
  'Times watered',
  'Truck',
  'Hazards',
  'Last Job Status',
];
const ZONES = [1, 2, 3, 4, 5, 6];

const AllStatus = [
  { status: ZoneStatus.Open, color: 'red' },
  // { status: "Completed", color: "green" },
  { status: ZoneStatus.Progress, color: 'green' },
  // { status: "Due for watering/Incomplete", color: "purple" },
];

const ZoneTable = ({ refreshData }) => {
  const { ApiHandler } = useContext(UserContext);
  const [zones, setZones] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [toggleManageZoneModal, setToggleManageZoneModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { socket } = useContext(SocketContext);
  // const [hazardMessage, setHazardMessage] = useState("");
  const updatedZones = ZONES.map((zone) => ({
    zone,
    hazard: '',
    selected: false,
    isEdited: false,
  }));
  const [editHazardZones, setEditHazardZones] = useState(updatedZones);
  // const [prevData, setPrevData] = useState(updatedZones);
  const [currentZoneHazards, setCurrentZoneHazards] = useState([]);
  const refreshDataWithZones = () => {
    refreshData();
    getZones();
  };

  const getZones = useCallback(async (isSocket, abortController, noLoader) => {
    try {
      if (!noLoader) setIsLoading(true);
      const zones = await ApiHandler({
        endPoint: ENDPOINTS.getZones,
        method: API_METHODS.GET,
        signal: abortController,
      });
      if (!noLoader) setIsLoading(false);
      setZones(zones.data);
      const zoneData = zones.data?.map((zoneInfo) => ({
        zone: zoneInfo.zone,
        hazard: zoneInfo.hazard,
        selected: false,
        isEdited: false,
      }));
      const sortedZones = zoneData.sort((a, b) => a.zone - b.zone);
      setCurrentZoneHazards(sortedZones);
      setEditHazardZones(sortedZones);
    } catch (err) {
      if (isSocket) toast.error(err.message);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    getZones(false, abortController.signal);

    socket.on(SOCKETS.zones, async () => {
      await getZones(false, undefined, 'noLoader');
    });
    return () => {
      abortController.abort();
      socket.off(SOCKETS.zones);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getZones, socket]);

  const modalToggle = () => {
    let newZones = [...editHazardZones];
    const updatedZones = newZones.map((zoneInfo, index) => {
      return {
        ...zoneInfo,
        selected: false,
        isEdited: false,
        hazard: currentZoneHazards[index].hazard,
      };
    });
    setEditHazardZones(updatedZones);
    setModalOpen((prev) => !prev);
  };

  const onSaveClick = async () => {
    let selectedZones = editHazardZones.reduce((accum, zoneInfo) => {
      if (zoneInfo.isEdited) {
        const message = zoneInfo.hazard;
        accum.push({ zone: zoneInfo.zone, message });
      }
      return accum;
    }, []);
    if (selectedZones.length <= 0) {
      return toast.error('No changes made');
    }
    try {
      await ApiHandler({
        endPoint: ENDPOINTS.editHazards,
        method: API_METHODS.PUT,
        reqParam: {
          zones: selectedZones,
        },
      });
      toast.success('Successfully updated hazards');
      let newZones = [...editHazardZones];
      const updatedZones = newZones.map((zoneInfo) => {
        return { ...zoneInfo, hazard: '' };
      });
      setEditHazardZones(updatedZones);
      modalToggle();
      refreshDataWithZones();
    } catch (err) {
      toast.error(err?.message);
    }
  };
  const handleHazardMessageChange = (event, zone) => {
    const { value } = event.target;
    const newZones = [...editHazardZones];
    if (value !== currentZoneHazards[zone - 1].hazard) {
      newZones[zone - 1].isEdited = true;
    }
    newZones[zone - 1].hazard = value;
    setEditHazardZones(newZones);
  };

  if (isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );

  return (
    <div>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          backdrop="static"
          keyboard={false}
          size="lg"
          scrollable
          dialogClassName="hazard-modal-position"
        >
          <Modal.Header>
            <div className="modal-header-container mt-3 px-4">
              <label className="hazard-label p-0">
                <b>Edit Hazards</b>
              </label>
              <div className="d-flex gap-2 button-container">
                <BasicButton
                  outlined
                  onClick={modalToggle}
                  variant="outline-dark"
                >
                  Discard
                </BasicButton>
                <BasicButton onClick={onSaveClick} variant="dark">
                  Save
                </BasicButton>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-hazards-container gap-4 p-4">
              {editHazardZones.map((zone) => (
                <div className="hazard-zone-card" key={zone.zone}>
                  <p className="hazard-zone">Zone {zone.zone}</p>
                  <textarea
                    type="text"
                    value={zone.hazard}
                    onChange={(e) => handleHazardMessageChange(e, zone.zone)}
                    cols={30}
                    spellCheck={true}
                    placeholder="Add Hazards"
                    className="hazard-textbox"
                  ></textarea>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}

      <p className="pt-3 fw-bold">Zone operations</p>
      <div className="gap-2 d-flex">
        <BasicButton onClick={() => setToggleManageZoneModal(true)}>
          Manage priorities
        </BasicButton>
        <BasicButton outlined onClick={modalToggle}>
          Edit hazards
        </BasicButton>
      </div>
      <div className="d-flex flex-row">
        <div className="zone-table-container">
          <Table responsive className="zone-table">
            <thead>
              <tr>
                {tableHeader.map((item, index) => (
                  <th
                    className={`table-text text-start pb-4 ${
                      item === 'Zone' ? 'ps-3' : 'ps-0'
                    }`}
                    key={index}
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {zones?.map((zone) => (
                <tr className="table-text row-text" key={zone.zone}>
                  <td className="zoneColumn">
                    <div className="d-flex align-items-center">
                      <div
                        className={`statusRectangle ${
                          AllStatus.find(({ status }) => status === zone.status)
                            ?.color
                        }`}
                      ></div>

                      <div className="no-word-break">Zone {zone.zone}</div>
                    </div>
                  </td>
                  {zone.status === ZoneStatus.Open ? (
                    <td className="priorityColor">
                      <div>
                        <StatusDot className="red"> P{zone.priority}</StatusDot>
                      </div>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td
                    className={` ${
                      zone.status === 'In progress' && 'in-progress'
                    }`}
                  >
                    {zone.status === 'In progress'
                      ? zone.status
                      : moment(zone.last_watered_time).fromNow()}
                  </td>
                  <td>{zone.timesWatered}</td>
                  <td
                    className={`${
                      zone.status === 'In progress' && 'in-progress-truck'
                    }`}
                  >
                    {zone.trucks ? zone.trucks.join(', ') : ''}
                  </td>
                  <td>{zone.hazard || ''}</td>
                  <td>
                    {zone.status === 'In progress'
                      ? '-'
                      : capitaliseFirstLetter(zone.lastJobStatus) ||
                        zone.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {toggleManageZoneModal && (
        <ManageZone
          zones={zones}
          setToggleManageZoneModal={setToggleManageZoneModal}
          refreshData={refreshDataWithZones}
        />
      )}
    </div>
  );
};

export default ZoneTable;
