import React, { useEffect, useState } from 'react';
import { SUMMARY_DATA } from '../../utils/constants';
import PieChartGraph from '../PieChart';

const CategoryCard = ({ closeWigets, data }) => {
  const [categoryData, setCategoryData] = useState([]);

  const mapCategoryData = (data) => {
    const categoryMap = {};
    data?.forEach((item) => {
      categoryMap[item.name] = item.data;
    });
    setCategoryData(categoryMap);
  };

  useEffect(() => {
    mapCategoryData(data);
  }, [data]);

  const renderPieChart = (chartData, title) => {
    const total = chartData.reduce((sum, value) => sum + value, 0);
    return (
      total > 0 && (
        <div
          style={{ width: '33%' }}
          className="d-flex flex-column justify-content-start align-items-start"
        >
          <b className="d-flex justify-content-start w-100 ps-5 mb-1">
            {title}
          </b>
          <div style={{ height: '114px' }} className="pie-container">
            <PieChartGraph
              key={title + title}
              data={chartData}
              outerRadius={51}
              chartData={[
                { name: 'passed', value: chartData[0] },
                { name: 'failed', value: chartData[1] },
                // { name: 'incomplete', value: chartData[2] },
              ]}
            />
          </div>
          <div className="mb-2 d-flex flex-column align-items-start justify-content-start ps-5">
            <b style={{ fontSize: '11px' }} className="">
              {chartData[1]} failed{' '}
            </b>
            <b style={{ fontSize: '11px' }} className="">
              {chartData[0]} passed
            </b>
            {/* <b style={{ fontSize: '11px' }} className="">
              {chartData[2]} incomplete
            </b> */}
          </div>
      
        </div>
      )
    );
  };

  return (
    <div
      className="widget-container3 white-background  mt-1"
      style={{ boxShadow: '0px 0px 0px' }}
    >
      <div
        style={{ borderBottom: '1px solid #EBEBEB' }}
        className="mb-2 d-flex white-background justify-content-between"
      >
        <div className="sub-header">By Category</div>

        <div className="mt-2  white-background pdf-pie-container">
          <div>
            {Object.keys(SUMMARY_DATA).map((data) => {
              return (
                <span key={data}>
                  <div>
                    <div
                      className="circle"
                      style={{ backgroundColor: SUMMARY_DATA[data].color }}
                    ></div>{' '}
                    {SUMMARY_DATA[data]?.label}
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{ borderBottom: '1px solid #EBEBEB' }}
        className=" body d-flex w-100"
      >
        {Object.entries(categoryData).map(([categoryName, categoryData]) =>
          renderPieChart(categoryData, categoryName),
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
