import React, { useEffect, useRef, useState } from 'react';
import { withAccessControl } from '../../../pages/service/with-access-control';
import { Map } from '../../Map/map';
import { CallWithAuth } from '../../../pages/service/apiActions';
import { GET_MATERIALS, GET_POLYGONS } from '../../action/apiPath';
import FunctionalButtons from '../../components/Buttons/FunctionalButtons';
import MaterialBreakDown from '../../components/Buttons/MaterialBreakDown';
import TopButton from '../../components/Buttons/TopButton';
import ConfirmationPopup from '../../components/ConfirmationPopup/ConfirmationPopup';
import MaterialMovementsHistory from '../../components/History/MaterialMovementsHistory';
import './index.css';
import MessagePopup from '../../components/MessagePopup/MessagePopup';
import moment from 'moment';

const MaterialMovements = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState({});
  const [materials, setMaterials] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState({});
  const [detailPopup, setDetailPopup] = useState({});
  const [mapType, setMapType] = useState('select'); //select / create / edit
  const [module, setModule] = useState('source'); // source / dump / history
  const [polygonType, setPolygonType] = useState('Active'); // Active / Inactive / history

  const [selectedTruckForLoadMarkers, setSelectedTruckForLoadMarkers] =
    useState(null);
  const [resetState, setResetState] = useState(1);
  const [allSourceDumps, setAllSourceDumps] = useState([]); //polygons
  const [loadMarkers, setLoadMarkers] = useState({});

  const [popupConfirmation, setPopupConfirmation] = useState('');
  const [popupMessage, setPopupMessage] = useState({ message: '', footer: '' });
  const [reloadDataForcefully, setReloadDataForcefully] = useState(false);

  const mapRef = useRef(null);

  const handleCursor = (key) => {
    const htmlElement = document.getElementById('map-container');
    if (key === 'pencil') {
      htmlElement.classList.add('mm-custom-cursor');
    } else if (key === 'default') {
      htmlElement.classList.remove('mm-custom-cursor');
    }
  };

  const fetchAllSourceDumps = async () => {
    try {
      const response = await CallWithAuth(
        'GET',
        GET_POLYGONS({
          module: module ?? 'source',
          date: moment().format('yyyy-MM-DD'),
        }),
      );
      const geofenceData = response?.res?.data?.data;

      setAllSourceDumps(geofenceData);

      const markers = { unknown: [] };

      geofenceData?.forEach((geofence) => {
        geofence?.loads?.forEach((load) => {
          const objectTitle = load?.objectTitle || 'unknown';
          markers[objectTitle] = markers[objectTitle] || [];
          markers[objectTitle].push(load);
        });
      });

      setLoadMarkers(markers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (module !== 'history') {
      fetchAllSourceDumps();
    }
  }, [module, reloadDataForcefully]);

  useEffect(() => {
    setDetailPopup({});
    setSelectedPoint({});
  }, [module, mapType]);

  const fetchMaterials = async () => {
    try {
      const response = await CallWithAuth('GET', GET_MATERIALS);
      if (response?.res?.data?.status === 200) {
        setMaterials(response?.res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMaterialColor = (material) => {
    let color = 'rgba(255, 0, 0 , 1)';
    materials?.map((entry) => {
      if (entry?.MaterialType == material) {
        color = entry?.color;
      }
    });
    return color;
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  return (
    <div className="mm-body">
      <div pageName="Source & Dump Points">
        <div
          style={{ marginBottom: '0.7rem', paddingLeft: '0.8rem' }}
          className="d-flex"
        >
          {[
            {
              title: 'Source Points',
              img: 'source-icon.svg',
              module: 'source',
            },
            { title: 'Dump Points', img: 'dump-icon.svg', module: 'dump' },
            {
              title: 'History',
              img: module === 'history' ? 'history.svg' : 'historyu.svg',
              module: 'history',
            },
          ]?.map((button, index) => (
            <TopButton
              key={index}
              onClick={() => {
                setModule(button?.module);
                setMapType('select');
                setSelectedPolygon({});
              }}
              title={button.title}
              img={button.img}
              disabled={loading}
              style={{
                background: module === button.module ? '#05795F' : '#2B2D2F',
                font:
                  module === button.module
                    ? 'normal normal bold 12px/18px Open Sans'
                    : 'normal normal normal 12px/18px Open Sans',
                marginLeft: index !== 0 ? '20px' : '0px',
                color: module === button.module ? '#FFF' : 'rgb(229, 229, 231)',
              }}
            />
          ))}
        </div>

        <div className=" map-area " style={{ overflow: 'hidden' }}>
          <div
            className={` d-flex h-${
              module == 'history' ? '0' : '100'
            } position-relative`}
            style={{
              backgroundColor: '#DDDDDD',
              overflowY: 'hidden',
              objectFit: 'cover',
            }}
          >
            <Map
              type={mapType}
              module={module}
              mapRef={mapRef}
              polygonType={polygonType}
              polygons={allSourceDumps}
              markers={loadMarkers}
              getMaterialColor={getMaterialColor}
              selectedMarker={{}}
              selectedPolygon={selectedPolygon}
              selectedPoint={selectedPoint}
              onMarkerClick={() => {}}
              onPolygonClick={(v) => {
                if (v?.isIntersecting) setMapType('select');
                setSelectedPolygon(v);
              }}
              selectedTruckForLoadMarkers={selectedTruckForLoadMarkers}
              onDetailPopupClose={() => {
                setDetailPopup({});
              }}
              materials={materials}
              detailPopup={detailPopup}
              resetState={resetState}
            />

            {module != 'history' && (
              <FunctionalButtons
                allSourceDumps={allSourceDumps}
                type={mapType}
                module={module}
                getMaterialColor={getMaterialColor}
                selectedPolygon={selectedPolygon}
                onEditPolygonPoints={(v) => {
                  setSelectedPolygon(v);
                }}
                changeMapType={(v) => {
                  setMapType(v);
                }}
                handleCursor={handleCursor}
                data={[]}
                selectedTruckForLoadMarkers={selectedTruckForLoadMarkers}
                setSelectedTruckForLoadMarkers={setSelectedTruckForLoadMarkers}
                onSelectPoint={(v) => {
                  setSelectedPoint(v);
                }}
                onResetCreateState={() => {
                  setResetState(resetState + 1);
                }}
                onDemandDetailPopup={(v) => {
                  setDetailPopup(v);
                }}
                setPopupConfirmation={setPopupConfirmation}
                setPopupMessage={setPopupMessage}
                setPolygonType={setPolygonType}
                reloadForcefully={() => {
                  setReloadDataForcefully(!reloadDataForcefully);
                }}
              />
            )}

            {mapType != 'create' && module != 'history' && (
              <MaterialBreakDown
                materials={materials}
                allSourceDumps={allSourceDumps}
              />
            )}
          </div>

          {popupConfirmation && <ConfirmationPopup {...popupConfirmation} />}

          {module == 'history' && (
            <div
              style={{ paddingLeft: '0.8rem' }}
              className=" d-flex h-100 position-relative"
            >
              <MaterialMovementsHistory />
            </div>
          )}
          {popupMessage?.message && (
            <MessagePopup
              popupMessage={popupMessage}
              setPopupMessage={setPopupMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialMovements;
