import { useContext, useMemo, useState, useEffect } from 'react';
import { UserContext } from './userContext';
import Unauthorized from '../unauthorized';
import Sidebar from '../Header/Sidebar';
import Header from '../Header';
import { sections, subSections } from '../../dustControl/utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from './globalContext';
import { startCase } from 'lodash';

const getContainerClassNameByDepartment = (departmentCode) => {
  if (departmentCode === 200 || departmentCode === 800) {
    return 'inner-container-not-overflow ';
  } else if (departmentCode === 400) {
    //scrap bins
    return 'inner-container';
  } else if (departmentCode === 500) {
    //kpi-dashboard
    return 'mr-3 inner-container';
  } else return 'mx-3 inner-container';
};

export const withAccessControl = (Component, defaultSelectedTab) => (props) => {
  const { code: departmentCode } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { showLiveAssetTracking, setShowLiveAssetTracking } =
    useGlobalContext();

  const getSelectedTabFromUrl = () => {
    if (departmentCode == 800) {
      if (queryParams?.get('type') == 'live') {
        return 0;
      } else if (queryParams?.get('type') == 'history') {
        return 1;
      }
    }
    // write more according to department logic
    return defaultSelectedTab ?? 0;
  };

  const handleTabChange = (newTab) => {
    navigate({
      pathname: location.pathname,
      search: '?',
      replace: true,
    });
    setSelectedTab(newTab);
  };

  const { userInfo, departments } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(getSelectedTabFromUrl);
  const [selectedSubText, setSelectedSubText] = useState('');
  const [sidebarActive, setSidebarActive] = useState(true);
  const [showBack, setShowBack] = useState(false);

  const handleOnBack = () => {
    if (showLiveAssetTracking && departmentCode === 800) {
      setShowLiveAssetTracking(false);
      setShowBack(false);
    }
  };

  useEffect(() => {
    if (showLiveAssetTracking && departmentCode === 800) {
      setSelectedSubText('Live Tracking');
      setShowBack(true);
    } else {
      setShowLiveAssetTracking(false);
      setShowBack(false);
      const subTexts = subSections[departmentCode];
      if (subTexts && subTexts.length > 0) {
        setSelectedSubText(startCase(subTexts[selectedTab] || subTexts[0]));
      } else {
        setSelectedSubText('');
      }
    }
  }, [
    departmentCode,
    selectedTab,
    showLiveAssetTracking,
    setShowLiveAssetTracking,
  ]);

  useEffect(() => {
    const subTexts = subSections[departmentCode];
    if (subTexts && subTexts.length > 0) {
      setSelectedSubText(subTexts[selectedTab] || subTexts[0]);
    } else {
      setSelectedSubText('');
    }
  }, [departmentCode, selectedTab]);

  const department = useMemo(() => {
    return departments?.find(({ code }) => code === departmentCode);
  }, [departments, departmentCode]);
  if (
    !userInfo.departments?.includes(department?.code) &&
    department?.is_restricted
  )
    return <Unauthorized />;
  else
    return (
      <div className="main-container">
        <Sidebar
          title={sections[departmentCode]}
          selectedTab={selectedTab}
          setSelectedTab={handleTabChange}
          subSections={subSections[departmentCode]}
          setSelectedSubText={setSelectedSubText}
          sidebarActive={sidebarActive}
          code={departmentCode}
          setSidebarActive={setSidebarActive}
        />
        <div
          className={`mt-3 ${getContainerClassNameByDepartment(
            departmentCode,
          )}`}
        >
          <Header
            showDropDown
            title={sections[departmentCode]}
            code={departmentCode}
            selectedSubText={selectedSubText}
            showBack={showBack}
            onBack={handleOnBack}
          />
          <Component
            {...props}
            sidebarActive={sidebarActive}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
    );
};
