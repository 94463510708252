import { FunctionComponent } from 'react';

type ScrapIconProps = {
  width: number;
  height: number;
};

export const ScrapIcon: FunctionComponent<ScrapIconProps> = (
  props: ScrapIconProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46.127 47.775"
    {...props}
  >
    <g
      fill="none"
      stroke="#2b2d2f"
      strokeLinecap="round"
      strokeWidth={2.3}
      data-name="Group 46068"
    >
      <path
        strokeLinejoin="round"
        d="M27.736 11.072 28.864 6"
        data-name="Line 2179"
      />
      <path
        d="m23.33 23.076-3.623-9.126.536-5.162h0l2.232-1.041.341-3.339 6.3-3.167a15.8 15.8 0 0 1 5.317 3.321 9.446 9.446 0 0 1 1.749 11.331l-.042.076a14.584 14.584 0 0 1-3.528 4.21c-3.326 1.347-9.282 2.897-9.282 2.897Z"
        data-name="Path 34734"
      />
      <path d="m22.516 12.944 5.22-1.871 4.88 9.1" data-name="Path 34735" />
      <path
        d="m39.466 46.205-8.749.037-2.174-3.789-4.506.167-4.388-6.712 2.255-7.053 18.32-5.683c4.328 2.971 6.019 9.444 3.662 14.527Z"
        data-name="Path 34736"
      />
      <path d="m23.565 35.715 9.58-.469 4.016-5.888" data-name="Path 34737" />
      <path d="m39.466 46.204-6.321-10.959" data-name="Line 2180" />
      <path
        d="m17.941 24.148-6.979 2.771-3.949-.41h0l-.8-1.707-2.553-.261-2.419-4.822a12.086 12.086 0 0 1 2.54-4.066 7.224 7.224 0 0 1 8.666-1.334l.058.032a11.154 11.154 0 0 1 3.22 2.7c1.031 2.542 2.216 7.097 2.216 7.097Z"
        data-name="Path 34748"
      />
      <path d="m6.117 21.289 2.18-2.571 5.336 3.309" data-name="Path 34749" />
    </g>
  </svg>
);
