import React from 'react';
import DownloadIcon from '../../assets/icons/download.svg';
import CloseIcon from '../../assets/icons/cross.svg';
import { Spinner } from 'react-bootstrap';

const LoadingScreen = () => {
  return (
    <div className="text-center">
      <Spinner animation="border " role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

function PdfModal({ closeModal, children, onDownload, loading }) {
  return (
    <div className="modal-view-report">
      <div className="pdf-modal-content">{children}</div>

      {/* Download/close icons */}
      <div className="download-icon-report gap-3">
        <div
          onClick={() => {
            onDownload();
          }}
          className="cursor-pointer text-white"
        >
          {loading ? (
            <LoadingScreen />
          ) : (
            <img src={DownloadIcon} alt="download" />
          )}
        </div>

        <div
          className="cursor-pointer text-white"
          onClick={() => closeModal(false)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
    </div>
  );
}

export default PdfModal;
