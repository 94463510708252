import Select from 'react-select';
import { customStyles } from './style';
import { generateTimeOptions } from '../../utils/helper';

interface TimeSelectProps {
  value?: string;
  onChange: (selectedOption: any) => void;
  disabled?: boolean;
  placeholder?: string;
}

const TimeSelect: React.FC<TimeSelectProps> = ({
  value,
  onChange,
  disabled = false,
  placeholder,
}) => {
  return (
    <Select
      isDisabled={disabled}
      value={value ? { label: value, value: value } : null}
      onChange={(v) => onChange(v)}
      options={generateTimeOptions()}
      isClearable={true}
      placeholder={placeholder || '00:00 AM'}
      styles={customStyles}
    />
  );
};

export default TimeSelect;
