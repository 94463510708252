import { HistoryCategory, HistoryFlyerComponent } from './types';
import PieChart from '../PieChart';
import { TableCell, TableHead, TableRow } from '@mui/material';

export const renderDisplayComponentsForFlyer = (
  flyerComponents: HistoryFlyerComponent[],
): JSX.Element[] => {
  const flyerComponentsInJSXForm: JSX.Element[] = flyerComponents.map(
    (flyerComponent: HistoryFlyerComponent, index: number) => {
      return (
        <>
          <div>
            <b className="flyer-component-title">{flyerComponent.title}</b>
          </div>
          <PieChart
            data={flyerComponent.chartComponentProps.data}
            legend={flyerComponent.chartComponentProps.legend}
            subscriber={'History'}
          />
          {index < flyerComponents.length - 1 && (
            <hr className="horizontal-separator" />
          )}
        </>
      );
    },
  );
  return flyerComponentsInJSXForm;
};

export const renderTableHeadersForHistory = (
  historyCategory: HistoryCategory,
): JSX.Element => {
  const historyFields: string[] = [];
  if (historyCategory === HistoryCategory.BINS) {
    const binHistoryFields = [
      'Bin ID',
      'Job Ref',
      'Job Status',
      'Job Type',
      'Scrap Type',
      'Truck ID',
      'Operator',
      'Job Cycle Duration',
      'Download',
    ];
    historyFields.push(...binHistoryFields);
  } else {
    const truckHistoryFields = [
      'Truck ID',
      'Job Ref',
      'Bin ID',
      'Job Status',
      'Job Type',
      'Operator',
      'Delays',
      'Job Cycle Duration',
      'Download',
    ];
    historyFields.push(...truckHistoryFields);
  }

  const tableHeaderRow: JSX.Element = (
    <TableHead
      style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}
    >
      <TableRow>
        {historyFields.map((historyField: string) => (
          <TableCell>
            <b>{historyField}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return tableHeaderRow;
};
