// Timeline.tsx
import React, { useRef, useState, useEffect } from 'react';
import './style.css';
import TrafficLightIndicator from './TrafficLightIndicator';
import { convertHoursToTimeString } from '../utils';
import { Segment, TimelineProps } from '../types';

const Timeline: React.FC<TimelineProps> = ({
  segments,
  range,
  unwantedWidth,
  formator,
  getDate,
  getInterval,
}) => {
  const [mouseX, setMouseX] = useState<number | null>(null);
  const [popupData, setPopupData] = useState<Segment | null>(null);
  const svgGraph = useRef<SVGSVGElement | null>(null);
  const totalDuration = Math.ceil(range);
  const [widthOFGraph, setWidthOFGraph] = useState<number>(
    window.innerWidth - unwantedWidth,
  );
  const radius = 12.5;
  const heightOrYvalue = '35%';

  useEffect(() => {
    const handleResize = (e: UIEvent) => {
      setWidthOFGraph((e.target as Window).innerWidth - unwantedWidth);
    };

    window.addEventListener('resize', handleResize);
    setWidthOFGraph(window.innerWidth - unwantedWidth);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [unwantedWidth]);

  return (
    <div className="timeline-container">
      <svg
        ref={svgGraph}
        height="60"
        style={{ overflow: 'visible', display: 'block' }}
        viewBox={`0 0 ${widthOFGraph} 60`}
        preserveAspectRatio="xMinYMin meet"
      >
        {/* Render timeline segments */}
        <g>
          <rect
            x="0%"
            y={heightOrYvalue}
            width={'99.65%'}
            height={heightOrYvalue}
            style={{ transition: 'all 0.5s ease' }}
            rx={radius}
            ry={radius}
            fill="#DBDBDB"
            className="segment"
            onMouseEnter={() => setPopupData(null)}
          />
        </g>

        {segments.map((segment, index) => {
          const width =
            (Math.max(segment.end - segment.start, 0) / totalDuration) * 100;
          const x = (segment.start / totalDuration) * 100;
          const isFirstSegment = index === 0;
          const isLastSegment = index === segments.length - 1;

          return (
            <g key={index}>
              {isLastSegment && !isFirstSegment && (
                <defs>
                  <clipPath id={'rounded-corner' + index}>
                    <rect
                      x={`${x}%`}
                      y={heightOrYvalue}
                      width={`${width + 0.05}%`}
                      height={heightOrYvalue}
                    />
                  </clipPath>
                </defs>
              )}

              {!isLastSegment && isFirstSegment && (
                <defs>
                  <clipPath id={'rounded-corner' + index}>
                    <rect
                      x={`${x}%`}
                      y={heightOrYvalue}
                      width={`${width + 0.05}%`}
                      height={heightOrYvalue}
                    />
                  </clipPath>
                </defs>
              )}

              <rect
                x={`${Math.max(isLastSegment ? x - 1 : x, 0)}%`}
                y={heightOrYvalue}
                width={`${isFirstSegment || isLastSegment ? width + 1 : width}%`}
                height={heightOrYvalue}
                style={{ transition: 'all 0.5s ease' }}
                rx={`${isLastSegment || isFirstSegment ? radius : 0}`}
                ry={radius}
                fill={segment.status ? '#04D149' : '#EB5F5F'}
                className="segment"
                clipPath={`url(#${'rounded-corner' + index})`}
                onMouseLeave={() => setMouseX(null)}
                onMouseMove={(e) =>
                  setMouseX(
                    e.clientX -
                      svgGraph.current!.getBoundingClientRect().left +
                      8,
                  )
                }
                onMouseEnter={() => setPopupData(segment)}
              />
            </g>
          );
        })}

        {Array.from({ length: totalDuration }, (_, i) => {
          const x = (i / totalDuration) * 100;
          return (
            <text
              key={i}
              x={`${x}%`}
              y="95%"
              fill="#707070"
              fontWeight="900"
              fontSize="12px"
              fontFamily="Open Sans"
              textAnchor="middle"
              alignmentBaseline="middle"
              className="time-label"
            >
              {formator(i, range)}
            </text>
          );
        })}

        {mouseX && popupData && (
          <>
            {/* Line */}
            <line
              x1={Math.max(mouseX - 15, 0)}
              x2={Math.max(mouseX - 15, 0)}
              y1="-35%"
              y2="70%"
              stroke="#07795F"
              strokeWidth="1"
              style={{ transition: 'all 0.5s ease' }}
              onMouseLeave={() => setMouseX(null)}
              onMouseMove={(e) =>
                setMouseX(
                  e.clientX -
                    svgGraph.current!.getBoundingClientRect().left +
                    8,
                )
              }
            />

            {/* Tooltip Box */}
            <foreignObject
              style={{
                borderRadius: 8,
                boxShadow: '0px 2px 6px #0000001A',
                backgroundColor: 'white',
                border: '1px solid #E1E8F4',
                position: 'relative',
                zIndex: 1000,
                opacity: 1,
                padding: '13px 16px',
              }}
              x={mouseX > 175 ? mouseX - 240 : mouseX + 10}
              y={-45}
              width={220}
              height={110}
            >
              <h5 className="fw-bold mb-1" style={{ fontSize: 14 }}>
                {getInterval(popupData)}
              </h5>
              <h6
                className="fw-bold my-1"
                style={{ fontSize: 12, fontStyle: 'italic', color: '#8e8e8f' }}
              >
                {getDate(popupData)}
              </h6>

              <div className="d-flex justify-content-between me-3 mt-4">
                <div
                  style={{ fontSize: 14 }}
                  className="mt-1 me-3 d-flex align-items-center"
                >
                  <TrafficLightIndicator
                    stop={!popupData!.status}
                    shadow={false}
                    size={8}
                  />
                  <div className="ms-1">
                    {popupData!.status ? 'Runtime' : 'Stopped'}
                  </div>
                </div>
                <span className="fw-bold">
                  {convertHoursToTimeString(popupData!.end - popupData!.start)}
                </span>
              </div>
            </foreignObject>
          </>
        )}
      </svg>
    </div>
  );
};

export default Timeline;
