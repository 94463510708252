import { useEffect, useMemo, useRef } from 'react';

// this function return a new function that is debounced based on the specified timeout.
const debounce = (func, timeout) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(func, timeout);
  };
};

export const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    // Updates the ref.current value whenever the `callback` function changes.
    ref.current = callback;
  }, [callback]);

  // Memoizes the debounced callback function to ensure it is only recreated when necessary.
  const debouncedCallback = useMemo(() => {
    // Retrieves the callback function stored in ref.current and creates a new debounced function.
    const func = () => {
      ref.current?.();
    };
    return debounce(func, 500);
  }, []);

  return debouncedCallback;
};

function useInfiniteScroll(callback) {
  const observer = useRef();

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        callback();
      }
    });

    return () => observer.current.disconnect();
  }, [callback]);

  return observer;
}

export default useInfiniteScroll;
