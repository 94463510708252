export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const ZONE_DATA = {
  ZONE1: { color: '#138BAA', name: 'zone1', label: 'Zone 1' },
  ZONE2: { color: '#FAF265', name: 'zone2', label: 'Zone 2' },
  ZONE3: { color: '#4C4B66', name: 'zone3', label: 'Zone 3' },
  ZONE4: { color: '#FFC850', name: 'zone4', label: 'Zone 4' },
  ZONE5: { color: '#00C48C', name: 'zone5', label: 'Zone 5' },
  ZONE6: { color: '#6C63FF', name: 'zone6', label: 'Zone 6' },
};

export const STATUS_DATA = {
  ZONE1: { color: '#00C48C', name: 'passed', label: 'Passed' },
  ZONE2: { color: '#E3402F', name: 'failed', label: 'Failed' },
  ZONE3: { color: '#325D88', name: 'incomplete', label: 'Incomplete' },
};

export const SUMMARY_DATA = {
  ZONE1: { color: '#00C48C', name: 'passed', label: 'Passed' },
  ZONE2: { color: '#E3402F', name: 'failed', label: 'Failed' },
};

export const ENDPOINTS = {
  login: 'auth/login',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'users/reset-password',
  getZones: 'zone/',
  getDailyZones: 'jobs/get-zones',
  getActiveOperators: 'users/active-operators',
  logout: 'auth/logout',
  editHazards: 'zone/edit-hazard',
  getTrucks: 'trucks',
  changeZoneStatus: 'zone/change-status',
  getAllUsers: 'users/users-list',
  addUser: 'users/add-user',
  resendInvitation: 'users/resend-invitation',
  cancelInvitation: 'users/cancel-invitation',
  editUser: 'users/edit-user',
  deactivateDeleteUsers: 'users/deactivate-delete',
  getDepartments: 'departments/',
  getLicenses: 'users/licenses',
  getNewToken: 'auth/refresh-token',
  setTimer: (zone, reminderTime) =>
    `zone/set-timer?zone=${zone}&timer=${reminderTime}`,
  getWaterConsumption: 'water-usage',
  changePassword: 'users/change-password',
  authChangePassword: 'auth/change-password',
  dismissAlert: (zone) => `zone/dismiss-alert?zone=${zone}`,
  getJobs: 'jobs/jobs-list',
  getJob: 'jobs/get-job',
  getJobDailyReport: 'reports/jobs-daily-report',
  getZonesReport: 'reports/zone-daily-report',
  getSingleJob: 'jobs/single-job',
  getRefills: 'waterRefill/',
  validateToken: 'auth/validate-token',
  generatePin: 'users/generate-pin',
};

export const headerText = [
  'User Name',
  'Status',
  'User Type',
  'Email',
  'Department',
  'Equipment License',
  'PIN No',
  'Added by',
  'Actions',
];

export const headerText2 = [
  'Asset ID',
  'Status',
  'Ref No.',
  'Operator',
  'Login/ Engine On',
  'Check time',
  'Failed Checks',
  'Engine Hours',
  "Supervisor's Signature",
];
export const UserType = {
  admin: { value: 'Admin', name: 'Super Admin' },
  supervisor: { value: 'Supervisor', name: 'Supervisor' },
  operator: { value: 'Operator', name: 'Operator' },
};
export const UserStatus = {
  invited: 'Invited',
  inactive: 'Inactive',
  active: 'Active',
};

export const ZoneStatus = {
  Open: 'Open',
  Completed: 'Completed',
  Alerted: 'Alerted',
  Progress: 'In progress',
};
export const sections = {
  100: 'Dust Control',
  200: 'Material Movements',
  300: 'Maintenance',
  400: 'Scrap Bins',
  500: 'KPI Dashboard',
  600: 'Pre-Start Checks',
  700: 'Manage Users',
  800: 'Asset Tracking',
};
export const subSections = {
  100: [
    'Dust control progress',
    'Reports',
    'Jobs history',
    'Water towers interactions',
  ],
  200: [],
  300: [],
  400: [],
  500: [],
  600: [],
  700: [],
  800: [],
};

export const links = {
  100: '/dust-control',
  200: '/material-movements',
  300: '/maintenance',
  400: '/scrap-bins',
  500: '/kpi-dashboard',
  600: '/pre-start-check',
  700: '/user-management',
  800: '/asset-tracking',
};

export const desiredOrder = [500, 400, 100, 200, 300, 600, 700, 800];

export const userType = {
  Supervisor: 'Supervisor',
  Admin: 'Super Admin',
  Operator: 'Operator',
};

export const data = [
  {
    assetID: '1',
    status: 'Status 1',
    refNo: 'Ref No 1',
    operator: 'Operator 1',
    loginOrEngineOn: 'Login 1',
    checkTime: 'Time 1',
    failedChecks: 'Failed 1',
    engineHours: 'Hours 1',
    supervisorSignature: 'Supervisor 1',
    department: 'Dept1',
    category: 'Cat1',
  },
  {
    assetID: '2',
    status: 'Status 2',
    refNo: 'Ref No 2',
    operator: 'Operator 2',
    loginOrEngineOn: 'Login 2',
    checkTime: 'Time 2',
    failedChecks: 'Failed 2',
    engineHours: 'Hours 2',
    supervisorSignature: 'Supervisor 2',
    department: 'Dept1',
    category: 'Cat1',
  },
  {
    assetID: '3',
    status: 'Status 3',
    refNo: 'Ref No 3',
    operator: 'Operator 3',
    loginOrEngineOn: 'Login 3',
    checkTime: 'Time 3',
    failedChecks: 'Failed 3',
    engineHours: 'Hours 3',
    supervisorSignature: 'Supervisor 3',
    department: 'Dept1',
    category: 'Cat1',
  },
  {
    assetID: '4',
    status: 'Status 4',
    refNo: 'Ref No 4',
    operator: 'Operator 4',
    loginOrEngineOn: 'Login 4',
    checkTime: 'Time 4',
    failedChecks: 'Failed 4',
    engineHours: 'Hours 4',
    supervisorSignature: 'Supervisor 4',
    department: 'Dept1',
    category: 'Cat2',
  },
  {
    assetID: '5',
    status: 'Status 5',
    refNo: 'Ref No 5',
    operator: 'Operator 5',
    loginOrEngineOn: 'Login 5',
    checkTime: 'Time 5',
    failedChecks: 'Failed 5',
    engineHours: 'Hours 5',
    supervisorSignature: 'Supervisor 5',
    department: 'Dept1',
    category: 'Cat2',
  },
  {
    assetID: '6',
    status: 'Status 6',
    refNo: 'Ref No 6',
    operator: 'Operator 6',
    loginOrEngineOn: 'Login 6',
    checkTime: 'Time 6',
    failedChecks: 'Failed 6',
    engineHours: 'Hours 6',
    supervisorSignature: 'Supervisor 6',
    department: 'Dept1',
    category: 'Cat2',
  },
  {
    assetID: '7',
    status: 'Status 7',
    refNo: 'Ref No 7',
    operator: 'Operator 7',
    loginOrEngineOn: 'Login 7',
    checkTime: 'Time 7',
    failedChecks: 'Failed 7',
    engineHours: 'Hours 7',
    supervisorSignature: 'Supervisor 7',
    department: 'Dept2',
    category: 'Cat1',
  },
  {
    assetID: '8',
    status: 'Status 8',
    refNo: 'Ref No 8',
    operator: 'Operator 8',
    loginOrEngineOn: 'Login 8',
    checkTime: 'Time 8',
    failedChecks: 'Failed 8',
    engineHours: 'Hours 8',
    supervisorSignature: 'Supervisor 8',
    department: 'Dept2',
    category: 'Cat1',
  },
  {
    assetID: '9',
    status: 'Status 9',
    refNo: 'Ref No 9',
    operator: 'Operator 9',
    loginOrEngineOn: 'Login 9',
    checkTime: 'Time 9',
    failedChecks: 'Failed 9',
    engineHours: 'Hours 9',
    supervisorSignature: 'Supervisor 9',
    department: 'Dept2',
    category: 'Cat1',
  },
  {
    assetID: '10',
    status: 'Status 10',
    refNo: 'Ref No 10',
    operator: 'Operator 10',
    loginOrEngineOn: 'Login 10',
    checkTime: 'Time 10',
    failedChecks: 'Failed 10',
    engineHours: 'Hours 10',
    supervisorSignature: 'Supervisor 10',
    department: 'Dept2',
    category: 'Cat2',
  },
  {
    assetID: '11',
    status: 'Status 11',
    refNo: 'Ref No 11',
    operator: 'Operator 11',
    loginOrEngineOn: 'Login 11',
    checkTime: 'Time 11',
    failedChecks: 'Failed 11',
    engineHours: 'Hours 11',
    supervisorSignature: 'Supervisor 11',
    department: 'Dept2',
    category: 'Cat2',
  },
  {
    assetID: '12',
    status: 'Status 12',
    refNo: 'Ref No 12',
    operator: 'Operator 12',
    loginOrEngineOn: 'Login 12',
    checkTime: 'Time 12',
    failedChecks: 'Failed 12',
    engineHours: 'Hours 12',
    supervisorSignature: 'Supervisor 12',
    department: 'Dept2',
    category: 'Cat2',
  },
  {
    assetID: '13',
    status: 'Status 13',
    refNo: 'Ref No 13',
    operator: 'Operator 13',
    loginOrEngineOn: 'Login 13',
    checkTime: 'Time 13',
    failedChecks: 'Failed 13',
    engineHours: 'Hours 13',
    supervisorSignature: 'Supervisor 13',
    department: 'Dept2',
    category: 'Cat3',
  },
  {
    assetID: '14',
    status: 'Status 14',
    refNo: 'Ref No 14',
    operator: 'Operator 14',
    loginOrEngineOn: 'Login 14',
    checkTime: 'Time 14',
    failedChecks: 'Failed 14',
    engineHours: 'Hours 14',
    supervisorSignature: 'Supervisor 14',
    department: 'Dept2',
    category: 'Cat3',
  },
  {
    assetID: '15',
    status: 'Status 15',
    refNo: 'Ref No 15',
    operator: 'Operator 15',
    loginOrEngineOn: 'Login 15',
    checkTime: 'Time 15',
    failedChecks: 'Failed 15',
    engineHours: 'Hours 15',
    supervisorSignature: 'Supervisor 15',
    department: 'Dept2',
    category: 'Cat3',
  },
];
