import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Query,
  ShiftLoadTimeFrameMapping,
  ShiftLoaderDateRangeType,
  ShiftLoadsGraphOption,
} from '../../../../utils/constants';
import { Spinner } from 'react-bootstrap';
import { API_METHODS } from '../../../../../dustControl/utils/constants';
import { Endpoint, getUrl } from '../../helper';
import * as toast from '../../../../../dustControl/components/toast';
import { fetch } from '../../../../../utils/api';
import { GraphData, HourlyGraphData } from '../../../../utils/types';
import LoadingScreen from '../../../../../dustControl/components/loading';
import NavigateArrow from '../../../../components/navigateArrow';
import Daily from './subGraphs/daily';
import HourlyBar from './subGraphs/hourlyBar';
import HourlyLine from './subGraphs/hourlyLine';
import Monthly from './subGraphs/monthly';
import Tabs from './components/Tabs';
import { getUnixTimestampsFromFilter } from '../../utils';
import {
  canMoveRight,
  getGraphData,
  processHourlyDataForGraph,
  setDataForGraphs,
} from './utils';
import { ShiftLoaderGraphProps, TabsData } from './Types';
import moment from 'moment';

const ShiftLoaderGraph: React.FC<ShiftLoaderGraphProps> = (props) => {
  const [selectedTab, setSelectedTab] = useSearchParams({
    [Query.GRAPH_TAB]:
      props.dateRangeType == ShiftLoaderDateRangeType.RANGE_48_PLUS
        ? ShiftLoadsGraphOption.DAYS
        : ShiftLoadsGraphOption.HOURS,
  });
  const maxPageReached = useRef<{ [key: string]: undefined | number }>({
    [ShiftLoadsGraphOption.HOURS.toString()]: undefined,
    [ShiftLoadsGraphOption.DAYS.toString()]: undefined,
    [ShiftLoadsGraphOption.MONTHS.toString()]: undefined,
  });

  const minPageReached = useRef<{ [key: string]: undefined | number }>({
    [ShiftLoadsGraphOption.HOURS.toString()]: undefined,
    [ShiftLoadsGraphOption.DAYS.toString()]: undefined,
    [ShiftLoadsGraphOption.MONTHS.toString()]: undefined,
  });

  const selectedTabValue: string | null = selectedTab.get(Query.GRAPH_TAB);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [hourlyDefaultGraphData, setHourlyDefaultGraphData] = useState<
    HourlyGraphData[]
  >([]);
  const [hourlyPastGraphData, setHourlyPastGraphData] = useState<
    HourlyGraphData[]
  >([]);
  const [hourlyFutureGraphData, setHourlyFutureGraphData] = useState<
    HourlyGraphData[]
  >([]);

  const [dailyPastGraphData, setDailyPastGraphData] = useState<GraphData[]>([]);
  const [dailyDefaultGraphData, setDailyDefaultGraphData] = useState<
    GraphData[]
  >([]);
  const [dailyFutureGraphData, setDailyFutureGraphData] = useState<GraphData[]>(
    [],
  );

  const [monthlyDefaultGraphData, setMonthlyDefaultGraphData] = useState<
    GraphData[]
  >([]);
  const [monthlyPastGraphData, setMonthlyPastGraphData] = useState<GraphData[]>(
    [],
  );
  const [monthlyFutureGraphData, setMonthlyFutureGraphData] = useState<
    GraphData[]
  >([]);

  const [graphPageNumber, setGraphPageNumber] = useState(0);
  const navigate = useNavigate();
  const [extraHourlyPage, setExtraHourlyPage] = useState<undefined | string>(
    undefined,
  );

  const tabs: TabsData[] = [
    {
      label: ShiftLoadsGraphOption.HOURS,
      onClick: () => {
        selectedTab.set(Query.GRAPH_TAB, ShiftLoadsGraphOption.HOURS);
        setSelectedTab(selectedTab);
      },
    },
    {
      label: ShiftLoadsGraphOption.DAYS,
      onClick: () => {
        selectedTab.set(Query.GRAPH_TAB, ShiftLoadsGraphOption.DAYS);
        setSelectedTab(selectedTab);
      },
    },
    {
      label: ShiftLoadsGraphOption.MONTHS,
      onClick: () => {
        selectedTab.set(Query.GRAPH_TAB, ShiftLoadsGraphOption.MONTHS);
        setSelectedTab(selectedTab);
      },
    },
  ];

  const resetStates = () => {
    setGraphPageNumber(0);

    setHourlyDefaultGraphData([]);
    setHourlyPastGraphData([]);
    setHourlyFutureGraphData([]);

    setDailyPastGraphData([]);
    setDailyDefaultGraphData([]);
    setDailyFutureGraphData([]);

    setMonthlyDefaultGraphData([]);
    setMonthlyPastGraphData([]);
    setMonthlyFutureGraphData([]);

    maxPageReached.current[ShiftLoadsGraphOption.HOURS] = undefined;
    maxPageReached.current[ShiftLoadsGraphOption.DAYS] = undefined;
    maxPageReached.current[ShiftLoadsGraphOption.MONTHS] = undefined;

    minPageReached.current[ShiftLoadsGraphOption.HOURS] = undefined;
    minPageReached.current[ShiftLoadsGraphOption.DAYS] = undefined;
    minPageReached.current[ShiftLoadsGraphOption.MONTHS] = undefined;
  };

  const getTimelineData = async (
    grouping: string | null,
    direction: string,
  ) => {
    if (!grouping) return;

    const filters = getUnixTimestampsFromFilter(
      props.filterValues,
      grouping == ShiftLoadsGraphOption.HOURS,
    );

    if (filters.fromDate >= filters.toDate) {
      return;
    }

    setIsLoading(true);

    try {
      let processedData: any = [];
      let pageOffset =
        direction == 'left'
          ? maxPageReached.current[grouping]
          : minPageReached.current[grouping];

      if (
        grouping === ShiftLoadsGraphOption.HOURS &&
        direction === extraHourlyPage &&
        (maxPageReached.current[grouping] != 0 ||
          minPageReached.current[grouping] != 0)
      )
        pageOffset = (pageOffset || 0) + (direction === 'left' ? -1 : 1);

      const response = await fetch({
        method: API_METHODS.GET,
        endPoint: getUrl(Endpoint.getTimelineLoads),
        reqParams: {
          grouping: grouping.toLowerCase(),
          ...filters,
          pageOffset,
        },
        navigateToLoginPage: () => navigate('/'),
      });

      const responseData = await response.data;
      processedData = responseData as GraphData[];

      switch (grouping) {
        case ShiftLoadsGraphOption.HOURS:
          const slicedData = processedData;
          const processedDataForGraph = processHourlyDataForGraph(slicedData);
          // Check if the default page data is being loaded or if the left/right arrow is clicked
          // There are three sections on the page: Past Data, Default Data, and Future Data
          // We need to handle data loading and slicing differently for the Default Page compared to other pages
          // The entire dataset is saved, but it may need to be sliced or fetched from the server based on the date range
          // The slicing size varies for the first/default page and other pages thats why I used three seperate states

          if (
            maxPageReached.current[grouping] == 0 &&
            minPageReached.current[grouping] == 0
          ) {
            //Set Default Data
            const isAM = moment().format('A') === 'AM';

            if (props.dateRangeType == ShiftLoaderDateRangeType.CURRENT_DAY) {
              if (isAM)
                setHourlyDefaultGraphData(processedDataForGraph.slice(0, 12));
              else {
                setHourlyDefaultGraphData(processedDataForGraph.slice(12, 24));
                setDataForGraphs(
                  setHourlyPastGraphData,
                  setHourlyFutureGraphData,
                  processedDataForGraph.slice(0, 12),
                  'left',
                );
                setExtraHourlyPage('left');
              }
            } else if (
              props.dateRangeType == ShiftLoaderDateRangeType.PAST_DAY
            ) {
              setHourlyDefaultGraphData(processedDataForGraph.slice(0, 12));
              setExtraHourlyPage('right');
              setDataForGraphs(
                setHourlyPastGraphData,
                setHourlyFutureGraphData,
                processedDataForGraph.slice(12, 24),
                'right',
              );
            } else setHourlyDefaultGraphData(processedDataForGraph);
          } else
            setDataForGraphs(
              setHourlyPastGraphData,
              setHourlyFutureGraphData,
              processedDataForGraph,
              direction,
            );
          break;
        case ShiftLoadsGraphOption.DAYS:
          if (
            maxPageReached.current[grouping] == 0 &&
            minPageReached.current[grouping] == 0
          )
            setDailyDefaultGraphData(processedData);
          else
            setDataForGraphs(
              setDailyPastGraphData,
              setDailyFutureGraphData,
              processedData,
              direction,
            );
          break;
        case ShiftLoadsGraphOption.MONTHS:
          if (
            maxPageReached.current[grouping] == 0 &&
            minPageReached.current[grouping] == 0
          )
            setMonthlyDefaultGraphData(processedData);
          else
            setDataForGraphs(
              setMonthlyPastGraphData,
              setMonthlyFutureGraphData,
              processedData,
              direction,
            );
          break;
        default:
          break;
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSectionChange = (direction: string) => {
    const selectedOption = selectedTabValue || ShiftLoadsGraphOption.HOURS;

    if (direction === 'left') {
      setGraphPageNumber((prevPageNumber) => prevPageNumber + 1);

      if (graphPageNumber === maxPageReached.current[selectedOption]) {
        maxPageReached.current[selectedOption] = graphPageNumber + 1;

        if (
          selectedOption == ShiftLoadsGraphOption.HOURS &&
          props.dateRangeType == ShiftLoaderDateRangeType.CURRENT_DAY &&
          moment().format('A') !== 'AM' &&
          maxPageReached.current[selectedOption] == 1
        )
          return;

        getTimelineData(selectedTabValue, direction);
      }
    } else if (direction === 'right') {
      setGraphPageNumber((prevPageNumber) => prevPageNumber - 1);

      if (graphPageNumber === minPageReached.current[selectedOption]) {
        minPageReached.current[selectedOption] = graphPageNumber - 1;

        if (
          selectedOption == ShiftLoadsGraphOption.HOURS &&
          ((props.dateRangeType == ShiftLoaderDateRangeType.CURRENT_DAY &&
            moment().format('A') === 'AM') ||
            props.dateRangeType == ShiftLoaderDateRangeType.PAST_DAY) &&
          minPageReached.current[selectedOption] == -1
        )
          return;

        getTimelineData(selectedTabValue, direction);
      }
    }
  };

  useEffect(() => {
    if (!selectedTabValue)
      selectedTab.set(Query.GRAPH_TAB, ShiftLoadsGraphOption.HOURS);
    setSelectedTab(selectedTab);
  }, []);

  useEffect(() => {
    if (props.dateRangeType === ShiftLoaderDateRangeType.CURRENT_DAY) {
      setHourlyDefaultGraphData((previousData) =>
        previousData.map((dataPoint) => {
          if (
            moment(props.newLoad?.endTime).format('YYYY/MM/DD') ===
              dataPoint.date &&
            moment(props.newLoad?.endTime).format('hh:00 A') == dataPoint.time
          ) {
            return {
              ...dataPoint,
              baseLoads: Math.min(3, dataPoint.loads + 1),
              extra: Math.max(0, dataPoint.loads - 2),
              loads: dataPoint.loads + 1,
            };
          }
          return dataPoint;
        }),
      );
    }
  }, [props.newLoad]);

  useEffect(() => {
    let selectedOption = selectedTabValue
      ? selectedTabValue
      : ShiftLoadsGraphOption.HOURS;

    if (
      props.dateRangeType == ShiftLoaderDateRangeType.RANGE_48_PLUS &&
      selectedTabValue === ShiftLoadsGraphOption.HOURS
    ) {
      selectedTab.set(Query.GRAPH_TAB, ShiftLoadsGraphOption.DAYS);
      setSelectedTab(selectedTab);
      selectedOption = ShiftLoadsGraphOption.DAYS;
    }

    resetStates();
    minPageReached.current[selectedOption] = 0;
    maxPageReached.current[selectedOption] = 0;
    getTimelineData(selectedOption, 'left');
  }, [props.filterValues]);

  useEffect(() => {
    setGraphPageNumber(0);
    const selectedOption = selectedTabValue
      ? selectedTabValue
      : ShiftLoadsGraphOption.HOURS;

    if (
      maxPageReached.current[selectedOption] == undefined &&
      minPageReached.current[selectedOption] == undefined
    ) {
      minPageReached.current[selectedOption] = 0;
      maxPageReached.current[selectedOption] = 0;

      getTimelineData(selectedTabValue, 'left');
    }
  }, [selectedTab]);

  return (
    <div
      className="w-100 rounded-3 shadow-sm px-4 py-3 h-100"
      style={{ fontSize: '12px' }}
    >
      <div className="h-100 d-flex flex-column w-full overflow-scroll ">
        <section className="pb-1 d-flex justify-content-between align-items-center">
          <h6>
            {selectedTabValue
              ? ShiftLoadTimeFrameMapping[selectedTabValue]
              : ''}{' '}
            Loads
          </h6>
          <Tabs
            tabs={
              props.dateRangeType == ShiftLoaderDateRangeType.RANGE_48_PLUS
                ? tabs.slice(1)
                : tabs
            }
            selectedTabValue={selectedTabValue}
            loading={props.loading || isLoading}
          />
        </section>
        {selectedTabValue === ShiftLoadsGraphOption.DAYS ? (
          <>
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <Daily
                data={getGraphData(
                  graphPageNumber,
                  dailyPastGraphData,
                  dailyDefaultGraphData,
                  dailyFutureGraphData,
                  15,
                )}
                queryDate={props.date}
              >
                <NavigateArrow
                  status={{
                    left: true,
                    right: canMoveRight(
                      ShiftLoadsGraphOption.DAYS,
                      graphPageNumber,
                      props.dateRangeDetails,
                      props.dateRangeType,
                    ),
                  }}
                  onClick={handleSectionChange}
                />
              </Daily>
            )}
          </>
        ) : selectedTabValue === ShiftLoadsGraphOption.HOURS ? (
          <>
            {isLoading ? (
              <div className="flex-grow-1 w-100 d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            ) : props.dateRangeType <=
              ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE ? (
              <HourlyBar
                data={getGraphData(
                  graphPageNumber,
                  hourlyPastGraphData,
                  hourlyDefaultGraphData,
                  hourlyFutureGraphData,
                  12,
                )}
                isFirstDay={graphPageNumber == 0}
                filterValues={props.filterValues}
              >
                <NavigateArrow
                  status={{
                    left: graphPageNumber != 1 && moment().format('A') == 'PM',
                    right: graphPageNumber != 0,
                  }}
                  onClick={handleSectionChange}
                />
              </HourlyBar>
            ) : (
              <HourlyLine
                data={getGraphData(
                  graphPageNumber,
                  hourlyPastGraphData,
                  hourlyDefaultGraphData,
                  hourlyFutureGraphData,
                  12,
                )}
                filterValues={props.filterValues}
              >
                <NavigateArrow
                  status={{
                    left: true,
                    right: canMoveRight(
                      ShiftLoadsGraphOption.HOURS,
                      graphPageNumber,
                      props.dateRangeDetails,
                      props.dateRangeType,
                    ),
                  }}
                  onClick={handleSectionChange}
                />
              </HourlyLine>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <Monthly
                data={getGraphData(
                  graphPageNumber,
                  monthlyPastGraphData,
                  monthlyDefaultGraphData,
                  monthlyFutureGraphData,
                  12,
                )}
                queryDate={props.date}
              >
                <NavigateArrow
                  status={{
                    left: true,
                    right: canMoveRight(
                      ShiftLoadsGraphOption.MONTHS,
                      graphPageNumber,
                      props.dateRangeDetails,
                      props.dateRangeType,
                    ),
                  }}
                  onClick={handleSectionChange}
                />
              </Monthly>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShiftLoaderGraph;
