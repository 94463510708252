import { FC } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

type NavigateArrowProps = {
  onClick: (direction: string) => void;
  status: { left: boolean; right: boolean };
};
const NavigateArrow: FC<NavigateArrowProps> = (props) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <FaAngleLeft
        style={{
          cursor: props.status.left ? 'pointer' : 'unset',
          color: props.status.left ? 'black' : 'gray',
          position: 'relative',
          left: 45,
          bottom: 10,
        }}
        onClick={() => props.status.left && props.onClick('left')}
      />
      <FaAngleRight
        style={{
          color: props.status.right ? 'black' : 'gray',
          cursor: props.status.right ? 'pointer' : 'unset',
          position: 'relative',
          right: 30,
          bottom: 10,
        }}
        onClick={() => props.status.right && props.onClick('right')}
      />
    </div>
  );
};

export default NavigateArrow;
