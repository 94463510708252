import { FC } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { GraphData, ShiftLoadGraphProps } from '../../../../../utils/types';
import DailyCustomizedAxisTick from '../components/dailyCustomizedAxisTick';
import ToolTip from '../components/ToolTip';
import { isCurrentDate } from '../../../utils';
import { ShiftLoadsGraphOption } from '../../../../../utils/constants';

//@ts-ignore
const CustomizedLabel = (props) => {
  const { x, y, width } = props.viewBox;

  return (
    <text
      x={x + width}
      y={y + 3}
      dx={33}
      textAnchor="end"
      fill={'#666'}
      fontWeight={'bold'}
    >
      Time
    </text>
  );
};

//@ts-ignore
const CustomToolTip = (props) => {
  const { label, payload, active, queryDate } = props;

  if (payload?.length && active && label)
    return (
      <ToolTip
        date={label}
        metrics={[
          { label: 'Loads', value: payload[0]?.value },
          {
            label: 'H Average',
            value: (
              payload[0]?.value /
              (isCurrentDate(queryDate, ShiftLoadsGraphOption.DAYS, label)
                ? Math.max(new Date().getHours(), 1)
                : 24)
            ).toFixed(1),
          },
        ]}
      />
    );
  return <></>;
};

const Daily: FC<ShiftLoadGraphProps<GraphData>> = (props) => {
  return (
    <>
      <ResponsiveContainer height={250}>
        {!props.data ? (
          <p className="d-flex justify-content-center align-items-center">
            No Daily data found
          </p>
        ) : (
          <LineChart
            data={props.data}
            margin={{ top: 5, right: 50, left: 10, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              tickMargin={20}
              interval={'preserveStart'}
              stroke="#3E5D90"
              padding={{ left: 50, right: 50 }}
              dataKey="date"
              tickLine={false}
              label={<CustomizedLabel />}
              tick={
                <DailyCustomizedAxisTick queryDate={props.queryDate || ''} />
              }
            />
            <YAxis
              domain={[0, (dataMax: number) => Math.max(dataMax, 5)]}
              ticks={Array.from(
                {
                  length:
                    Math.ceil(
                      Math.max(
                        Math.max(...props.data.map((d) => d.loads)) / 10,
                        5,
                      ),
                    ) + 1,
                },
                (_, i) => i * 10,
              )}
              dataKey="loads"
              padding={{ top: 15 }}
              label={{
                value: 'Loads',
                fontWeight: 'bold',
                angle: -90,
                position: 'insideLeft',
              }}
              tickMargin={10}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip content={<CustomToolTip queryDate={props.queryDate} />} />
            <Line
              dataKey="loads"
              stroke="#2626DB"
              activeDot={{ r: 0 }}
              dot={false}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
      {props.children}
    </>
  );
};

export default Daily;
