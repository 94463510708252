import moment from 'moment';
import { GraphData, HourlyGraphData } from '../../../../utils/types';
import {
  ShiftLoaderDateRangeType,
  ShiftLoadsGraphOption,
} from '../../../../utils/constants';
import { DateRangeDetails } from '../../types';
import { Dispatch, SetStateAction } from 'react';

export const setDataForGraphs = (
  pastSetDataFunction: Dispatch<SetStateAction<any>>,
  futureSetDataFunction: Dispatch<SetStateAction<any>>,
  newData: GraphData[],
  direction: string,
) => {
  if (direction === 'right') {
    futureSetDataFunction((prev: any) => [...prev, ...newData]);
  } else {
    pastSetDataFunction((prev: any) => [...newData, ...prev]);
  }
};

export const processHourlyDataForGraph = (
  fetchedGraphData: any,
): HourlyGraphData[] => {
  return fetchedGraphData.map((graphData: GraphData) => {
    const dateObj = moment(graphData.date, 'DD/MM/YYYY hh');
    const dateStr = dateObj.format('YYYY/MM/DD');
    const timeStr = dateObj.format('hh:mm A');

    const baseLoads = Math.min(3, graphData.loads);
    const extra = Math.max(0, graphData.loads - 3);

    return {
      date: dateStr,
      time: timeStr,
      baseLoads: baseLoads,
      extra: extra,
      loads: graphData.loads,
      timestamp: graphData.date,
    };
  });
};

export const canMoveRight = (
  tabValue: string,
  graphPageNumber: number,
  dateRangeDetails: DateRangeDetails,
  dateRangeType: ShiftLoaderDateRangeType,
) => {
  if (graphPageNumber > 0) return true;

  if (tabValue == ShiftLoadsGraphOption.DAYS) {
    if (dateRangeType <= ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE)
      return false;
    else if (dateRangeType <= ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE)
      return (
        moment().diff(
          moment
            .unix(dateRangeDetails.startDayUnix)
            .add(-graphPageNumber * 15 + 7, 'days'),
          'days',
        ) > 0
      );
    else
      return (
        moment().diff(
          moment
            .unix(dateRangeDetails.endDayUnix)
            .add(-graphPageNumber * 15, 'days'),
          'days',
        ) > 0
      );
  } else if (tabValue == ShiftLoadsGraphOption.MONTHS) {
    if (dateRangeType <= ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE)
      return false;
    else if (dateRangeType <= ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE)
      return (
        moment().diff(
          moment
            .unix(dateRangeDetails.startMonthUnix)
            .add(-graphPageNumber * 12 + 12, 'month'),
          'month',
        ) > 0
      );
    else
      return (
        moment().diff(
          moment
            .unix(dateRangeDetails.endMonthUnix)
            .add(-graphPageNumber * 12, 'month'),
          'month',
        ) > 0
      );
  } else if (tabValue == ShiftLoadsGraphOption.HOURS) {
    return (
      moment().diff(
        moment
          .unix(dateRangeDetails.startTimeUnix)
          .add(
            -(graphPageNumber + 1) * 12 +
              Math.ceil(
                (dateRangeDetails.endTimeUnix -
                  dateRangeDetails.startTimeUnix) /
                  3600,
              ),
            'hour',
          ),
        'hour',
      ) > 0
    );
  }
  return true;
};

export const getGraphData = (
  pageNumber: number,
  pastData: GraphData[],
  defaultData: GraphData[],
  futureData: GraphData[],
  itemsPerPage: number,
): any[] => {
  if (pageNumber > 0) {
    const startIndex = -itemsPerPage * pageNumber;
    const endIndex =
      pageNumber !== 1 ? -itemsPerPage * (pageNumber - 1) : undefined;
    return pastData.slice(startIndex, endIndex);
  } else if (pageNumber < 0) {
    const startIndex = -1 * itemsPerPage * (pageNumber + 1);
    const endIndex = -1 * pageNumber * itemsPerPage;

    return futureData.slice(startIndex, endIndex);
  } else {
    return defaultData;
  }
};
