import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import BasicButton from '../../dustControl/components/BasicButton';
import CalendarIcon from '../../dustControl/assets/icons/calendar.png';
import { formatDateToDDMMYYYY } from '../../dustControl/utils/helper';
import moment from 'moment';
import { duration } from 'moment/moment';

export const DateRangeSelector = ({
  dateRange,
  setDateRange,
  labelText = 'Added on',
  hideClearButton = false,
  onClickApply = () => {},
  leftSafe = false,
  onClear = () => {},
  onCancel = () => {},
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const clickListener = (e) => {
      setTimeout(() => {
        // timeout needed so button press still works
        const classNameContainsRdp = (
          e.target.nearestViewportElement?.className?.baseVal ??
          e.target.className?.baseVal ??
          e.target.className
        ).includes('rdp');
        if (
          e.target.id !== `keepDropdownOpenCalendar` &&
          !classNameContainsRdp &&
          e.target.id !== `apply-date-range-btn`
        ) {
          setDropdownVisible(false);
          onCancel();
        } else if (
          e.target.id !== `keepDropdownOpenCalendar` &&
          !classNameContainsRdp &&
          e.target.id === `apply-date-range-btn`
        ) {
          setTimeout(() => setDropdownVisible(false), 200);
        }
      }, 100);
    };
    if (isDropdownVisible)
      document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [isDropdownVisible]);

  let numberOfDays = 0;
  if (dateRange?.to) {
    numberOfDays = duration(moment(dateRange.to).diff(dateRange.from)).as(
      'days',
    );
    numberOfDays = Math.round(numberOfDays + 1); // duration is not inclusive of start date
  } else if (dateRange?.from) numberOfDays = 1;

  return (
    <div className="d-flex flex-row justify-content-between">
      {labelText ? <label className="flex-one">{labelText}</label> : null}
      <div
        className="d-flex flex-column add-user-input align-items-end position-relative"
        id="keepDropdownOpenCalendar"
      >
        <button
          className="rounded-borders-and-padding d-flex justify-content-between"
          onClick={() => setDropdownVisible(!isDropdownVisible)}
          id="keepDropdownOpenCalendar"
        >
          <div id="keepDropdownOpenCalendar">
            {dateRange?.from ? `${formatDateToDDMMYYYY(dateRange.from)}` : ''}
            {dateRange?.to ? ` - ${formatDateToDDMMYYYY(dateRange?.to)}` : ''}
          </div>
          <Image
            className="ms-2"
            src={CalendarIcon}
            alt="down"
            id="keepDropdownOpenCalendar"
            height={15}
          />
        </button>

        {isDropdownVisible && (
          <div
            style={leftSafe ? { left: 0, minWidth: 315 } : {}}
            className=" hover-card calendar-position width-310px"
            id="keepDropdownOpenCalendar"
          >
            <div className="p-3 pb-0">
              <b>Select a date</b>
            </div>
            <DayPicker
              mode="range"
              onSelect={setDateRange}
              selected={dateRange}
              id="keepDropdownOpenCalendar"
            />
            <div className="border-top m-3 pt-3 d-flex gap-2">
              <div className="flex-fill">
                {numberOfDays
                  ? `${numberOfDays} day${numberOfDays === 1 ? '' : 's'}`
                  : ''}{' '}
              </div>
              {hideClearButton ? null : (
                <BasicButton
                  outlined
                  onClick={() => {
                    setDateRange();
                    onClear();
                  }}
                  id="keepDropdownOpenCalendar"
                >
                  Clear
                </BasicButton>
              )}

              <BasicButton id="apply-date-range-btn" onClick={onClickApply}>
                Apply
              </BasicButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
