import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import * as toast from '../../../dustControl/components/toast';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Settings from '../SettingPopup/SettingPopup';
import dumpSvg from './../../assets/images/point.svg';
import sourceSvg from './../../assets/images/source-tooltip-icon.svg';
import './index.css';
import { formatDateDDMMYYYY } from '../../utils/helper';

const ActiveTable = ({
  getMaterialColor,
  reloadForcefully,
  onEditPolygon,
  module,
  data,
  onSelectRow,
  selectedRow,
  onDemandDetailPopup,
  setPopupConfirmation,
}) => {
  const [openedSettingIndex, setOpenedSettingIndex] = useState(-1);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const myDiv = document.getElementById('settings');
      if (
        !myDiv?.contains(event.target) &&
        event.target.nodeName !== 'svg' &&
        event.target.nodeName !== 'path'
      ) {
        setOpenedSettingIndex(-1);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const getAssetCount = (sourceDump) => {
    const uniqueAssets = [];
    if (sourceDump?.loads?.length) {
      for (let i = 0; i < sourceDump?.loads.length; i++) {
        const load = sourceDump?.loads[i];
        if (module === 'source') {
          if (load && load.contactTitle) {
            if (!uniqueAssets.includes(load.contactTitle)) {
              uniqueAssets.push(load.contactTitle);
            }
          }
        } else {
          if (load && load.objectTitle) {
            if (!uniqueAssets.includes(load.objectTitle)) {
              uniqueAssets.push(load.objectTitle);
            }
          }
        }
      }
    }
    if (uniqueAssets.length) {
      return uniqueAssets.length;
    } else {
      return '-';
    }
  };

  return (
    <>
      <table
        style={{
          borderBottom: '0.1rem solid #707070',
          borderTop: '0.1rem solid #707070',
        }}
        className="custom-table-active"
      >
        <thead>
          <th></th>
          <th>{_.capitalize(module)} Point</th>
          <th>{module === 'source' ? 'Excavators' : 'Trucks'}</th>
          <th>Materials</th>
          <th>Total Loads</th>
          <th></th>
        </thead>
      </table>

      <div
        className="scroll-div"
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: 'calc(100vh - 26.5rem)',
          minHeight: 'calc(100vh - 26.5rem)',
          overflowY: 'scroll',
        }}
      >
        {data?.length == 0 && (
          <div className="d-flex text-muted mt-2 justify-content-center align-items-center">
            No Record Found
          </div>
        )}
        <table style={{ width: '100%' }} className="custom-table-active">
          <tbody className="">
            {data?.map((sourceDump, index) => (
              <tr
                style={{
                  font:
                    sourceDump?.name === 'Unknown'
                      ? 'italic normal normal 0.8rem Open Sans'
                      : '',
                  backgroundColor:
                    JSON.stringify(selectedRow) == JSON.stringify(sourceDump)
                      ? '#05795F1A'
                      : '#FFF',
                  borderBottom:
                    JSON.stringify(selectedRow) == JSON.stringify(sourceDump)
                      ? '0.1rem solid #05795F'
                      : ' 0px solid #EBEBEB ',
                  borderTop:
                    JSON.stringify(selectedRow) == JSON.stringify(sourceDump)
                      ? '0.1rem solid #05795F'
                      : ' 1px solid #EBEBEB ',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (sourceDump?.name === 'Unknown') {
                    onSelectRow({ ...sourceDump });
                  } else {
                    onSelectRow(sourceDump);
                  }
                }}
                key={index}
              >
                <td></td>
                <td>{sourceDump?.name ?? '-'}</td>
                <td>{getAssetCount(sourceDump)}</td>
                {/* When materials length is 1 then single material name is shown, when it’s length is greater than 1 then count is shown on which hovering shows all materials. */}
                <td>
                  {sourceDump?.materials?.length > 1 ? (
                    <a alt="" href="#" className={`my-anchor-element-${index}`}>
                      {sourceDump?.materials?.length}
                    </a>
                  ) : sourceDump?.materials?.length === 1 ? (
                    <div> {sourceDump?.materials[0]}</div>
                  ) : (
                    <div>-</div>
                  )}
                  <Tooltip
                    style={{
                      backgroundColor: '#fff',
                      color: 'black',
                      boxShadow: '1px 6px 10px #00000029',
                    }}
                    anchorSelect={`.my-anchor-element-${index}`}
                    place="top"
                  >
                    <ul className="px-2 py-0 m-0">
                      {sourceDump?.materials?.map((e, i) => (
                        <li
                          className="d-flex justify-content-start align-items-center"
                          style={{ fontSize: '0.8rem' }}
                          key={i}
                        >
                          <span
                            className="mm-dot-1"
                            style={{
                              backgroundColor: getMaterialColor(e.material),
                            }}
                          ></span>{' '}
                          {e}
                        </li>
                      ))}
                    </ul>
                  </Tooltip>
                </td>
                <td>{sourceDump?.loads?.length ?? '-'}</td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenedSettingIndex(index);
                  }}
                >
                  <div id="settings">
                    <div className="mm-ellipse_dropdown ">
                      {_.lowerCase(sourceDump?.name) != 'unknown' && (
                        <span className="mm-ellipse_dropdown_button ">
                          <BsThreeDotsVertical onClick={() => {}} />
                        </span>
                      )}
                      {openedSettingIndex == index && (
                        <Settings
                          onDemandDetailPopup={() => {
                            onDemandDetailPopup(sourceDump);
                          }}
                          data={sourceDump}
                          setPopupConfirmation={setPopupConfirmation}
                          module={module}
                          status={'Active'}
                          onEditPolygon={onEditPolygon}
                          reloadForcefully={reloadForcefully}
                          onSelectRow={onSelectRow}
                        />
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const InActiveTable = ({
  getMaterialColor,
  reloadForcefully,
  onEditPolygon,
  module,
  data,
  onSelectRow,
  selectedRow,
  setPopupConfirmation,
  onDemandDetailPopup,
}) => {
  const [openedSettingIndex, setOpenedSettingIndex] = useState(-1);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const myDiv = document.getElementById('settings');
      if (
        !myDiv?.contains(event.target) &&
        event.target.nodeName !== 'svg' &&
        event.target.nodeName !== 'path'
      ) {
        // Perform your desired functionality here
        setOpenedSettingIndex(-1);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <>
      <table
        style={{
          borderBottom: '0.1rem solid #707070',
          borderTop: '0.1rem solid #707070',
        }}
        className="custom-table-inactive"
      >
        <thead>
          <th></th>
          <th>{_.capitalize(module)} Point</th>
          <th>Last Activity</th>
          <th>Disabled On</th>
          <th></th>
        </thead>
      </table>

      <div
        className="scroll-div"
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: 'calc(100vh - 26.5rem)',
          minHeight: 'calc(100vh - 26.5rem)',
          overflowY: 'scroll',
        }}
      >
        {data?.length == 0 && (
          <div className="d-flex text-muted mt-2 justify-content-center align-items-center">
            No Record Found
          </div>
        )}
        <table style={{ width: '100%' }} className="custom-table-inactive">
          <tbody className="">
            {data?.map((sourceDump, index) => (
              <tr
                style={{
                  backgroundColor:
                    JSON.stringify(selectedRow) == JSON.stringify(sourceDump)
                      ? '#05795F1A'
                      : '#FFF',
                  borderBottom:
                    JSON.stringify(selectedRow) == JSON.stringify(sourceDump)
                      ? '0.1rem solid #05795F'
                      : ' 0px solid #EBEBEB ',
                  borderTop:
                    JSON.stringify(selectedRow) == JSON.stringify(sourceDump)
                      ? '0.1rem solid #05795F'
                      : ' 1px solid #EBEBEB ',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onSelectRow(sourceDump);
                }}
                key={index}
              >
                <td></td>
                <td>{sourceDump?.name ?? '-'}</td>
                <td>
                  {sourceDump?.lastActivity
                    ? formatDateDDMMYYYY(sourceDump?.lastActivity * 1000)
                    : '-'}
                </td>
                <td>
                  {sourceDump?.disabled_on
                    ? formatDateDDMMYYYY(sourceDump?.disabled_on)
                    : '-'}
                </td>
                <td>
                  <div id="settings" onClick={(e) => e.stopPropagation()}>
                    <div className="mm-ellipse_dropdown ">
                      <span
                        onClick={() => {
                          setOpenedSettingIndex(index);
                        }}
                        className="mm-ellipse_dropdown_button "
                      >
                        <BsThreeDotsVertical onClick={() => {}} />
                      </span>
                      {openedSettingIndex == index && (
                        <Settings
                          onEditPolygon={onEditPolygon}
                          data={sourceDump}
                          onDemandDetailPopup={() => {
                            onDemandDetailPopup(sourceDump);
                          }}
                          module={module}
                          status={'Inactive'}
                          setPopupConfirmation={setPopupConfirmation}
                          reloadForcefully={reloadForcefully}
                          onSelectRow={onSelectRow}
                        />
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const AllPolygons = ({
  module,
  selectedRow,
  onClose,
  onEditPolygon = () => {},
  data = [],
  onSelectRow,
  onDemandDetailPopup = () => {},
  setPopupConfirmation,
  reloadForcefully = () => {},
  getMaterialColor = () => {},
  setPolygonType = () => {},
}) => {
  const [filteredData, setFilteredData] = useState([...data]);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeStatus, setActiveStatus] = useState('Active');

  useEffect(() => {
    setPolygonType(activeStatus);
  }, [activeStatus]);

  const fetchAllEquipments = async () => {
    try {
    } catch (error) {
      console.log('error', error);
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchAllEquipments();
  }, []);

  const filterBySearch = (unFilteredData) => {
    if (searchQuery === '') {
      return unFilteredData;
    }
    return unFilteredData.filter((item) => {
      return item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
  };

  const filterByStatus = (unFilteredData) => {
    if (activeStatus === '') {
      return unFilteredData;
    }
    return unFilteredData.filter((item) => {
      return item?.status == activeStatus;
    });
  };

  useEffect(() => {
    let filteredData = data;
    filteredData = filterBySearch(filteredData);
    filteredData = filterByStatus(filteredData);
    setFilteredData(filteredData);
  }, [searchQuery, activeStatus, data]);

  return (
    <div style={{ fontSize: '0.8rem' }} className="modal-container p-0">
      <div style={{ borderRadius: '0.8rem 0.8rem 0 0', background: '#FAFAFA' }}>
        <div style={{ position: 'absolute', right: 0 }}>
          <CgClose
            onClick={() => onClose()}
            style={{
              cursor: 'pointer',
              borderRight: '0px',
            }}
            size={'2.3rem'}
            className="px-2"
          />
        </div>
        <div
          className="d-flex pt-4 pb-2"
          style={{
            font: 'normal normal 600 1rem/1.375rem Open Sans',
            marginLeft: '1.5rem',
          }}
        >
          <Image
            src={module == 'source' ? sourceSvg : dumpSvg}
            style={{
              marginBottom: '0.625rem',
              height: '1.5rem',
              width: '1.8rem',
            }}
          />
          <div style={{ marginLeft: '0.879rem', marginBottom: '0.625rem' }}>
            {_.upperCase(module)} POINTS
          </div>
        </div>
      </div>

      <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
        <div
          style={{
            width: '100%',
            marginTop: '1.25rem',
            marginBottom: '0.3rem',
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #dde5f3',
                padding: '0.25rem 0.8rem',
                borderRadius: '15px',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <i
                style={{ fontSize: '0.8rem', color: '#707070' }}
                className="mr-2 fa-regular fa-magnifying-glass"
              ></i>
              <div style={{ width: '0.5rem' }}></div>
              <input
                type="search"
                className="shadow-none w-100"
                value={searchQuery}
                placeholder={`Search for a ${module} point`}
                style={{
                  border: 'none',
                  outline: '0px',
                  font: 'normal normal normal 0.8rem Open Sans',
                }}
                onChange={(e) => setSearchQuery(e?.target?.value)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div
            onClick={() => {
              setActiveStatus('Active');
            }}
            className="pb-1 mt-3 me-3"
            style={
              activeStatus == 'Active'
                ? {
                    fontWeight: 600,
                    borderBottom: '1.4px solid #05795F',
                    lineHeight: 1,
                  }
                : { color: '#707070', fontWeight: 'normal', lineHeight: 1 }
            }
          >
            Active
          </div>
          <div
            onClick={() => {
              setActiveStatus('Inactive');
            }}
            className="pb-1 mt-3"
            style={
              activeStatus == 'Inactive'
                ? {
                    fontWeight: 600,
                    borderBottom: '1.4px solid #05795F',
                    lineHeight: 1,
                  }
                : { color: '#707070', fontWeight: 'normal', lineHeight: 1 }
            }
          >
            Inactive
          </div>
        </div>

        {activeStatus === 'Active' && (
          <ActiveTable
            module={module}
            data={filteredData}
            onSelectRow={onSelectRow}
            onDemandDetailPopup={onDemandDetailPopup}
            selectedRow={selectedRow}
            getMaterialColor={getMaterialColor}
            setPopupConfirmation={setPopupConfirmation}
            onEditPolygon={(v) => {
              onEditPolygon(v);
            }}
            reloadForcefully={reloadForcefully}
          />
        )}

        {activeStatus === 'Inactive' && (
          <InActiveTable
            module={module}
            data={filteredData}
            onSelectRow={onSelectRow}
            selectedRow={selectedRow}
            getMaterialColor={getMaterialColor}
            onDemandDetailPopup={onDemandDetailPopup}
            setPopupConfirmation={setPopupConfirmation}
            onEditPolygon={(v) => {
              onEditPolygon(v);
            }}
            reloadForcefully={reloadForcefully}
          />
        )}
      </div>
      <div
        style={{
          borderRadius: '0 0 0.6rem 0.6rem ',
          backgroundColor: '#F9FAFB',
        }}
      >
        <div
          className="px-4 d-flex w-100 justify-content-end"
          style={{ fontSize: '0.9rem', color: '#387FF3' }}
        >
          {selectedRow?.name && (
            <div
              onClick={() => {
                onSelectRow({});
              }}
            >
              Deselect
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllPolygons;
