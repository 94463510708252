import { useContext, useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import FloatingInput from '../../../dustControl/components/FloatingInput';
import * as toast from '../../../dustControl/components/toast';
import { API_METHODS, ENDPOINTS } from '../../../dustControl/utils/constants';
import BasicButton from '../../../dustControl/components/BasicButton';
import { FormModal } from '../../../dustControl/components/FormModal';
import {
  newPasswordValidation,
  passwordValidation,
} from '../../../dustControl/utils/helper';
import { UserContext } from '../../service/userContext';

const SuccessMessage = ({ hide }) => {
  return (
    <div className="position-fixed P-Update-Success-3 d-flex justify-content-center align-items-center">
      <div className=" P-Update-Success-4 rounded-2 pt-2 pb-4">
        <CloseButton onClick={hide} className="close-button" />

        <div className="mb-3 rounded-top">
          <h4 style={{ fontSize: '16px' }} className="mt-3 mb-3">
            Your Password was changed successfully.
          </h4>
        </div>
      </div>
    </div>
  );
};

const ChangePasswordModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [currentpass, setcurrentpass] = useState('');
  const [newpass, setnewpass] = useState('');
  const [confirmpass, setconfirmpass] = useState('');
  const { ApiHandler } = useContext(UserContext);
  const [errorMsg, setErrorMsg] = useState({
    currentPassErrorMsg: '',
    newpasswordErrorMsg: '',
    confirmpassErrorMsg: '',
  });

  const handleSubmitClick = async (e) => {
    e.preventDefault();

    let currentPassErrorMsg = passwordValidation(currentpass);
    const errors = newPasswordValidation(newpass, confirmpass);
    const newpasswordErrorMsg = errors.password;
    const confirmpassErrorMsg = errors.confirmPassword;
    setErrorMsg({
      currentPassErrorMsg,
      newpasswordErrorMsg,
      confirmpassErrorMsg,
    });

    if (currentPassErrorMsg || newpasswordErrorMsg || confirmpassErrorMsg) {
      return;
    }

    try {
      const data = {
        currentPassword: currentpass,
        newPassword: newpass.trim(),
        confirmPassword: confirmpass.trim(),
      };
      const response = await ApiHandler({
        reqParam: data,
        method: API_METHODS.POST,
        endPoint: ENDPOINTS.changePassword,
      });

      if (response.status === 200) {
        setVisible(!visible);
      }
    } catch (error) {
      if (error?.status === 401) {
        currentPassErrorMsg =
          'The current password you have provided is incorrect';
        setErrorMsg({
          currentPassErrorMsg,
          newpasswordErrorMsg,
          confirmpassErrorMsg,
        });
      } else {
        toast.error(error?.message);
      }
    }
  };
  return visible ? (
    <SuccessMessage hide={props.onHide} />
  ) : (
    <FormModal header="Change Password">
      <FloatingInput
        style={{ width: '100%' }}
        value={currentpass}
        setValue={setcurrentpass}
        label="Current Password"
        type="password"
        isError={errorMsg.currentPassErrorMsg ? true : false}
      />

      <p className="error-msg-2 text-start">{errorMsg.currentPassErrorMsg}</p>

      <FloatingInput
        style={{ width: '100%' }}
        value={newpass}
        setValue={setnewpass}
        label="New Password"
        type="password"
        isError={errorMsg.newpasswordErrorMsg ? true : false}
      />
      <p className="error-msg-2 text-start">{errorMsg.newpasswordErrorMsg}</p>

      <FloatingInput
        style={{ width: '100%' }}
        value={confirmpass}
        setValue={setconfirmpass}
        label="Confirm New Password"
        s
        type="password"
        isError={errorMsg.confirmpassErrorMsg ? true : false}
      />

      <p className="error-msg-2 text-start">{errorMsg.confirmpassErrorMsg}</p>

      <div className="d-flex gap-2 w-50 align-self-end justify-content-end mt-2">
        <BasicButton onClick={props.onHide} outlined variant="outline-dark">
          Cancel
        </BasicButton>
        <BasicButton onClick={(e) => handleSubmitClick(e)} variant="dark">
          Submit
        </BasicButton>
      </div>
    </FormModal>
  );
};

export default ChangePasswordModal;
