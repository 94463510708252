//@ts-nocheck
import {
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { withAccessControl } from '../pages/service/with-access-control';
import { Map } from './Map/map';
import TopButton from './components/Buttons/TopButton';
import { History } from './components/History';

import './index.css';
import {
  MODULES,
  BinWithPickup,
  GetAllTrucksData,
  TruckData,
  SortStatus,
  SocketResponse,
  SocketEvent,
  TruckStatus,
} from './utils/types';
import Filter from './components/filter';
import ListForMap from './components/List/ListForMap';
import { useSearchParams } from 'react-router-dom';
import { includes, isEmpty, values } from 'lodash';
import {
  applyTruckFilters,
  findTruckDataByName,
  getFilterData,
  getGeneralBinStatus,
} from './utils/helpers';
import { useScrapBins } from './context/scranBinsContext';
import { API_METHODS, BIN_FILTERS, SOCKETS } from './utils/constants';
import { Details } from './components/Details';
import { UserContext } from '../pages/service/userContext';
import { Routes } from './utils/routes';
import * as toast from '../dustControl/components/toast';
import { SocketContext } from '../dustControl/utils/socket';
import {
  SocketContextType,
  Truck,
  UpdateOperatorNameByTruckName,
} from '../dustControl/utils/types';
import { useSocket } from '../hooks/useSocket';
import {
  EngineEvent,
  EngineHoursSocketData,
  SocketClient,
  SocketRoom,
} from '../utils/constants';
import { TruckSocketData } from '../dustControl/pages/Dashboard/types';
import { PickupChangeData } from './components/TrackBin/types';

const ScrapBins = () => {
  const { ApiHandler } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { socket } = useContext<SocketContextType>(SocketContext);
  const [module, setModule] = useState<MODULES>(MODULES.BINS); // source / dump / history
  const [trucks, setTrucks] = useState<GetAllTrucksData>({});
  const mapRef = useRef(null);
  const { bins, setBins } = useScrapBins();
  const [binDetailName, setBinDetailName] = useState<string>(); //only store reference to object so that updates to bins list rerender components
  const [showDetailsSection, setShowDetailsSection] = useState<boolean>(false);
  const [showSection, setShowSection] = useState<boolean>(false);
  const [truckDetailName, setTruckDetailName] = useState<string>(); //only store reference to object so that updates to trucks rerenders components
  const [filter, setFilter] = useSearchParams();
  const [isMapFlyoutVisible, setIsMapFlyoutVisible] = useState<boolean>(false);

  const binDetails: BinWithPickup | undefined = bins.find(
    (bin) => bin.name === binDetailName,
  );

  const truckDetails: TruckData | undefined = findTruckDataByName(
    truckDetailName,
    trucks,
  );

  const fetchBinsData = async () => {
    setLoading(true);
    try {
      const response = await ApiHandler({
        endPoint: Routes.GET_BINS,
        method: API_METHODS.GET,
        reqParam: {},
      });

      const bins = response.data?.bins;
      if (bins) setBins(bins);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  // Scrap bin sockets
  const socketHandler = useCallback(
    (socketData: SocketResponse) => {
      if (socketData.socketEvent === SocketEvent.UPDATED_BIN_LIST) {
        setBins(socketData.bins);
      } else if (socketData.socketEvent === SocketEvent.PICKUP_CHANGE) {
        setBins((previousBinsState) => {
          return previousBinsState.map((bin) => {
            if (bin.name === socketData.pickupDocument?.bin.name) {
              return { ...bin, lastPickup: socketData.pickupDocument };
            } else return bin;
          });
        });
      } else if (socketData.socketEvent === SocketEvent.UPDATE_TRUCK_LIST) {
        setTrucks(socketData.trucks);
      } else if (socketData.socketEvent === SocketEvent.TRUCK_LOCATION) {
        setTrucks((previous) => {
          const updatedAllTrucks: GetAllTrucksData = { ...previous };
          for (const category in updatedAllTrucks) {
            const truckList =
              updatedAllTrucks[category as keyof GetAllTrucksData];
            if (truckList) {
              updatedAllTrucks[category as keyof GetAllTrucksData] =
                truckList.map((truck): TruckData => {
                  if (truck.name === socketData.truckName) {
                    return {
                      ...truck,
                      lastLocation: socketData.mostRecentLocation,
                    };
                  }
                  return truck;
                });
            }
          }

          return updatedAllTrucks;
        });
      }
    },
    [setBins],
  );
  useSocket<SocketResponse>(
    SocketRoom.SCRAP_BINS,
    socketHandler,
    SocketClient.ADMIN,
  );

  // Engine hour socket
  const updateEngineHours = useCallback((socketData: EngineHoursSocketData) => {
    setTrucks((prevTrucks: GetAllTrucksData) => {
      const updatedTrucks: GetAllTrucksData = { ...prevTrucks };

      // Iterate over all key-value pairs in trucks
      for (const category in prevTrucks) {
        // Map through nested array of trucks
        const truckList: TruckData[] | undefined =
          prevTrucks[category as keyof GetAllTrucksData];
        const trucksInCategory: TruckData[] | undefined = truckList?.map(
          (truck) => {
            if (truck.name !== socketData.truckName) return truck;
            const matchingTruck = { ...truck };
            matchingTruck.status =
              socketData.engineStatus === EngineEvent.ON
                ? TruckStatus.Active
                : TruckStatus.Parked;
            if (socketData.engineHours) {
              matchingTruck.engineHourSummary = {
                dailyEngineHour: socketData.engineHours.calculatedEngineMinutes,
                lifetimeEngineHour:
                  socketData.engineHours.totalEngineHoursInMinutes,
              };
              matchingTruck.shiftSummary.active =
                socketData.engineHours.calculatedEngineMinutes;
            }
            return matchingTruck;
          },
        );

        updatedTrucks[category as keyof GetAllTrucksData] = trucksInCategory;
      }

      return updatedTrucks;
    });
  }, []);
  useSocket<EngineHoursSocketData>(SocketRoom.ENGINE_HOURS, updateEngineHours);

  useEffect(() => {
    /* 
    These listeners are duplicated by the new useSocket 
    but the below sockets (emitted by the EC2 rather than the lambda)
    are required because in some cases the EC2 will still emit sockets
    */
    socket.on(SOCKETS.bins, (socketData: BinWithPickup[]) => {
      setBins(socketData);
    });
    socket.on(SOCKETS.trucks, (socketData: TruckSocketData) => {
      setTrucks(socketData);
    });
    socket?.on(SOCKETS.pickupUpdate, (data: PickupChangeData) => {
      setBins((previousBinsState) => {
        return previousBinsState.map((bin) => {
          if (bin.name === data.pickupDocument?.bin.name) {
            return { ...bin, lastPickup: data.pickupDocument };
          } else return bin;
        });
      });
    });

    return () => {
      socket.off(SOCKETS.bins);
      socket.off(SOCKETS.trucks);
      socket.off(SOCKETS.pickupUpdate);
    };
  }, []);

  const fetchTruckData = async () => {
    setLoading(true);

    try {
      const response = await ApiHandler({
        endPoint: Routes.GET_TRUCKS,
        method: API_METHODS.GET,
        reqParam: {},
      });
      const truckData = response.data;
      if (truckData) setTrucks(truckData);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateOperatorNameByTruckName = (
      socketData: UpdateOperatorNameByTruckName,
    ) => {
      const { truckName, operator } = socketData;

      setTrucks((prevTrucks: Record<string, TruckData[]>) => {
        const updatedTrucks: Record<string, TruckData[]> = {};

        for (const category in prevTrucks) {
          const updatedCategoryTrucks = prevTrucks[category]?.map(
            (truck: Truck) => {
              if (truck.name === truckName) {
                return {
                  ...truck,
                  operator,
                };
              }
              return truck;
            },
          );

          updatedTrucks[category] = updatedCategoryTrucks;
        }

        return { ...prevTrucks, ...updatedTrucks };
      });
    };
    socket.on(SOCKETS.updateOperator, (socketData: TruckSocketData) => {
      updateOperatorNameByTruckName(socketData);
    });
    return () => {
      socket.off(SOCKETS.updateOperator);
    };
  }, [trucks]);

  const handleShowDetailsSection = (
    item: BinWithPickup | TruckData | string,
  ) => {
    setShowDetailsSection(true);
    if (module === MODULES.BINS) {
      setBinDetailName(item.name);
    } else if (module === MODULES.TRUCKS) {
      setTruckDetailName(item.name);
    }
  };

  const handleShowMapFlyout = (item?: BinWithPickup | TruckData) => {
    if (!item) {
      setBinDetailName(null);
      setTruckDetailName(null);
      setIsMapFlyoutVisible(false);
      setShowDetailsSection(false);
    } else {
      setShowDetailsSection(false);
      setIsMapFlyoutVisible(true);
      if (module === MODULES.BINS) {
        setBinDetailName(item.name);
      } else if (module === MODULES.TRUCKS) {
        setTruckDetailName(item.name);
      }
    }
  };

  const handleShowDetailsToggle = (showDetailsVisibility: boolean) => {
    setShowDetailsSection(showDetailsVisibility);
    if (!showDetailsVisibility) {
      // unselect item from row
      setBinDetailName(undefined);
      setTruckDetailName(undefined);
    }
  };

  const handleShowSectionToggle = (title: string) => {
    if (title.toUpperCase() === module.toUpperCase()) {
      setShowSection((prev) => !prev);
    } else {
      // If a new title is clicked, set the state to true
      setShowSection(true);
    }
  };

  const dataBasedOnModule = useMemo(() => {
    const { binFilterQueries, truckFilterQueries } = getFilterData(filter);
    if (module === MODULES.BINS) {
      if (isEmpty(binFilterQueries)) return bins;
      if (binFilterQueries.length === BIN_FILTERS.length) return bins;
      /* 
         Check if the query is one of the sorting options. If yes, binFilterQueries would 
         look like ['lastemptied']. Would only have one element. These elements are one of the SortStatus
         enum values. If this element is one of the enum values, that means this is a sort request
      */
      if (
        binFilterQueries.length === 1 &&
        includes(values(SortStatus), binFilterQueries[0])
      )
        return bins;
      return bins.filter((bin) =>
        includes(binFilterQueries, getGeneralBinStatus(bin)),
      );
    }
    if (module === MODULES.TRUCKS) {
      return applyTruckFilters(trucks, truckFilterQueries);
    } else return [];
  }, [module, bins, trucks, filter]);

  useEffect(() => {
    handleShowDetailsToggle(false);
    if (module === MODULES.BINS) {
      fetchBinsData();
    } else {
      // required for history module as well as trucks module
      fetchTruckData();
    }
  }, [module]);

  return (
    <div className="bin-container">
      <div className="d-flex gap-2 position-relative px-2 ">
        <div className="d-flex gap-2">
          {[
            {
              title: 'Bins',
              img: 'scrap-bin.png',
              module: MODULES.BINS,
            },
            {
              title: 'Trucks',
              img: 'bin-truck.png',
              module: MODULES.TRUCKS,
            },
            {
              title: 'History',
              img: 'history-black.svg',
              module: MODULES.HISTORY,
            },
          ].map((button, index) => (
            <TopButton
              key={index}
              onClick={() => {
                setModule(button?.module);
                handleShowSectionToggle(button.title);
              }}
              title={button.title}
              img={button.img}
              disabled={loading}
              active={module === button.module && showSection}
            />
          ))}
          {showSection && module !== MODULES.HISTORY ? (
            <div className="grid-container">
              <div className="list-container">
                <ListForMap
                  module={module}
                  data={dataBasedOnModule}
                  isLoading={loading}
                  showDetails={handleShowMapFlyout}
                  binDetails={binDetails}
                  truckDetails={truckDetails}
                  setShowDetailsSection={setShowDetailsSection}
                />
              </div>

              {showDetailsSection && (binDetails || truckDetails) && (
                <div className="details-container">
                  <Details
                    trucks={trucks}
                    bins={bins}
                    detailData={
                      module === MODULES.BINS ? binDetails : truckDetails
                    }
                    detailComponentType={
                      module === MODULES.BINS ? 'Bin' : 'Truck'
                    }
                    toggleShowDetailsVisibility={handleShowDetailsToggle}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
        {module !== MODULES.HISTORY && (
          <Filter
            filter={filter}
            truckData={trucks}
            binData={bins}
            setFilter={setFilter}
            module={module}
            setModule={setModule}
          />
        )}
      </div>

      {module === MODULES.HISTORY ? (
        <div className="history-container">
          <History trucks={trucks} />
        </div>
      ) : (
        <div
          className=" map-area"
          style={{
            overflow: 'hidden',
            marginTop: '-2.5rem',
          }}
        >
          <div
            className={` d-flex h-${
              // @ts-ignore
              module === MODULES.HISTORY ? '0' : '100'
            } position-relative`}
            style={{
              backgroundColor: '#DDDDDD',
              overflowY: 'hidden',
              objectFit: 'cover',
            }}
          >
            <Map
              setShowSection={setShowSection}
              module={module}
              mapRef={mapRef}
              markers={dataBasedOnModule}
              handleItemSelect={handleShowDetailsSection}
              selectedItemName={binDetailName ?? truckDetailName}
              showFlyout={isMapFlyoutVisible}
              handleShowMapFlyout={handleShowMapFlyout}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withAccessControl(ScrapBins);
