/* eslint-disable no-console */
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

type GlobalContextType = {
  showLiveAssetTracking: boolean;
  setShowLiveAssetTracking: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GlobalContext: React.Context<GlobalContextType | undefined> =
  createContext<GlobalContextType | undefined>(undefined);

export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within an GlobalProvider');
  }
  return context;
};

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showLiveAssetTracking, setShowLiveAssetTracking] =
    useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/asset-tracking') {
      setShowLiveAssetTracking(false);
    }
  }, [location.pathname]);

  return (
    <GlobalContext.Provider
      value={{
        showLiveAssetTracking,
        setShowLiveAssetTracking,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
