import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import TrafficLightIndicator from './components/TrafficLightIndicator';
import {
  Query,
  ShiftLoaderDateRangeType,
  ShiftLoadsGraphOption,
} from '../../../../utils/constants';
import MonthSelect from './components/MonthSelect';
import MonthlyHopperGraph from './components/MonthlyGraph';
import { DateRangeDetails, FilterValues } from '../../types';
import moment from 'moment';
import { getUnixTimestampsFromFilter } from '../../utils';
import { DateTimeFormats, TrafficLightColor } from '../../constants';
import Timeline from './components/TimeLine';
import useQueryParams from '../../../../../hooks/useQueryParams';
import {
  convertHoursToTimeString,
  getTimeIntervalForDaysTrafficLightGraphToolTip,
  getDateForTrafficLightGraphToolTip,
  getTimeIntervalForHourlyTrafficLightGraphToolTip,
  getMetaData,
  getRanges,
  hourlyGraphFormattor,
  monthlyGraphFormattor,
  processDailyGraphData,
  processHourlyGraphData,
  processMonthlyGraphData,
  getDateForHourlyTrafficLightGraphToolTip,
} from './utils';
import { API_METHODS } from '../../../../../dustControl/utils/constants';
import { Endpoint, getUrl } from '../../helper';
import { useNavigate } from 'react-router-dom';
import { fetch } from '../../../../../utils/api';
import { toast } from 'react-toastify';
import {
  HourlyGraphData,
  TotalRunTimeAndStoppedTime,
  MonthlyGraphData,
  DateTimeRanges,
  Ranges,
  TrafficLightStatus,
  Segment,
} from './types';
import { WebSocketURL } from '../../../../../utils/constants';

const TrafficLight: React.FC<{
  filterValues: FilterValues;
  dateRangeType: ShiftLoaderDateRangeType;
  dateRangeDetails: DateRangeDetails;
}> = (props) => {
  const navigate = useNavigate();
  const unixStartTimeRef = useRef(props.dateRangeDetails.startHourUnix);
  const selectedTabValue: string | null = useQueryParams(Query.GRAPH_TAB);

  const [dailyGraphData, setDailyGraphData] = useState<HourlyGraphData[]>([]);
  const [hourlyGraphData, setHourlyGraphData] = useState<HourlyGraphData[]>([]);
  const [monthlyGraphData, setMonthlyGraphData] = useState<MonthlyGraphData[]>(
    [],
  );
  const [dateTimeRanges, setDateTimeRanges] = useState<DateTimeRanges>({
    dateRange: 0,
    timeRange: 0,
  });
  const [
    totalRunTimeAndStoppedTimeForInMinutes,
    setTotalRunTimeAndStoppedTimeForInMinutes,
  ] = useState<TotalRunTimeAndStoppedTime>({ runtime: 0, stopped: 0 });
  const [metaData, setMetaData] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<number>(-1);
  const [trafficLightStatus, setTrafficLightStatus] = useState(false);
  const [startEndOfMonths, setStartEndOfMonths] = useState({
    start: 0,
    end: 12,
  });

  const getTimelineData = async (
    grouping: string | null,
    ranges: Ranges,
    chosenMonth = 0,
  ) => {
    if (!grouping) return;
    const filters = getUnixTimestampsFromFilter(props.filterValues);

    if (filters.fromDate >= filters.toDate) {
      setHourlyGraphData([]);
      setDailyGraphData([]);
      setMonthlyGraphData([]);
      setTotalRunTimeAndStoppedTimeForInMinutes({ runtime: 0, stopped: 0 });
      return;
    }

    if (grouping === ShiftLoadsGraphOption.MONTHS) {
      const start = props.filterValues.dateRange.to
        ? moment(
            props.filterValues.dateRange.from,
            DateTimeFormats.DATE,
          ).month() + 1
        : 1;

      const end =
        !props.filterValues.dateRange.to &&
        props.filterValues.dateRange.from !=
          moment().format(DateTimeFormats.DATE)
          ? 12
          : moment(
              props.filterValues.dateRange.to ||
                props.filterValues.dateRange.from,
              DateTimeFormats.DATE,
            ).month() + 1;
      setStartEndOfMonths({ start, end });

      filters.fromDate =
        moment
          .unix(props.dateRangeDetails.startMonthUnix)
          .startOf('year')
          .add(
            (chosenMonth + 1 < start ? 12 - start : 0) + chosenMonth,
            'months',
          )
          .unix() * 1000;

      filters.toDate =
        moment
          .unix(props.dateRangeDetails.startMonthUnix)
          .startOf('year')
          .add(
            (chosenMonth + 1 < start ? 12 - start : 0) + chosenMonth,
            'months',
          )
          .endOf('month')
          .unix() * 1000;
    } else if (grouping === ShiftLoadsGraphOption.DAYS) {
      if (props.dateRangeType <= ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE) {
        filters.fromDate =
          (props.dateRangeDetails.startDayUnix - 14 * 3600 * 24) * 1000;
        filters.toDate = props.dateRangeDetails.endDayUnix * 1000;
      } else {
        filters.fromDate = props.dateRangeDetails.startTimeUnix * 1000;
        filters.toDate = props.dateRangeDetails.endTimeUnix * 1000;
      }
    }

    setIsLoading(true);

    try {
      let processedData: any = [];

      const response = await fetch({
        method: API_METHODS.GET,
        endPoint: getUrl(Endpoint.getTrafficLightTimeline),
        reqParams: {
          grouping: grouping.toLowerCase(),
          ...filters,
        },
        navigateToLoginPage: () => navigate('/'),
      });

      const responseData = await response.data;
      processedData = responseData;

      setTotalRunTimeAndStoppedTimeForInMinutes({
        runtime: processedData.totalRuntimeMinutes,
        stopped: processedData.totalStoppedMinutes,
      });
      switch (grouping) {
        case ShiftLoadsGraphOption.HOURS:
          processedData = processHourlyGraphData(processedData, ranges);
          setHourlyGraphData(processedData);
          break;
        case ShiftLoadsGraphOption.DAYS:
          processedData = processDailyGraphData(processedData, ranges);
          setDailyGraphData(processedData);
          break;
        case ShiftLoadsGraphOption.MONTHS:
          processedData = processMonthlyGraphData(processedData);
          setMonthlyGraphData(processedData);
          break;
        default:
          break;
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getTrafficLightStatus = async () => {
    try {
      const response = await fetch<TrafficLightStatus>({
        method: API_METHODS.GET,
        endPoint: getUrl(Endpoint.getTrafficLightStatus),
        navigateToLoginPage: () => navigate('/'),
      });
      const responseData = response.data;

      const processedData = responseData;
      setTrafficLightStatus(
        processedData.status == TrafficLightColor.GREEN ? true : false,
      );
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    unixStartTimeRef.current = props.dateRangeDetails.startHourUnix;
  }, [props.dateRangeDetails]);

  useEffect(() => {
    getTrafficLightStatus();

    const socketUrl = WebSocketURL + 'shift_loader_traffic_light';
    const socket = new WebSocket(socketUrl);
    let heartbeatInterval: NodeJS.Timeout;

    socket.onopen = () => {
      console.log('WebSocket connection opened for shift loader loads');
      heartbeatInterval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send('heartbeat');
          console.log('Heartbeat sent');
        }
      }, 25000);
    };

    socket.onmessage = (event: MessageEvent) => {
      const message = event?.data;
      console.log('WebSocket message received:', message);
      const data = JSON.parse(message);

      setTrafficLightStatus(
        data.status == TrafficLightColor.GREEN ? true : false,
      );

      setHourlyGraphData((prev) => {
        return [
          ...prev,
          {
            start: prev.length > 0 ? prev[prev.length - 1].end : 0,
            end: (moment().unix() - unixStartTimeRef.current) / 3600,
            status: data.status == TrafficLightColor.GREEN ? true : false,
          },
        ];
      });

      socket.onerror = (error: Event) => {
        console.error('WebSocket error:', error);
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed for pushes 2');
        clearInterval(heartbeatInterval);
      };

      return () => {
        if (heartbeatInterval) clearInterval(heartbeatInterval);
        socket.close();
      };
    };
  }, []);

  useEffect(() => {
    let selectedOption = selectedTabValue
      ? selectedTabValue
      : ShiftLoadsGraphOption.HOURS;

    const metaDataObj = getMetaData(props.filterValues, selectedOption);
    const newRanges = getRanges(props.filterValues);

    let currMonth = 0;
    if (props.dateRangeType <= ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE)
      currMonth = moment().month();
    else if (
      props.dateRangeType <= ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE
    )
      currMonth = moment(props.filterValues.dateRange.from).month();
    else {
      currMonth = moment(props.filterValues.dateRange.to).month();
    }

    setSelectedMonth(currMonth);
    setMetaData(metaDataObj.metaData);
    setDateTimeRanges(metaDataObj.dateTimeRanges);

    getTimelineData(selectedOption, newRanges, selectedMonth);

    if (
      selectedTabValue == ShiftLoadsGraphOption.HOURS &&
      props.dateRangeType == ShiftLoaderDateRangeType.CURRENT_DAY
    ) {
      const interval = setInterval(() => {
        setHourlyGraphData((prev: HourlyGraphData[]) => {
          setTotalRunTimeAndStoppedTimeForInMinutes((originalData: any) => ({
            runtime:
              originalData.runtime + (prev[prev.length - 1]?.status ? 1 : 0),
            stopped:
              originalData.stopped + (!prev[prev.length - 1]?.status ? 1 : 0),
          }));
          if (prev.length) {
            return [
              ...prev.slice(0, -1),
              {
                start: prev[prev.length - 1].start,
                end: Math.min(
                  prev[prev.length - 1].end + 1 / 60,
                  dateTimeRanges.timeRange,
                ),
                status: prev[prev.length - 1].status,
              },
            ];
          }
          return [];
        });
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [props.filterValues, selectedTabValue]);

  useEffect(() => {
    let selectedOption = selectedTabValue
      ? selectedTabValue
      : ShiftLoadsGraphOption.HOURS;
    const ranges = getRanges(props.filterValues);
    if (selectedMonth != -1)
      getTimelineData(selectedOption, ranges, selectedMonth);
  }, [selectedMonth]);
  return (
    <div
      style={{ height: 200 }}
      className={'rounded-3 bg-white shadow-sm py-3 px-4 w-100'}
    >
      {isLoading ? (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div className="d-flex flex-column h-100">
          <div className="d-flex justify-content-between">
            <h6>Hopper Runtime</h6>

            <div className="d-flex align-items-center">
              <div
                style={{ fontSize: 14 }}
                className=" mt-1 me-4 d-flex align-items-center"
              >
                <TrafficLightIndicator stop={false} shadow={false} size={8} />
                <div className="fw-bold ms-2">
                  Runtime:{' '}
                  {convertHoursToTimeString(
                    totalRunTimeAndStoppedTimeForInMinutes.runtime / 60,
                  )}
                </div>
              </div>

              <div
                style={{ fontSize: 14 }}
                className="mt-1 d-flex align-items-center"
              >
                <TrafficLightIndicator stop={true} shadow={false} size={8} />
                <div className="fw-bold ms-2">
                  Stopped:{' '}
                  {convertHoursToTimeString(
                    totalRunTimeAndStoppedTimeForInMinutes.stopped / 60,
                  )}
                </div>
              </div>

              {selectedTabValue == ShiftLoadsGraphOption.MONTHS && (
                <div className=" ms-4">
                  <MonthSelect
                    start={startEndOfMonths.start}
                    end={startEndOfMonths.end}
                    value={selectedMonth}
                    onChange={(v) => setSelectedMonth(v)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-grow-1 align-items-center">
            {(selectedTabValue == ShiftLoadsGraphOption.HOURS ||
              selectedTabValue == ShiftLoadsGraphOption.DAYS ||
              selectedTabValue == null) && (
              <>
                {props.dateRangeType == ShiftLoaderDateRangeType.CURRENT_DAY &&
                  selectedTabValue == ShiftLoadsGraphOption.HOURS && (
                    <div className="px-4 my-4 me-3 border-end">
                      <TrafficLightIndicator stop={!trafficLightStatus} />
                    </div>
                  )}

                <div className="position-relative px-4">
                  <span
                    className="position-absolute fst-italic fw-bold"
                    style={{
                      fontSize: 12,
                      top: 25,
                      color: '#6E6E6E',
                    }}
                  >
                    {metaData}
                  </span>

                  {selectedTabValue == ShiftLoadsGraphOption.DAYS ? (
                    <Timeline
                      getDate={(popupData: any) =>
                        getDateForTrafficLightGraphToolTip(
                          popupData,
                          props.dateRangeDetails.startDayUnix,
                          props.dateRangeType,
                        )
                      }
                      getInterval={(popupData: any) =>
                        getTimeIntervalForDaysTrafficLightGraphToolTip(
                          popupData,
                          props.dateRangeDetails.startDayUnix,
                          props.dateRangeType,
                        )
                      }
                      formator={(index: number) =>
                        monthlyGraphFormattor(
                          index,
                          props.filterValues,
                          dateTimeRanges,
                        )
                      }
                      unwantedWidth={330}
                      segments={dailyGraphData}
                      range={24 * dateTimeRanges.dateRange}
                    />
                  ) : (
                    <Timeline
                      getDate={(popupData: Segment) =>
                        getDateForHourlyTrafficLightGraphToolTip(
                          popupData,
                          props.dateRangeDetails.startTimeUnix,
                        )
                      }
                      getInterval={(popupData: Segment) =>
                        getTimeIntervalForHourlyTrafficLightGraphToolTip(
                          popupData,
                          props.dateRangeDetails.startHourUnix,
                        )
                      }
                      unwantedWidth={
                        props.dateRangeType ==
                        ShiftLoaderDateRangeType.CURRENT_DAY
                          ? 448
                          : 330
                      }
                      segments={hourlyGraphData}
                      formator={(index: number) =>
                        hourlyGraphFormattor(
                          index,
                          moment
                            .unix(props.dateRangeDetails.startTimeUnix)
                            .hour(),
                        )
                      }
                      range={Math.max(
                        Math.ceil(
                          (props.dateRangeDetails.endTimeUnix -
                            props.dateRangeDetails.startTimeUnix) /
                            3600,
                        ),
                        1,
                      )}
                    />
                  )}
                </div>
              </>
            )}

            {selectedTabValue == ShiftLoadsGraphOption.MONTHS && (
              <div className="w-100 h-100">
                <MonthlyHopperGraph
                  month={
                    (props.filterValues.dateRange.to
                      ? moment(
                          props.filterValues.dateRange.from,
                          DateTimeFormats.DATE,
                        ).month() + 1
                      : 1) + selectedMonth
                  }
                  year={moment(
                    props.filterValues.dateRange.from,
                    DateTimeFormats.DATE,
                  ).year()}
                  data={monthlyGraphData}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TrafficLight;
