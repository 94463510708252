import React, { useState } from 'react';
import { Download } from '../../../../../dustControl/components/SubHeader';
import { SubHeaderProps } from './types';
import TimeSelect from '../../../../components/TimeSelect/TimeSelect';
import { DateRangeSelector } from '../../../../../manageUser/UserFilterModal/DateRangeSelector';
import moment from 'moment';
import { DateTimeFormats } from '../../constants';
import { DatePicker } from '@optitrac/components';
import styles from './materialMovementSubHeader.module.css';

interface DateRange {
  from: Date;
  to: Date | undefined;
}

const getDefaultDateRangeInString = () => ({
  from: moment().format(DateTimeFormats.DATE),
  to: '',
});

const SubHeader: React.FunctionComponent<SubHeaderProps> = ({
  filterValues,
  loading,
  setFilterValues,
  onDownloadClick,
}) => {
  const [dateRangeObj, setDateRangeObj] = useState<DateRange | undefined>({
    from: new Date(filterValues.dateRange?.from),
    to: undefined,
  });

  const onApplyDateRange = (v: DateRange) => {
    setDateRangeObj(v);

    const isDateRangeChanged = (newRange: { from: string; to: string }) => {
      return (
        filterValues.dateRange?.from != newRange.from ||
        filterValues.dateRange?.to != newRange.to
      );
    };

    const resetDateRange = v
      ? {
          from: moment(v?.from).format(DateTimeFormats.DATE),
          to:
            v?.to &&
            moment(v.from).format(DateTimeFormats.DATE) !=
              moment(v.to).format(DateTimeFormats.DATE)
              ? moment(v.to).format(DateTimeFormats.DATE)
              : '',
        }
      : getDefaultDateRangeInString();

    if (isDateRangeChanged(resetDateRange))
      setFilterValues({ ...filterValues, dateRange: resetDateRange });
  };

  return (
    <div className="d-flex align-items-center justify-content-between px-4 py-2">
      <div className="d-flex align-items-center">
        <DatePicker
          finalRange={dateRangeObj}
          handleApply={(v: DateRange) => {
            onApplyDateRange(v);
          }}
          isDisabled={false}
          buttonClass={styles.dateButtonClass}
        />
        <div className="mx-2">
          <TimeSelect
            disabled={loading}
            value={filterValues.from}
            onChange={(value: any) => setFilterValues({ from: value?.value })}
          />
        </div>
        <div className="mx-2">
          <TimeSelect
            placeholder="11:59 PM"
            disabled={loading}
            value={filterValues.to}
            onChange={(value: any) => setFilterValues({ to: value?.value })}
          />
        </div>
      </div>
      <Download onDownloadClick={onDownloadClick} />
    </div>
  );
};

export default SubHeader;
