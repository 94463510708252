import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import PopupModal from '..';
import { BinWithPickup } from '../../../utils/types';
import CustomButton from '../../../../dustControl/components/CustomButton';
import { BIN_LEVEL, PERCENTAGE_TO_NUMBER_MAP } from '../../../utils/constants';
import DropdownField from '../../FormDropdown/DropdownField';
import { useScrapBins } from '../../../context/scranBinsContext';
import {
  createDropDownField,
  createFormData,
  createHeader,
  displayButtonText,
  getLastLocation,
} from '../../../utils/helpers';
import RadioButton from '../../radioButton';
import { Modal } from '.';
import './../styles.css';
type MissingOrEditRequestPickupProps = {
  isOpen: boolean;
  loading: boolean;
  handleConfirmClick: () => void;
  isBinEditable: boolean;
  onClose: () => void;
  setShowModal: Dispatch<SetStateAction<Modal>>;
  bin: BinWithPickup;
  setRequestData: Dispatch<
    SetStateAction<{ [key in string]: string | number }>
  >;
};

type FieldTtype = {
  title: string;
  value: string;
  name: string;
  type: string;
  options?:
    | BinWithPickup[]
    | { value: string | number; name: string }[]
    | { scrapType: string | number; name: string }[]
    | { dumpPoint: string | number; name: string }[]
    | { dropoffPoint: string | number; name: string }[]
    | { pickupPoint: string | number; name: string }[]
    | { lastSeenLocation: string | number; name: string }[];
  field?: string;
  id: number;
};

type BinProperty = keyof Pick<
  BinWithPickup,
  'scrapType' | 'dumpPoint' | 'dropoffPoint' | 'pickupPoint'
>;

function uniqueBinAttributes<T extends BinProperty>(
  bins: BinWithPickup[],
  attr: T,
): { [K in T | 'name']: string }[] {
  return Array.from(new Set(bins.map((bin) => bin[attr]))).map(
    (uniqueAttr) => ({
      [attr]: uniqueAttr, // Dynamic key based on the attribute
      name: uniqueAttr,
    }),
  ) as { [K in T | 'name']: string }[];
}

const MissingOrEditRequestPickup: FC<MissingOrEditRequestPickupProps> = (
  props,
) => {
  const { bins } = useScrapBins();

  const uniqueScrapTypes = uniqueBinAttributes(bins, 'scrapType');
  const uniqueDumpPoints = uniqueBinAttributes(bins, 'dumpPoint');
  const uniqueDropoffPoints = uniqueBinAttributes(bins, 'dropoffPoint');
  const uniquePickupPoints = uniqueBinAttributes(bins, 'pickupPoint');

  const lastSeenLocationOptions = [
    {
      lastSeenLocation: props.bin.lastPickup?.events.at(-1)?.zone ?? '-',
      name: props.bin.lastPickup?.events.at(-1)?.zone ?? '-',
    },
  ];

  const fields: FieldTtype[] = [
    {
      title: 'Last Seen Location',
      value: props.bin.lastPickup?.events.at(-1)?.zone ?? '-',
      type: 'dropdown',
      options: lastSeenLocationOptions,
      field: 'lastSeenLocation',
      id: 0,
      name: 'lastSeenLocation',
    },
    {
      title: 'Pickup Point',
      value:
        getLastLocation(props.bin.lastLocation) ?? props.bin.pickupPoint ?? '-',
      type: 'dropdown',
      options: uniquePickupPoints,
      field: 'pickupPoint',
      id: 1,
      name: 'pickupPoint',
    },
    {
      title: 'Dump Required?',
      value: props.bin.pickupRequest?.dropoffOnly ? 'false' : 'true',
      type: 'radio',
      id: 2,
      name: 'dropoffOnly',
    },
    {
      title: 'Bin Level',
      value: `${props.bin.binLevel}%` ?? '-',
      type: 'dropdown',
      options: BIN_LEVEL,
      field: 'name',
      id: 3,
      name: 'binLevel',
    },
    {
      title: 'Scrap Type',
      value: props.bin.scrapType ?? '-',
      type: 'dropdown',
      options: uniqueScrapTypes,
      field: 'scrapType',
      id: 4,
      name: 'scrapType',
    },
    {
      title: 'Dump Point',
      value: props.bin.dumpPoint ?? '-',
      type: 'dropdown',
      options: uniqueDumpPoints,
      field: 'dumpPoint',
      id: 5,
      name: 'dumpPoint',
    },
    {
      title: 'Drop-Off Point',
      value: props.bin.dropoffPoint ?? '-',
      type: 'dropdown',
      options: uniqueDropoffPoints,
      field: 'dropoffPoint',
      id: 6,
      name: 'dropOffPoint',
    },
  ];

  const [requestPickupFormData, setRequestPickupFormData] = useState(
    fields.map(({ title, value, type, options, field, id, name }) =>
      createFormData(
        title,
        value,
        name,
        id,
        type,
        options &&
          Array.from(
            new Set(
              options.map((bin) => createDropDownField((bin as any)[field!])),
            ),
          ),
      ),
    ),
  );

  const isDropOffOnly = requestPickupFormData.reduce(
    (accumulator, requestPickupField) => {
      if (requestPickupField.id === 2) {
        accumulator = requestPickupField.value === 'true' ? false : true;
      }
      return accumulator;
    },
    false,
  );

  const handleDropDownClick = ({
    fieldKey,
    optionValue,
  }: {
    fieldKey: string;
    optionValue: string;
  }) => {
    setRequestPickupFormData((prev) =>
      prev.map((formData) => {
        if (formData.title === fieldKey)
          return { ...formData, value: optionValue };
        else return formData;
      }),
    );
  };
  const handleRadioButtonClick = (
    event: ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { value } = event.target;
    setRequestPickupFormData((prev) =>
      prev.map((formData) => {
        if (formData.id === id) return { ...formData, value: value };
        else return formData;
      }),
    );
  };

  const handleRequestPickupClick = () => {
    const requestData = requestPickupFormData.reduce(
      (accum: { [key in string]: string | number }, currentdata) => {
        if (currentdata.id !== 0) {
          switch (currentdata.id) {
            case 3:
              accum[currentdata.name] =
                PERCENTAGE_TO_NUMBER_MAP[
                  currentdata.value as keyof typeof PERCENTAGE_TO_NUMBER_MAP
                ];
              break;
            case 2:
              // The value is currently stored based on the input label called 'Dump Required'. However,
              // when sending the payload, the name is changed to 'dropOffOnly', which has the opposite meaning.
              accum[currentdata.name] =
                currentdata.value === 'false' ? 'true' : 'false';
              break;
            default:
              accum[currentdata.name] = currentdata.value;
          }
        }
        return accum;
      },
      { binName: props.bin.name },
    );

    // need to swap boolean for dropoffONly field.
    props.setRequestData(requestData);
    props.isBinEditable
      ? props.handleConfirmClick()
      : props.setShowModal(Modal.CONFIRM);
  };
  return (
    <PopupModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={createHeader(props.bin.name, props.isBinEditable)}
    >
      <div className=" d-flex flex-column  gap-3 justify-content-evenly align-content-center">
        <div className="d-flex flex-column gap-3" style={{ width: '500px' }}>
          {requestPickupFormData.map(({ title, value, id, type, options }) => {
            if (type === 'dropdown') {
              const isDisabled = isDropOffOnly && [3, 4, 5].includes(id);
              const isLastSeenLocation = title === 'Last Seen Location';
              return (
                <DropdownField
                  key={id}
                  noSearch={true}
                  style={{ flex: '0.5' }}
                  name={title}
                  fieldKey={title}
                  selectedOptions={value}
                  allOptions={options ?? []}
                  multiselect={false}
                  handleChange={handleDropDownClick}
                  errorMessage={''}
                  customDropdownlayout={
                    isLastSeenLocation || isDisabled ? 'disabled-dropdown' : ''
                  }
                />
              );
            } else if (type === 'radio') {
              return (
                <div className="d-flex flex-row" style={{ padding: '6px' }}>
                  <div style={{ flex: '0.4', textAlign: 'left' }}>{title}</div>
                  <div
                    style={{
                      flex: '1',
                      display: 'flex',
                      gap: '1rem',
                      fontSize: '13px',
                    }}
                  >
                    <RadioButton
                      style={{
                        fontSize: '13px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.1rem',
                      }}
                      checked={value === 'true'}
                      id={id.toString()}
                      name={title}
                      value={'true'}
                      text={'Yes'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleRadioButtonClick(event, id)
                      }
                    />
                    <RadioButton
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.1rem',
                      }}
                      checked={value === 'false'}
                      id={id.toString()}
                      name={title}
                      value={'false'}
                      text={'No'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleRadioButtonClick(event, id)
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <CustomButton
          style={{
            background: 'black',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            width: 'fit-content',
            alignSelf: 'end',
          }}
          loading={props.loading}
          id="RequestPickup"
          title={displayButtonText(props.isBinEditable)}
          onClick={handleRequestPickupClick}
        />
      </div>
    </PopupModal>
  );
};

export default MissingOrEditRequestPickup;
