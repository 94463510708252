import React from 'react';

const Tnc = () => {
  return (
    <div className="tnc-view">
      <div style={{ paddingRight: 5 }}>Privacy Policy </div> |{' '}
      <div style={{ paddingLeft: 5 }}> Terms of Service</div>
    </div>
  );
};

export default Tnc;
