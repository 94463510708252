import React, { useMemo } from 'react';
import './styles.scss';
import LabelWithLine from './LabelWithLine';
import { JobCycleProps, PieChartDataStructure } from './types';
import { PickupEvent, PickupEventType } from '../../utils/types';
import PieChart from '../PieChart';
import { calculateEventDuration, getLastLocation } from '../../utils/helpers';
import { getPickupTotalDuration } from './helper';
import { PieChartProps } from '../PieChart/types';

const eventTitles: Partial<Record<PickupEventType, string>> = {
  pickedUp: 'Loading',
  transitToDump: 'Travelling to dump',
  dumped: 'Dumping',
  transitToDropoff: 'Travelling to drop-off',
};

const getPieChartData = (events: PickupEvent[]): PieChartDataStructure[] => {
  return Object.entries(eventTitles).map(([eventType, title]) => {
    const matchingEvent: PickupEvent | undefined = events.find(
      (event) => event.type === eventType,
    );
    if (!matchingEvent || !matchingEvent.endTime) {
      return { title, duration: 0, time: '-' };
    }
    const eventTimings = calculateEventDuration(
      matchingEvent.startTime,
      matchingEvent.endTime,
    );

    if (
      eventType === PickupEventType.PICKUP ||
      eventType === PickupEventType.DUMP
    ) {
      title = `${title} (${getLastLocation(matchingEvent)})`;
    }

    return {
      title,
      time: eventTimings.time, // Adjust as needed, or calculate based on events
      duration: eventTimings.duration,
    };
  });
};

const JobCyclePieChart: React.FC<JobCycleProps> = ({ pickup }) => {
  const events = useMemo(() => getPieChartData(pickup.events), [pickup.events]);

  const pieChartProps: PieChartProps = {
    legend: {
      header: {
        headerKey: 'Total Cycle Duration',
        headerValue: getPickupTotalDuration(pickup),
      },
      data: events.map((event: PieChartDataStructure) => ({
        key: event.title,
        value: event.time,
      })),
    },
    data: events.map((event: PieChartDataStructure) => {
      if (event.time !== '00:00 h') {
        return event.duration || 0;
      }
      return 0;
    }),
  };

  return (
    <>
      <LabelWithLine title={'Job Cycle Timeline'} />

      <PieChart legend={pieChartProps.legend} data={pieChartProps.data} />
    </>
  );
};

export default JobCyclePieChart;
