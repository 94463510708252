import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import { EllipsisItem, EllipsisProps } from './types';

export const EllipsisDropdown: React.FunctionComponent<EllipsisProps> = (
  props: EllipsisProps,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleItemClick = (onClick: () => void) => {
    return () => {
      onClick(); // Perform the item's action
      setIsOpen(false); // Close the dropdown
    };
  };

  return (
    <div className="ellipsis-dropdown" ref={wrapperRef}>
      <button
        className="ellipsis-button"
        style={{ fontSize: '24px' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        ⋮
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {props.items.map((item: EllipsisItem, index: number) => (
            <a
              key={index}
              style={{ textWrap: 'nowrap' }}
              href={'#'}
              onClick={handleItemClick(item.onClick)}
            >
              {item.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
