import React from 'react';

interface MonthOption {
  value: number;
  label: string;
}

const monthOptions: MonthOption[] = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

interface MonthSelectProps {
  value: number;
  start?: number;
  end?: number;
  onChange: (selectedOption: number) => void;
}

const MonthSelect: React.FC<MonthSelectProps> = ({
  value,
  onChange,
  start = 1,
  end = 12,
}) => {
  const filteredOptions = () => {
    if (start <= end) {
      return monthOptions.filter(
        (_, index) => index >= start - 1 && index <= end - 1,
      );
    } else {
      return [
        ...monthOptions.filter((_, index) => index >= start - 1),
        ...monthOptions.filter((_, index) => index <= end - 1),
      ];
    }
  };

  return (
    <select
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      className="border ps-2 pe-5 "
      style={{ outline: 'none', fontSize: 14, padding: '3.5px 0' }}
    >
      {filteredOptions().map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default MonthSelect;
