import { FC } from 'react';
import moment from 'moment';

type DailyCustomizedAxisTickProps = {
  x?: string;
  y?: string;
  payload?: { value: string };
  queryDate: string;
  type?: string;
};
const DailyCustomizedAxisTick: FC<DailyCustomizedAxisTickProps> = (props) => {
  const isCurrDate = moment().format('DD/MM') == props.payload?.value;
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text
        x={0}
        y={0}
        dx={15}
        dy={5}
        textAnchor="end"
        stroke="#21B291"
        strokeWidth={isCurrDate ? 0.75 : 0}
        fontWeight={isCurrDate ? '900' : 'normal'}
        fill={isCurrDate ? '#21B291' : '#707070'}
      >
        {props.payload?.value}
      </text>
    </g>
  );
};

export default DailyCustomizedAxisTick;
