import React, { useEffect, useState } from 'react';
import './index.css';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import materialIcon from './../../assets/svgs/material_breakdown.svg';
import { Image } from 'react-bootstrap';

const MaterialBreakDown = ({ allSourceDumps, materials }) => {
  const [active, setActive] = useState(false);
  const [totalUniqueLoads, setTotalUniqueLoads] = useState(0);
  const [materialData, setMaterialData] = useState([{}]);

  useEffect(() => {
    let totalLoads = 0;
    let uniqueMaterials = [];
    let materialsData = [];
    allSourceDumps?.map((geofence) => {
      geofence?.loadMaterials?.map((loadMaterial) => {
        if (loadMaterial?.material) {
          if (loadMaterial.loadsCount) {
            totalLoads += loadMaterial.loadsCount;
          }
          let currentMaterial = {};
          materials?.forEach((material, index) => {
            if (material?.MaterialType === loadMaterial.material) {
              currentMaterial = material;
              currentMaterial.material = material.MaterialType;
            }
          });

          if (uniqueMaterials.includes(loadMaterial.material)) {
            materialsData?.forEach((mat, index) => {
              if (mat?.MaterialType === loadMaterial.material) {
                materialsData[index] = {
                  ...currentMaterial,
                  material: mat.MaterialType,
                  loadsCount:
                    materialsData[index].loadsCount + loadMaterial.loadsCount,
                };
              }
            });
          } else {
            uniqueMaterials?.push(loadMaterial?.material);
            materialsData.push({
              ...currentMaterial,
              material: loadMaterial.material,
              loadsCount: loadMaterial.loadsCount,
            });
          }
        }
      });
    });

    setMaterialData(materialsData);
    setTotalUniqueLoads(totalLoads);
  }, [allSourceDumps, materials]);

  return (
    <div className="m-body-right" style={{ paddingLeft: '0.8rem' }}>
      <div className="mm-top_heading">
        <div
          className={
            active
              ? 'material-breakdown-body-active pb-2'
              : 'material-breakdown-body '
          }
        >
          <div
            style={{ color: '#707070', cursor: 'pointer' }}
            className="mt-4 py-1 d-flex align-items-center"
            onClick={() => setActive(!active)}
          >
            <Image
              style={{
                marginRight: '0.5rem',
                marginLeft: '0.9rem',
                height: '0.9rem',
              }}
              src={materialIcon}
            />
            <div style={active ? { color: '#000', fontWeight: 600 } : {}}>
              MATERIAL BREAKDOWN
            </div>
            {!active ? (
              <RiArrowDropDownLine
                size={27}
                style={{
                  marginRight: '0.9rem',
                  marginLeft: '2rem',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <RiArrowDropUpLine
                onClick={() => setActive(!active)}
                size={27}
                style={{
                  marginRight: '0.9rem',
                  marginLeft: '2rem',
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
          {active && (
            <>
              <div
                className="pt-1 px-4"
                style={{
                  borderBottom: materialData?.length ? '1px solid #EBEBEB' : '',
                }}
              >
                <div className="mt-2 d-flex">
                  <div className="w-50">Total Loads:</div>
                  <div style={{ padding: '0px 1rem' }}>
                    <b>{totalUniqueLoads}</b>
                  </div>
                </div>
                <div className="d-flex mb-2 mt-1">
                  <div className="w-50">Material Types:</div>
                  <div style={{ padding: '0px 1rem' }}>
                    <b>{materialData?.length}</b>
                  </div>
                </div>
              </div>
              <div className=" mt-2">
                <ul>
                  {materialData.map((item) =>
                    item.loadsCount > 0 ? (
                      <li
                        className="d-flex justify-content-start align-items-center"
                        key={item.material}
                        style={{ padding: '0.2rem 0rem 0.7rem 0rem' }}
                      >
                        <span
                          className=" mm-dot-1"
                          style={{ backgroundColor: item?.color }}
                        ></span>{' '}
                        {item.material + ' ('}{' '}
                        <span style={{ color: '#707070' }}>
                          {' '}
                          {item.loadsCount} loads){' '}
                        </span>
                      </li>
                    ) : null,
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialBreakDown;
