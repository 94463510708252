import { Filter, Search } from '../../../dustControl/components/SubHeader';
import {
  DateRange,
  FilterField,
  FilterSections,
  FilterSubheaderProps,
  HistoryCategory,
  JobTypeFilters,
} from './types';
import DownloadIcon from '../../../dustControl/assets/icons/download-prestart.svg';
import DropdownField from '../FormDropdown/DropdownField';
import './styles.css';
import { DateRangeSelector } from '../../../manageUser/UserFilterModal/DateRangeSelector';
import { useMemo, useState } from 'react';
import { getInitialDateRangeForFilter } from './helper';
import FilterModal from './FilterModal';
import { useScrapBins } from '../../context/scranBinsContext';
import { BinHistory, BinWithPickup, PickupStatus } from '../../utils/types';
import { capitaliseFirstLetter } from '../../utils/helper';
import { DatePicker } from '@optitrac/components';

const FilterSubheader: React.FC<FilterSubheaderProps> = (props) => {
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>(props.dateRange);
  const [filterModalDateRange, setFilterModalDateRange] = useState<
    DateRange | undefined
  >(props.dateRange);
  const { bins } = useScrapBins();

  const truckNames: string[] = useMemo(() => {
    const names = props.binHistories.reduce(
      (truckNameSet: Set<string>, dateObject: BinHistory) => {
        dateObject.binHistories.forEach((history) =>
          truckNameSet.add(history.truckName),
        );
        return truckNameSet;
      },
      new Set<string>(),
    );
    return [...names].sort();
  }, [props.binHistories]);

  const binSortedAlphabetically = useMemo(
    () =>
      bins.sort(
        (firstBin: BinWithPickup, secondBin: BinWithPickup) =>
          firstBin.name.localeCompare(secondBin.name), // make sorting case insensitve
      ),
    [bins],
  );
  const filterFields: FilterField[] = useMemo(() => {
    const truckIdField: FilterField = {
      name: 'Truck ID',
      key: FilterSections.truckName,
      options: truckNames.map((truckName) => ({
        value: truckName,
        name: truckName,
      })),
      hideSearchBar: true,
    };
    const binIdField: FilterField = {
      name: 'Bin ID',
      key: FilterSections.bin,
      options: binSortedAlphabetically.map((bin) => ({
        value: bin.name,
        name: `Bin ${bin.name}`,
      })),
      hideSearchBar: false,
    };

    const firstTwoFields =
      props.selectedSection === HistoryCategory.BINS
        ? [binIdField, truckIdField]
        : [truckIdField, binIdField];

    return [
      ...firstTwoFields,
      {
        name: 'Job Status',
        key: FilterSections.status,

        options: [PickupStatus.INCOMPLETE, PickupStatus.COMPLETE].map(
          (status) => ({
            value: status,
            name: capitaliseFirstLetter(status),
            color: status === PickupStatus.INCOMPLETE ? '#D54A4A' : '#21A865',
          }),
        ),
        hideSearchBar: true,
      },
      {
        name: 'Job Type',
        key: FilterSections.type,
        options: Object.entries(JobTypeFilters).map(([value, name]) => ({
          value,
          name,
        })),
        hideSearchBar: true,
      },
    ];
  }, [binSortedAlphabetically, truckNames, props.selectedSection]);

  const onApplyDateRange = () => {
    setDateRange((prevDateRange: DateRange | undefined) => {
      if (!prevDateRange) {
        const resetDateRange = getInitialDateRangeForFilter();
        props.setSelectedDateRange(resetDateRange);
        setFilterModalDateRange(resetDateRange);
        return resetDateRange;
      } else {
        props.setSelectedDateRange(prevDateRange);
        setFilterModalDateRange(prevDateRange);
        return prevDateRange;
      }
    });
  };
  return (
    <>
      <div className={'d-flex align-items-center justify-content-between '}>
        <div className="d-flex gap-3">
          <div className="history-header-dropdown pt-1">
            {/* @ts-ignore */}
            <DropdownField
              allOptions={[
                { name: 'Bin History', value: HistoryCategory.BINS },
                { name: 'Truck History', value: HistoryCategory.TRUCKS },
              ]}
              noSearch={true}
              selectedOptions={props.selectedSection}
              handleChange={({
                optionValue,
              }: {
                optionValue: HistoryCategory;
              }) => props.setSelectedSection(optionValue)}
            />
          </div>
          <div className="history-header-dropdown">
            <DatePicker
              finalRange={dateRange}
              handleApply={(v: DateRange) => {
                setDateRange(v);
                onApplyDateRange();
              }}
              isDisabled={false}
              buttonClass="dateButtonClass"
            />
          </div>
        </div>
        <div className="d-flex">
          <Search
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.onSearchChange(e.target.value)
            }
          />
          <Filter toShowFilter openModal={setShowFilterModal} />
          <div
            onClick={props.onDownloadClick}
            className="d-flex align-items-center"
          >
            <img
              src={DownloadIcon}
              alt="download"
              className="subheader-download ms-2 cursor-pointer"
              onClick={props.onDownloadClick}
            />
            <div style={{ fontSize: '12px' }}>Download</div>
          </div>
        </div>
      </div>
      <FilterModal
        modalOpen={showFilterModal}
        modalToggle={setShowFilterModal}
        dateRange={dateRange}
        setFilterModalDateRange={setFilterModalDateRange}
        filterModalDateRange={filterModalDateRange}
        setDateRange={setDateRange}
        onApplyDateRange={onApplyDateRange}
        selectedFilters={props.selectedFilters}
        setSelectedFilters={props.setSelectedFilters}
        filterFields={filterFields}
      />
    </>
  );
};

export default FilterSubheader;
