import { useContext, useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Spinners from '../../dustControl/components/spinner';
import { getAccessToken } from './accessToken';
import { UserContext } from './userContext';
import { AuthContext } from './auth';

export const ProtectRoute = () => {
  const { fetchData } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWithLoading = async () => {
      await fetchData();
      setLoading(false);
    };

    fetchWithLoading();
  }, [fetchData]);

  if (loading) return <Spinners />;
  if (!getAccessToken()) return <Navigate to="/" replace />;
  return <Outlet />;
};

export const RedirectToLandingPage = () => {
  const { fetchData } = useContext(UserContext);
  const { isLoggedOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoggedOut) return setLoading(false);
    const fetchWithLoading = async () => {
      await fetchData();
      setLoading(false);
    };

    fetchWithLoading();
  }, [fetchData, isLoggedOut]);

  if (loading) return <Spinners />;
  if (getAccessToken()) return <Navigate to="/landing-page" replace />;
  else return <Outlet />;
};
