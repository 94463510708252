import { FunctionComponent } from 'react';
import { BinIconProps } from './types';

const BinYellow: FunctionComponent<BinIconProps> = (props: BinIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.95 50.5" {...props}>
    <g
      stroke="#2b2d2f"
      strokeLinecap="round"
      strokeWidth={2.5}
      data-name="Group 46045"
      transform="translate(1823.332 9554.467)"
    >
      <path
        fill="#f29f1a"
        d="M-1812.575-9512.258a4.93 4.93 0 0 1-4.767-3.678l-4.664-17.82a1.943 1.943 0 0 1 1.877-2.434h45.542a1.943 1.943 0 0 1 1.88 2.434l-4.664 17.82a4.93 4.93 0 0 1-4.768 3.681h-.013"
        data-name="Path 34729"
      />
      <circle
        cx={4.818}
        cy={4.818}
        r={4.818}
        fill="#fff"
        data-name="Ellipse 3347"
        transform="translate(-1813.106 -9514.853)"
      />
      <path fill="none" d="M-1790.698-9512.259h-13.318" data-name="Line 2164" />
      <circle
        cx={4.818}
        cy={4.818}
        r={4.818}
        fill="#fff"
        data-name="Ellipse 3348"
        transform="translate(-1791.242 -9514.853)"
      />
      <path
        fill="none"
        d="m-1803.146-9519.575-.834-11.113"
        data-name="Line 2165"
      />
      <path
        fill="none"
        d="m-1808.902-9519.575-1.667-11.113"
        data-name="Line 2166"
      />
      <path fill="none" d="M-1797.322-9519.575v-11.113" data-name="Line 2167" />
      <path
        fill="none"
        d="m-1791.566-9519.575.834-11.113"
        data-name="Line 2168"
      />
      <path
        fill="none"
        d="m-1785.136-9524.075.992-6.613"
        data-name="Line 2169"
      />
      <path
        fill="none"
        d="m-1818.958-9536.19 10.532-10.533a4.677 4.677 0 0 1 6.614 0l2.936 2.936"
        data-name="Path 34731"
      />
      <path
        fill="none"
        d="m-1802.48-9547.392 4.455-4.455a4.677 4.677 0 0 1 6.614 0l15.656 15.656"
        data-name="Path 34732"
      />
    </g>
  </svg>
);
export default BinYellow;
