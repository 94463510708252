import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { GraphData, ShiftLoadGraphProps } from '../../../../../utils/types';
import { FC } from 'react';
import { isCurrentDate } from '../../../utils';
import { ShiftLoadsGraphOption } from '../../../../../utils/constants';
import ToolTip from '../components/ToolTip';

//@ts-ignore
const CustomizedLabel = (props) => {
  const { x, y, width } = props.viewBox;
  return (
    <text
      x={x + width}
      y={y + 3}
      dx={33}
      textAnchor="end"
      fill={'#666'}
      fontWeight={'bold'}
    >
      Time
    </text>
  );
};

//@ts-ignore
const CustomToolTip = (props) => {
  const { label, payload, active } = props;

  if (payload?.length && active && label)
    return (
      <ToolTip
        date={label}
        metrics={[{ label: 'Loads', value: payload[0].value }]}
      />
    );
  return <></>;
};

const Monthly: FC<ShiftLoadGraphProps<GraphData>> = (props) => {
  return (
    <>
      <ResponsiveContainer height={250}>
        {!props.data ? (
          <p className="d-flex justify-content-center align-items-center">
            No Monthly data found
          </p>
        ) : (
          <BarChart
            margin={{ top: 5, right: 50, left: 10, bottom: 5 }}
            data={props.data}
          >
            <CartesianGrid strokeDasharray="1 1" vertical={false} />
            <XAxis
              tickMargin={17.5}
              tickFormatter={(value) => value.slice(0, -5)}
              interval={'preserveStart'}
              stroke="#3E5D90"
              padding={{ left: 20, right: 20 }}
              dataKey="date"
              tickLine={false}
              label={<CustomizedLabel />}
            />
            <YAxis
              domain={[0, (dataMax: number) => Math.max(dataMax, 5)]}
              ticks={Array.from(
                {
                  length:
                    Math.ceil(
                      Math.max(
                        Math.max(...props.data.map((d) => d.loads)) / 100,
                        5,
                      ),
                    ) + 1,
                },
                (_, i) => i * 100,
              )}
              dataKey="loads"
              padding={{ top: 15 }}
              label={{
                value: 'Loads',
                fontWeight: 'bold',
                angle: -90,
                position: 'insideLeft',
              }}
              tickMargin={10}
              axisLine={false}
              tickLine={false}
            />

            <Tooltip cursor={false} content={<CustomToolTip />} />
            <Bar dataKey="loads" fill="#2626DB" layout="vertical" barSize={13}>
              {props.data.map((entry, index) => (
                <Cell
                  fill={
                    isCurrentDate(
                      props.queryDate || '',
                      ShiftLoadsGraphOption.MONTHS,
                      entry.date.slice(0, -5),
                    )
                      ? '#69D8E3'
                      : '#7761ED'
                  }
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          </BarChart>
        )}
      </ResponsiveContainer>
      {props.children}
    </>
  );
};

export default Monthly;
