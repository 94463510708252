import { GroupedItem } from '../components/List/types';

export type CoordinatesAndItem = {
  coordinates: { latitude: number; longitude: number };
  item: TruckDataWithAvailability | BinWithPickup;
};

export type TruckDataWithAvailability = TruckData & {
  availability: TruckStatusForList;
};

export enum PickupEventType {
  TRANSIT_PICKUP = 'transitToPickup',
  PICKUP = 'pickedUp',
  TRANSIT_DUMP = 'transitToDump',
  DUMP = 'dumped',
  TRANSIT_DROPOFF = 'transitToDropoff',
  DELIVER = 'delivered',
}

export enum PickupType {
  SCHEDULED = 'scheduled',
  UNSCHEDULED = 'unscheduled',
}

export type PickupEvent = {
  startTime: Date;
  endTime?: Date;
  type: PickupEventType;
} & Partial<Location>;

export type Location = {
  zone: string;
  lat: number;
  long: number;
  zoneNumber: number;
};

export type BinPickup = {
  bin: Bin;
  pickupRef: string;
  status: PickupStatus;
  type: PickupType;
  truckName: string;
  operator?: Operator;
  endTime?: Date;
  isCurrentlyOnTruck?: boolean;
  events: PickupEvent[];
};

export type Operator = {
  _id: string;
  firstName: string;
  lastName: string;
};

export type Bin = {
  _id: string;
  name: string;
  pickupPoint: string;
  dumpPoint: string;
  dropoffPoint: string;
  scrapType: string;
  binLevel: number;
  hazards?: string;
  pickupRequest?: {
    time: Date;
    dropoffOnly?: boolean;
  };
};

export type BinHistory = {
  date: string;
  binHistories: BinPickup[];
};

declare type ModuleType = MODULES.BINS | MODULES.TRUCKS;

export type ListForMapProps = {
  data: GroupedItem;
  isLoading: boolean;
  showDetails: boolean;
  module: ModuleType;
  binDetails: BinWithPickup;
  setShowDetailsSection?: boolean;
};

export type BinWithPickup = Bin & {
  lastPickup?: BinPickup;
  lastEmptiedTime: string;
  lastUpdatedTime: string;
  lastLocation: Location;
};

export type TruckData = {
  name: string;
  shiftSummary: ShiftSummary;
  lastLocation: Location;
  lastLoad: string;
  pickup_info: PickUpInfo;
  operator: string;
  engineHourSummary: engineHourSummary;
  bin: string;
  status: TruckStatus;
  _id: string;
};

export enum TruckStatus {
  Parked = 'Parked',
  Active = 'Active',
  Idle = 'Idle',
}

export enum PickupStatus {
  COMPLETE = 'complete',
  IN_PROGRESS = 'inProgress',
  INCOMPLETE = 'incomplete',
  CANCELLED = 'cancelled',
}

export type GetAllTrucksData = {
  [PickupStatus.IN_PROGRESS]?: TruckData[];
  standby?: TruckData[];
  Workshop?: TruckData[];
};

type ShiftSummary = {
  active: number;
  idle: number;
  parked: number;
  totalBinLoad: number;
  prestartChecks: string;
  delays: number;
};

type engineHourSummary = {
  dailyEngineHour: number;
  lifetimeEngineHour: number;
};
type PickUpInfo = {
  pickupRef: string;
  status: string;
  type: string;
};

export enum MODULES {
  BINS = 'bins',
  TRUCKS = 'trucks',
  HISTORY = 'history',
  FILTER = 'Filter',
}

export enum GeneralBinStatus {
  IN_PROGRESS = 'In Progress',
  REQUESTED = 'Requested',
  MISSING = 'Missing',
  HUNDRED = '100%',
  SEVENTY_FIVE = '75%',
  FIFTY = '50%',
  TWENTY_FIVE = '25%',
  EMPTY = 'Empty',
  RUBBISH = 'Rubbish',
}

export enum SortStatus {
  BIN_LEVEL = 'binlevel',
  LAST_EMPTIED = 'lastemptied',
  LAST_UPDATED = 'lastupdated',
  BIN_LEVEL_TITLE = 'Bin Level',
  LAST_EMTIED_TITLE = 'Last Emptied',
  LAST_UPDATED_TITLE = 'Last Updated',
  TRUCK_AVAILABILITY_TITLE = 'Truck Availability',
  TRUCK_STATUS_TITLE = 'Truck Status',
  LAST_LOAD_TITLE = 'Last Load',
  TRUCK_AVAILABILITY = 'truckavailability',
  TRUCK_STATUS = 'truckstatus',
  LAST_LOAD = 'lastload',
}

export enum ZeroPercent {
  ZERO = '0%',
}

export enum DetailedBinStatus {
  PICK_UP_SCHEDULED = 'pick-up-scheduled',
  PICKED_UP = 'picked-up',
  DUMPED = 'dumped',
  JOB_COMPLETE = 'job-complete',
  BACK_AT_SOURCE = 'back-at-source',
  JOB_INCOMPLETE = 'job-incomplete',
  BIN_MISSING = 'bin-missing',
  PICK_UP_REQUESTED = 'pick-up-requested',
  STATUS_UNAVAILABLE = 'status-unavailable',
}

export enum TruckStatusForList {
  inProgress = 'Job in Progress',
  standby = 'Standby',
  Workshop = 'Workshop',
}

export enum SocketEvent {
  UPDATED_BIN_LIST = 'updatedBinList',
  UPDATE_TRUCK_LIST = 'updateTruckList',
  PICKUP_CHANGE = 'pickupStatusChange',
  TRUCK_LOCATION = 'updateTruckLocation',
}

export type SocketResponse =
  | {
      socketEvent: SocketEvent.PICKUP_CHANGE;
      type: PickupEventType;
      pickupReferenceNumber: string;
      location: string;
      startTime: Date;
      endTime?: Date;
      pickupDocument: BinPickup; // required for web
    }
  | {
      socketEvent: SocketEvent.UPDATED_BIN_LIST;
      bins: BinWithPickup[];
    }
  | {
      socketEvent: SocketEvent.UPDATE_TRUCK_LIST;
      trucks: GetAllTrucksData;
    }
  | {
      socketEvent: SocketEvent.TRUCK_LOCATION;
      truckName: string;
      mostRecentLocation: Location;
    };
