import { Image } from 'react-bootstrap';

const TopButton = ({ onClick, disabled, style, img, title }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      onClick={disabled ? null : onClick}
      style={{
        marginLeft: '3.3rem',
        height: '2rem',
        width: '10rem',
        padding: '0.1rem 0.7rem',
        background: '#05795F', //: "#2B2D2F"
        color: '#E5E5E7',
        boxShadow: '0px 0.25rem 0.33rem #00000029',
        borderRadius: '0.34rem',
        cursor: 'pointer',
        ...style,
      }}
    >
      <Image
        style={{ height: '1rem' }}
        src={require(`../../assets/images/${img}`)}
      />
      <p style={{ fontSize: '0.9rem', margin: '5px 0px 5px 5px' }}>{title}</p>
    </div>
  );
};

export default TopButton;
