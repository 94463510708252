import React, { useState } from 'react';

const StatusToggle = ({ isActive, onChange }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onChange();
    setLoading(false);
  };
  return (
    <div className="status-toggle">
      <button
        className={`w-100 border-0 d-flex justify-content-between p-1 text-white fw-bold rounded-pill button-press-change ${
          isActive ? 'flex-row-reverse ps-2' : 'pe-2'
        } ${
          loading
            ? 'bg-secondary button-pressed'
            : isActive
              ? 'bg-success'
              : 'bg-danger'
        }`}
        onClick={handleClick}
        disabled={loading}
      >
        <div className="bg-white fixed-width rounded-circle"></div>
        <span>{isActive ? 'Active' : 'Inactive'}</span>
      </button>
    </div>
  );
};

export default StatusToggle;
