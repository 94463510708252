import moment from 'moment';
import React, { useContext, useState } from 'react';
import { API_METHODS, ENDPOINTS } from '../../utils/constants';
import * as toast from '../../components/toast';
import BasicButton from '../BasicButton';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { capitaliseFirstLetter } from '../../utils/helper';
import { UserContext } from '../../../pages/service/userContext';

const ManageZone = (props) => {
  const { ApiHandler } = useContext(UserContext);
  const { setToggleManageZoneModal, zones, refreshData } = props;
  const [data, setData] = useState(zones);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleSubmit = async () => {
    let zones = data?.map((zone) => {
      return zone.zone;
    });
    if (zones.length === 0) {
      setToggleManageZoneModal((prev) => !prev);
    }
    try {
      setDisableBtn(true);
      let statusRes = {};
      if (zones.length) {
        statusRes = await ApiHandler({
          method: API_METHODS.POST,
          endPoint: ENDPOINTS.changeZoneStatus,
          reqParam: { zones },
        });
      }

      if (statusRes.status === 200) {
        setToggleManageZoneModal((prev) => !prev);
        refreshData();
      }
      setDisableBtn(false);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 6;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging && 'lightgrey',
    ...draggableStyle,
  });

  const getListStyle = () => ({
    background: '#F7C4C4',
    borderRadius: 7,
    padding: 20,
    width: '100%',
    height: '550px',
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (data[result.destination.index].status === 'In progress') return;

    const reorderedData = reorder(
      data,
      result.source.index,
      result.destination.index,
    );

    const modifiedInProgressZones = [];

    reorderedData.forEach((zone, index) => {
      if (zone.status === 'In progress' && data[index] !== zone) {
        const oldIndex = data.findIndex((obj) => {
          return obj.zone === zone.zone;
        });
        modifiedInProgressZones.push({
          zone: zone.zone,
          newIndex: index,
          oldIndex,
        });
      }
    });

    if (modifiedInProgressZones.length) {
      modifiedInProgressZones.forEach((zone) => {
        const tempZone = reorderedData[zone.oldIndex];
        reorderedData[zone.oldIndex] = reorderedData[zone.newIndex];
        reorderedData[zone.newIndex] = tempZone;
      });
    }

    const newReorderedData = reorderedData.map((data, index) => {
      return { ...data, priority: index + 1 };
    });

    setData(newReorderedData);
  };

  return (
    <>
      <div className="manage-zone-modal">
        <div className="zone-modal-view rounded-2 ">
          <div className="mt-4">
            <div className="d-flex justify-content-between">
              <p className="fw-bold m-0">MANAGE PRIORITIES</p>
              <div className="d-flex gap-2">
                <BasicButton
                  outlined
                  onClick={() => setToggleManageZoneModal((prev) => !prev)}
                  variant="outline-dark"
                >
                  Cancel
                </BasicButton>
                <BasicButton
                  onClick={handleSubmit}
                  variant="dark"
                  disabled={disableBtn}
                >
                  Save
                </BasicButton>
              </div>
            </div>
            <div className="mt-2">
              <p className="zone-modal-text m-0">
                Drag and drop zones to set their priority
              </p>
            </div>
          </div>
          <div className="zone-modal-main-view d-flex justify-content-between flex-wrap mt-4">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle()}
                  >
                    <div className="drop-zone-header fw-bold">
                      <p>Location</p>
                      <p>Watered</p>
                      <p>Times watered</p>
                      <p>Last job status</p>
                    </div>
                    {data.map((item, index) => (
                      <Draggable
                        key={item.zone.toString()}
                        draggableId={item.zone.toString()}
                        index={index}
                        isDragDisabled={item.status === 'In progress'}
                      >
                        {(provided, snapshot) => (
                          <div className="drag-item gap-4">
                            <div className="fs-5 fw-bold">P{index + 1}</div>
                            <div
                              className="drag-card-container"
                              style={{
                                border:
                                  snapshot.isDragging && '1px dashed #707070',
                              }}
                            >
                              <div
                                className={`drag-card ${
                                  item.status === 'In progress' &&
                                  'in-progress-drag-card'
                                } `}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <div>Zone {item.zone}</div>
                                <div
                                  style={{
                                    color:
                                      item.status === 'In progress' &&
                                      '#3AAA30',
                                  }}
                                >
                                  {item.status === 'In progress'
                                    ? 'In progress'
                                    : moment(item?.last_watered_time).fromNow()}
                                </div>
                                <div>{item.timesWatered}</div>
                                <div>
                                  {item.status === 'In progress'
                                    ? '-'
                                    : capitaliseFirstLetter(
                                        item.lastJobStatus,
                                      ) || item.status}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageZone;
