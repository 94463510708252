import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const TableComponent = ({
  data = [],
  sortBy = 'department',
  onRowClick = () => {},
  showCharts,
}) => {
  const [id, setId] = useState('1234');
  const [selectedPreStart, setSelectedpreStart] = useState({});
  const [sortedData, setSortedData] = useState({
    'Dust Control': {
      'Water Cart': [
        {
          truck: {
            _id: '633cb0a01066726acbccbe1c',
            name: '721',
            type: 1,
          },
          operator: {
            _id: '63d1d6f9d28aa0001161abe1',
            first_name: 'Big',
            last_name: 'Macca',
            signature:
              'https://steel-serv.s3.ap-southeast-2.amazonaws.com/1692312171387_signature.jpg',
          },
          _id: '64de8d6e65bd4ffe4f94ba08',
          status: 'passed',
          loginTime: '2023-08-17T21:13:18.503Z',
          engineHours: 12291,
          checkpoints: [
            {
              question: 'Air / Hydraulic lines',
              answer: 'ok',
              imagePaths: [],
              _id: '64dea26bc654716b622b157a',
            },
            {
              question: 'V Belts',
              answer: 'ok',
              imagePaths: [],
              _id: '64dea26bc654716b622b157b',
            },
            {
              question: 'Slab cradle',
              answer: 'ok',
              imagePaths: [],
              _id: '64dea26bc654716b622b158a',
            },
            {
              question: 'A frame and safety ropes',
              answer: 'ok',
              imagePaths: [],
              _id: '64dea26bc654716b622b158b',
            },
            {
              question: 'Secondary locks',
              answer: 'ok',
              imagePaths: [],
              _id: '64dea26bc654716b622b158c',
            },
          ],
          __v: 1,
          checkTime: '2023-08-17T22:42:51.364Z',
          refNo: 'PS68',
          assetID: '721',
          failedChecks: '5/31',
          department: 'Dust Control',
          category: 'Water Cart',
        },
      ],
    },
  });

  const getSortedData = () => {
    if (sortBy === 'department') {
      const sortedData = {};
      data.forEach((entry) => {
        if (!sortedData[entry?.department]) {
          sortedData[entry?.department] = {};
        }
        if (!sortedData[entry?.department][entry?.category]) {
          sortedData[entry?.department][entry?.category] = [];
        }
        sortedData[entry?.department][entry?.category].push(entry);
      });

      return sortedData;
    } else if (sortBy === 'category') {
      const sortedData = {};
      data.forEach((entry) => {
        if (!sortedData[entry?.category]) {
          sortedData[entry?.category] = [];
        }
        sortedData[entry?.category].push(entry);
      });
      return sortedData;
    }
  };

  const formatDate = (timestamp, defaultValue = '00:00 AM') => {
    if (isNaN(Date.parse(timestamp))) return defaultValue;
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getLocalTime = (time) => {
    const timeString = formatDate(time);
    var lastTwoCharacters = timeString.slice(-2); // Get the last two characters
    var modifiedTime =
      timeString.slice(0, -2) + lastTwoCharacters.toLowerCase(); // Convert the last two characters to lowercase and replace them
    return modifiedTime;
  };

  const color = {
    passed: '#319844',
    failed: '#E3402F',
    incomplete: '#325D88',
  };

  const generateRandomId = () => {
    return uuidv4();
  };

  useEffect(() => {
    const temp = getSortedData();
    setSortedData(temp);
  }, [data, sortBy]);

  useEffect(() => {
    setId(generateRandomId());
  }, [sortedData]);

  return (
    <div>
      <div
        className={`table_manage_prestart  ${
          sortBy == 'department' ? 'table_dept' : 'table_cat'
        }  `}
      >
        <table cellPadding="0" cellSpacing="0">
          <thead className="fixed-header">
            <tr align="left" valign="top" className=" ">
              <th>AssetID</th>
              {sortBy !== 'department' && <th>Department</th>}
              <th>Status</th>
              <th>Ref No.</th>
              <th>Operator</th>
              <th>
                Login/ <span style={{ color: '#707070' }}> Engine On</span>
              </th>
              <th>Check Time</th>
              <th>Failed Checks</th>
              <th>Engine Hours</th>
              <th>Supervisor's Signature</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className={`table_manage_prestart  ${
          sortBy == 'department' ? 'table_dept' : 'table_cat'
        }   ${showCharts ? 'height-calc' : 'height-calculate'}  }  `}
      >
        <table cellPadding="0" cellSpacing="0">
          <tbody id={id} key={id}>
            {Object.entries(sortedData)?.map(([key, value], index) => {
              if (sortBy === 'department') {
                return (
                  <React.Fragment key={key + index}>
                    <tr align="left" valign="top">
                      <td colSpan="1">
                        <strong>{key}</strong>
                      </td>
                      <td colSpan="9"></td>
                    </tr>
                    {Object.entries(value)?.map(([cat, entries], index) => (
                      <React.Fragment key={cat + index}>
                        <tr align="left" valign="top">
                          <td colSpan="1">
                            <i>{cat}</i>
                          </td>
                          <td colSpan="9"></td>
                        </tr>
                        {entries?.length &&
                          entries?.map((entry, index) => (
                            <tr
                              onClick={() => {
                                setSelectedpreStart(entry);
                                if (entry.status != 'incomplete')
                                  onRowClick({
                                    ...entry,
                                    category: cat,
                                    department: key,
                                  });
                              }}
                              style={
                                JSON.stringify(selectedPreStart) ==
                                  JSON.stringify(entry) &&
                                entry.status != 'incomplete'
                                  ? {
                                      backgroundColor: '#05795F0D',
                                      borderTop: '2px solid #05795F ',
                                      borderBottom: '2px solid #05795F ',
                                    }
                                  : {}
                              }
                              align="left"
                              valign="top"
                              key={entry.assetID + index}
                            >
                              <td>{entry.assetID}</td>
                              <td style={{ color: color[entry.status] }}>
                                <strong>
                                  {' '}
                                  {entry.status?.charAt(0)?.toUpperCase() +
                                    entry.status?.slice(1)}
                                </strong>
                              </td>
                              <td>{entry.refNo ? entry.refNo : '-'}</td>

                              <td>
                                {entry.operator?.first_name}{' '}
                                {entry.operator?.last_name}
                              </td>
                              <td
                                style={{
                                  color:
                                    entry.status == 'incomplete'
                                      ? '#707070'
                                      : '',
                                }}
                              >
                                {getLocalTime(entry.loginTime)}
                              </td>
                              <td>
                                {entry.status != 'incomplete'
                                  ? getLocalTime(entry.checkTime)
                                  : '-'}
                              </td>

                              <td>
                                {entry.status != 'incomplete'
                                  ? entry.failedChecks
                                  : '-'}
                              </td>

                              <td>
                                {entry.engineHours == '0'
                                  ? '00000'
                                  : entry.engineHours}{' '}
                                hrs
                              </td>
                              <td>
                                {entry.supervisorSignature
                                  ? entry.supervisorSignature
                                  : entry.status == 'incomplete'
                                    ? 'N/A'
                                    : '-'}
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={key + index}>
                    <tr>
                      <td colSpan="2">{key}</td>
                    </tr>
                    {value?.length &&
                      value?.map((entry, index) => (
                        <tr
                          onClick={() => {
                            setSelectedpreStart(entry);
                            if (entry.status != 'incomplete') {
                              onRowClick({
                                ...entry,
                                category: key,
                              });
                            }
                          }}
                          style={
                            JSON.stringify(selectedPreStart) ==
                              JSON.stringify(entry) &&
                            entry.status != 'incomplete'
                              ? {
                                  backgroundColor: '#05795F0D',
                                  borderTop: '2px solid #05795F ',
                                  borderBottom: '2px solid #05795F ',
                                }
                              : {}
                          }
                          key={entry.assetID + index}
                        >
                          <td>{entry.assetID}</td>
                          <td>{entry.department}</td>
                          <td style={{ color: color[entry.status] }}>
                            <strong>
                              {' '}
                              {entry.status?.charAt(0)?.toUpperCase() +
                                entry.status?.slice(1)}{' '}
                            </strong>
                          </td>
                          <td>{entry.refNo ? entry.refNo : '-'}</td>
                          <td>
                            {entry.operator?.first_name}{' '}
                            {entry.operator?.last_name}
                          </td>
                          <td
                            style={{
                              color:
                                entry.status == 'incomplete' ? '#707070' : '',
                            }}
                          >
                            {getLocalTime(entry.loginTime)}
                          </td>

                          <td>
                            {entry.status != 'incomplete'
                              ? getLocalTime(entry.checkTime)
                              : '-'}
                          </td>
                          <td>
                            {entry.status != 'incomplete'
                              ? entry.failedChecks
                              : '-'}
                          </td>

                          <td>
                            {entry.engineHours == '0'
                              ? '00000'
                              : entry.engineHours}{' '}
                            hrs
                          </td>
                          <td>
                            {entry.supervisorSignature
                              ? entry.supervisorSignature
                              : entry.status == 'incomplete'
                                ? 'N/A'
                                : '-'}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
