import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { CallWithAuth } from '../../../pages/service/apiActions';
import { EDIT_SOURCE_DUMP, SAVE_SOURCE_DUMP } from '../../action/apiPath';
import PointsTable from '../PointTable/PointTable';
import PolygonForm from '../PointTable/PolygonForm';
import './index.css';
import * as toast from '../../../dustControl/components/toast';

const CreatePolygon = ({
  type,
  module,
  onClose,
  selectedPolygon = { coordinates: [] },
  onEditPolygonPoints = () => {},
  onSelectPoint = () => {},
  reloadForcefully = () => {},
  setPopupMessage = () => {},
}) => {
  const [points, setPoints] = useState([]);
  const [polygonFormData, setPolygonFormData] = useState({});
  const [selectedRow, setSelectedRow] = useState(-1);
  const [isDetailSectionVisible, setIsDetailSectionVisible] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (points[selectedRow]?.latitude && points[selectedRow]?.longitude)
      onSelectPoint(points[selectedRow]);
  }, [selectedRow, points]);

  useEffect(() => {
    setPolygonFormData(selectedPolygon);
    if (
      JSON?.stringify(selectedPolygon?.coordinates) != JSON?.stringify(points)
    ) {
      setPoints(
        selectedPolygon?.coordinates?.length
          ? selectedPolygon?.coordinates
          : [],
      );
    }
  }, [
    selectedPolygon?.coordinates,
    selectedPolygon?.name,
    selectedPolygon?.materials,
    selectedPolygon?.description,
  ]);

  function areAllPairsDifferentandNotZero(data) {
    const pairSet = new Set();
    for (const item of data) {
      const latitude = parseFloat(item.latitude);
      const longitude = parseFloat(item.longitude);

      if (latitude === 0 || longitude === 0) {
        return false;
      }

      const pairStr = `${item.latitude}:${item.longitude}`;

      if (pairSet.has(pairStr)) {
        return false;
      }

      pairSet.add(pairStr);
    }

    return true;
  }

  useEffect(() => {
    const isValidColumns = areAllPairsDifferentandNotZero(points);
    setIsDetailSectionVisible(
      (isValidColumns && type == 'create') || (type == 'edit' && isEdited),
    );
  }, [points]);

  useEffect(() => {
    setIsDetailSectionVisible(areAllPairsDifferentandNotZero(points));
  }, [points]);

  const savePolygon = async () => {
    try {
      const formData = {
        coordinates: points?.map((point) => {
          return {
            latitude: parseFloat(point?.latitude),
            longitude: parseFloat(point?.longitude),
          };
        }),
        type: module,
        materials: polygonFormData?.materials,
        description: polygonFormData?.description,
        name: polygonFormData?.name,
        status: polygonFormData.status === 'Inactive' ? 'Inactive' : 'Active',
      };

      const response =
        _.upperCase(type) === 'EDIT'
          ? await CallWithAuth('POST', EDIT_SOURCE_DUMP, {
              ...formData,
              _id: selectedPolygon?._id,
            })
          : await CallWithAuth('POST', SAVE_SOURCE_DUMP, formData);
      if (response.res.status === 201 || response.res.status === 200) {
        reloadForcefully();
        if (_.upperCase(type) === 'EDIT') {
          setPopupMessage({ message: `Changes Saved Successfully` });
        } else {
          setPopupMessage({
            message: `${_?.capitalize(module)} Point created successfully`,
            footer: `Click ‘Show All ${_?.startCase(
              module,
            )} Points’ to see your newly created source point`,
          });
        }
      } else {
        toast.error(response?.res?.data?.message ?? 'Error', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
        });
      }
    } catch (error) {
      toast.error('Server Error', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <div className="modal-container">
      <div
        style={{
          borderRadius: '0.8rem 0.8rem 0 0',
          background: '#fff',
          borderBottom: '1px solid #707070',
        }}
      >
        <div
          className="d-flex pt-4 pb-2"
          style={{
            font: 'normal normal 600 1rem/1.375rem Open Sans',
            marginLeft: '1.5rem',
          }}
        >
          <div style={{ marginBottom: '0.625rem' }}>
            {' '}
            {_.capitalize(type) == 'Edit' ? 'EDIT' : 'CREATE'}{' '}
            {_.upperCase(module)} POINT
          </div>
        </div>
      </div>
      <div
        className="hide-scrollbar point-form"
        style={{
          overflowY: 'scroll',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
        }}
      >
        <div style={{ width: '30.05rem' }}>
          {_.capitalize(type) == 'Create'
            ? 'Insert coordinates or draw geofence on the map'
            : 'Edit coordinates or modify points on the map'}
        </div>

        <PointsTable
          points={points}
          onChange={(newPoints) => {
            onEditPolygonPoints({ ...selectedPolygon, coordinates: newPoints });
            setPoints(newPoints);
          }}
          onSelectRow={(index) => {
            setSelectedRow(index);
            setIsEdited(true);
          }}
          selectedRow={selectedRow}
          isEditing={(index) => {
            setIsEdited(true);
          }}
        />

        {isDetailSectionVisible && (
          <PolygonForm
            data={polygonFormData}
            onChange={(v) => {
              setPolygonFormData(v);
              setIsEdited(true);
            }}
          />
        )}
      </div>

      <div
        className="d-flex mb-0"
        style={{
          borderTop: '1px solid #EBEBEB',
          width: '100%',
          justifyContent: 'space-between',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
        }}
      >
        <div></div>
        <div className="d-flex justify-content-between mt-3 mb-0">
          <button
            style={{
              color: '#2B2D2F',
            }}
            className="btn button-custom discard btn-focus mx-3 d-flex justify-content-center align-items-center"
            onClick={(v) => {
              onClose();
            }}
          >
            Discard
          </button>
          <button
            className="btn button-custom save ml-3 btn-focus   d-flex justify-content-center align-items-center text-white"
            onClick={() => {
              savePolygon();
            }}
            disabled={!isDetailSectionVisible || !isEdited}
            style={{
              background: '#2B2D2F 0% 0% no-repeat padding-box',
            }}
          >
            {_.capitalize(type) == 'Edit' ? 'Edit' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePolygon;
