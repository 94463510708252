import moment from 'moment';
import { GeneralBinStatus, MODULES, SortStatus } from './types';

export const areBinsSortedByLastEmptiedOrLastUpdated = (
  module,
  selectedOption,
  binSearchQuery,
) => {
  return (
    module === MODULES.BINS &&
    (selectedOption === SortStatus.LAST_EMPTIED ||
      selectedOption === SortStatus.LAST_UPDATED) &&
    !binSearchQuery
  );
};

export const isBinsModuleAndSearchOrSortApplied = (
  module,
  binSearchQuery,
  binSortOption,
) => {
  if (module !== MODULES.BINS) return false;
  if (
    binSortOption === SortStatus.LAST_EMPTIED ||
    binSortOption === SortStatus.LAST_UPDATED ||
    binSearchQuery
  ) {
    return true;
  } else return false;
};

export const isTrucksModuleSearchAndSort = (
  module,
  truckSearchQuery,
  selectedOption,
) =>
  module === MODULES.TRUCKS &&
  (truckSearchQuery || selectedOption === SortStatus.LAST_LOAD);

export const searchAllProperties = (obj, query, restrictedFields) => {
  let foundMatch = false;

  for (const field of restrictedFields) {
    const [key, subKey] = field.split('.');
    const fieldValue = subKey ? obj?.[key]?.[subKey] : obj?.[key];
    // if we split the query by spaces, that allows us to search for full names like 'John Smith' which will be separated across multiple fields
    const individualWordQueries = query.split(' ');
    for (const searchword of individualWordQueries) {
      if (
        typeof fieldValue === 'string' &&
        searchword &&
        fieldValue.toLowerCase().includes(searchword.toLowerCase())
      ) {
        foundMatch = true;
        break;
      }
    }
  }

  return foundMatch;
};

const searchableFieldsForBinList = ['name', 'lastLocation.zone'];

const searchableTruckFields = ['name', 'operator'];

const filterDataByStatus = (data) => {
  let filteredData = [];
  data.forEach((group) => {
    if (
      group?.title !== GeneralBinStatus.IN_PROGRESS &&
      group?.title !== GeneralBinStatus.REQUESTED
    ) {
      if (group?.data !== null) {
        filteredData.push(...(group?.data || []));
      }
    }
  });
  return filteredData;
};

const sortDataByEmptiedTime = (sortedData) => {
  let sortLastEmptiedTime = [];
  let withoutSortLastEmptiedTime = [];

  const filteredOtherData = filterDataByStatus(sortedData);

  let filterData = filteredOtherData?.filter((item) =>
    item.hasOwnProperty('lastEmptiedTime'),
  );
  sortLastEmptiedTime = filterData?.sort(
    (a, b) => new Date(a?.lastEmptiedTime) - new Date(b?.lastEmptiedTime),
  );
  withoutSortLastEmptiedTime = filteredOtherData?.filter(
    (item) => !item.hasOwnProperty('lastEmptiedTime'),
  );

  return sortLastEmptiedTime.concat(withoutSortLastEmptiedTime);
};

const sortDataByUpdatedTime = (sortedData) => {
  let sortLastUpdatedTime = [];
  let withoutSortLastUpdatedTime = [];

  const filteredOtherData = filterDataByStatus(sortedData);

  let filterData = filteredOtherData?.filter((item) =>
    item.hasOwnProperty('lastUpdatedTime'),
  );
  sortLastUpdatedTime = filterData?.sort(
    (a, b) => new Date(a?.lastUpdatedTime) - new Date(b?.lastUpdatedTime),
  );

  withoutSortLastUpdatedTime = filteredOtherData?.filter(
    (item) => !item.hasOwnProperty('lastUpdatedTime'),
  );

  return sortLastUpdatedTime.concat(withoutSortLastUpdatedTime);
};

export const applyDefaultSortAndSearchQueryToBins = (
  data,
  searchQuery,
  selectedOption,
) => {
  let sortedData = [...data];

  switch (selectedOption) {
    default:
    case SortStatus.BIN_LEVEL:
      sortedData.forEach((item) => {
        if (
          item?.title !== GeneralBinStatus.IN_PROGRESS &&
          item?.title !== GeneralBinStatus.REQUESTED
        ) {
          item?.data?.sort((a, b) => {
            if (
              a.hasOwnProperty('lastEmptiedTime') &&
              b.hasOwnProperty('lastEmptiedTime')
            ) {
              if (a.binLevel === b.binLevel) {
                const lastEmptiedTimeA = new Date(a.lastEmptiedTime);
                const lastEmptiedTimeB = new Date(b.lastEmptiedTime);
                return lastEmptiedTimeA - lastEmptiedTimeB;
              } else {
                return a.binLevel - b.binLevel;
              }
            } else {
              if (a.hasOwnProperty('lastEmptiedTime')) {
                return -1;
              } else if (b.hasOwnProperty('lastEmptiedTime')) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        }
      });
      break;
    case SortStatus.LAST_EMPTIED:
      sortedData = sortDataByEmptiedTime(sortedData);
      break;
    case SortStatus.LAST_UPDATED:
      sortedData = sortDataByUpdatedTime(sortedData);
      break;
  }

  sortedData.sort((a, b) => {
    if (a?.title === GeneralBinStatus.IN_PROGRESS) {
      return -1; // IN_PROGRESS comes first
    } else if (b?.title === GeneralBinStatus.IN_PROGRESS) {
      return 1; // IN_PROGRESS comes first
    } else if (a?.title === GeneralBinStatus.REQUESTED) {
      return -1; // REQUESTED comes second
    } else if (b?.title === GeneralBinStatus.REQUESTED) {
      return 1; // REQUESTED comes second
    } else if (a?.title === GeneralBinStatus.MISSING) {
      return -1; // MISSING comes third
    } else if (b?.title === GeneralBinStatus.MISSING) {
      return 1; // MISSING comes third
    }
    return 0;
  });

  if (searchQuery.length >= 1) {
    if (
      selectedOption === SortStatus.LAST_EMPTIED ||
      selectedOption === SortStatus.LAST_UPDATED
    ) {
      // data is already flatmapped if these sorts have been applied
      sortedData = sortedData?.filter((item) =>
        searchAllProperties(item, searchQuery, searchableFieldsForBinList),
      );
    } else {
      sortedData = sortedData?.flatMap((group) =>
        group?.data.filter((item) =>
          searchAllProperties(item, searchQuery, searchableFieldsForBinList),
        ),
      );
    }
  }
  return sortedData;
};

const sortDataBylastLoadTime = (sortedData) => {
  let sortLastLoadTime = [];
  let withoutSortLastLoadTime = [];

  const filteredOtherData = sortedData;
  let filterData = filteredOtherData?.filter((item) =>
    item.hasOwnProperty('lastLoad'),
  );

  sortLastLoadTime = filterData?.sort(
    (a, b) => new Date(a?.lastLoad) - new Date(b?.lastLoad),
  );
  withoutSortLastLoadTime = filteredOtherData?.filter(
    (item) => !item.hasOwnProperty('lastLoad'),
  );
  return sortLastLoadTime.concat(withoutSortLastLoadTime);
};

export const applyDefaultSortAndSearchQueryToTrucks = (
  data,
  searchQuery,
  selectedOption,
) => {
  let sortedData = [...data];
  switch (selectedOption) {
    default:
    case SortStatus.TRUCK_AVAILABILITY:
      sortedData.forEach((item) => {});
      break;
    case SortStatus.TRUCK_STATUS:
      break;
    case SortStatus.LAST_LOAD:
      sortedData = sortDataBylastLoadTime(sortedData);
      break;
  }
  if (searchQuery.length >= 1) {
    sortedData = data?.filter((item) =>
      searchAllProperties(item, searchQuery, searchableTruckFields),
    );
  }
  return sortedData;
};

export const convertMins = (minutes) => {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  let timeString = '';
  if (hrs === 1) timeString = '1 hour ';
  else if (hrs) timeString = `${hrs} hours `;
  if (mins === 1) timeString += '1 min';
  else if (mins) timeString += `${mins} mins`;
  return timeString;
};

export const getFirstLetter = (name) => {
  return name?.substring(0, 1).toUpperCase();
};

export const startOfDay = (date) => {
  const parts = Intl.DateTimeFormat('en-NZ', {
    timeZone: 'Pacific/Auckland',
    hourCycle: 'h23',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).formatToParts(new Date(date));

  const hour = parseInt(parts?.find((i) => i?.type === 'hour')?.value);
  const minute = parseInt(parts?.find((i) => i.type === 'minute')?.value);
  const second = parseInt(parts?.find((i) => i.type === 'second')?.value);
  return new Date(
    1000 *
      Math.floor(
        (new Date(date).getTime() -
          hour * 3600000 -
          minute * 60000 -
          second * 1000) /
          1000,
      ),
  );
};

export const endOfDay = (...args) =>
  new Date(startOfDay(args[0]).getTime() + 86399999);

export const groupBy = (list, key) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [curr[key]]: [...(prev[key] || []), curr],
    };
  }, {});
};

export function getOrdinal(n) {
  if (typeof n === 'string') {
    n = parseInt(n);
  }
  let ord = ['st', 'nd', 'rd'];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(n % 10) - 1] === undefined || exceptions.includes(n % 100)
      ? 'th'
      : ord[(n % 10) - 1];
  return n + nth;
}

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateDDMMYYYY = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatDateToISO = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const groupByDate = (list, key) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [formatDate(curr[key])]: [...(prev[formatDate(curr[key])] || []), curr],
    };
  }, {});
};

export const getTime = (time) => {
  return new Date(time).toLocaleTimeString('en-NZ', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let res = '';

  if (hours > 0 || minutes > 0) {
    if (hours === 1) {
      res += '1 hour ';
    } else if (hours > 0) {
      res += hours + ' hour ';
    }
    if (minutes > 0) {
      res += minutes + ' min';
    }
  } else {
    res += '0 min';
  }

  return res;
};

export const generatePin = () => {
  var numbers = Math.floor(1000 + Math.random() * 9000);
  return numbers;
};
export const currentDate = (date) => {
  let dayName = new Date(date).toLocaleDateString('en-NZ', { weekday: 'long' });
  let monthName = new Date(date).toLocaleDateString('en-NZ', { month: 'long' });
  let day = new Date(date).toLocaleDateString('en-NZ', {
    day: 'numeric',
  });
  let dayFormatTh = getOrdinal(day);

  return dayName + ' ' + dayFormatTh + ' ' + monthName;
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const minuteToHour = (minutes) => Math.round(minutes / 60);

export const formatDateToDDMMYYYY = (date) => {
  if (!date) return '';
  const convertedToDate = new Date(date);
  const day = String(convertedToDate.getDate()).padStart(2, '0');
  const month = String(convertedToDate.getMonth() + 1).padStart(2, '0');
  const year = convertedToDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const capitaliseFirstLetter = (word) => {
  if (!word) {
    return '';
  }
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const convertCodeListToNames = (entireList, selected) => {
  if (!selected) return 'None';
  if (selected.length === 0) return 'None';

  const filteredList = entireList
    .filter(({ value, code }) => selected.includes(value ?? code))
    .map(({ name }) => name);
  if (filteredList.length === 0) return 'None';
  if (filteredList.length === entireList.length) return 'All';
  else {
    return filteredList.join(', ');
  }
};
