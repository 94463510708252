import { AxiosError, CanceledError, GenericAbortSignal } from 'axios';
import { API, apiHeaders } from '../pages/service/apiHandler';
import { API_METHODS } from '../dustControl/utils/constants';
import { setAccessToken } from '../pages/service/accessToken';

type Response<T> = {
  data: T;
  status: number;
  statusText?: string;
  message: string;
};
type FetchParams = {
  endPoint: string;
  method: (typeof API_METHODS)[keyof typeof API_METHODS];
  reqBody?: Record<string, unknown>;
  blobType?: boolean;
  signal?: GenericAbortSignal;
  withCredentials?: boolean;
  reqParams?: Record<string, unknown>;
  navigateToLoginPage: () => void;
};
export const fetch = async <T>({
  endPoint,
  method,
  reqBody,
  reqParams,
  blobType = false,
  signal,
  withCredentials,
  navigateToLoginPage = () => {},
}: FetchParams): Promise<Response<T>> => {
  const acceptType = 'application/json';
  const contentType =
    reqBody instanceof FormData ? 'multipart/form-data' : 'application/json';
  try {
    let content = {
      method: method.toUpperCase(),
      headers: apiHeaders(reqBody, contentType, acceptType),
    };

    let stringifiedRequestBody: string | undefined;
    if (
      method === 'post' ||
      method === 'delete' ||
      method === 'patch' ||
      method === 'put'
    ) {
      if (reqBody instanceof FormData) {
      } else stringifiedRequestBody = JSON.stringify(reqBody);
    }
    const response = await API({
      url: endPoint,
      data: stringifiedRequestBody,
      headers: content.headers,
      method: content.method,
      responseType: blobType ? 'arraybuffer' : 'json',
      signal,
      withCredentials: withCredentials ?? false,
      params: reqParams,
    });

    if (response.headers['content-type'] === 'application/pdf') {
      const configuredResponse: Response<T> = {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
        message: response.data?.message,
      };
      return configuredResponse;
    } else {
      const configuredResponse: Response<T> = {
        data: response.data.data,
        status: response.data?.statusCode,
        statusText: response.data?.status,
        message: response.data?.message,
      };
      return configuredResponse;
    }
  } catch (err) {
    const errorData: Partial<Response<T>> = {
      status: 505,
      message: 'Something went wrong',
    };
    //@ts-ignore
    errorData.message = err?.response?.data?.message ?? 'Something went wrong';

    if (err instanceof AxiosError && err.response) {
      errorData.status = err.response.status;
      if (err.response?.status === 403) {
        // Client has invalid jwt
        // User should be logged out
        setAccessToken('');
        errorData.message = 'Your session has expired. Please log in again';
        navigateToLoginPage();
        // may need to return, not throw. but if returning make sure nothing happens
        throw errorData;
      } else if (err.response?.status !== 500) {
        // Do not display api response message if status 500 (server error)
        errorData.message =
          err.response.data?.message ?? 'Something went wrong';
      }
    } else if (err instanceof CanceledError) {
      /* A CanceledError occurs when the request is aborted
      In this scenario we should not show an error message to the client
      So we set the error message to undefined*/
      errorData.message = undefined;
      throw errorData;
    }
    console.error('fetch function threw error that was not AxiosError: ', err);
    throw errorData;
  }
};
