import React from 'react';

interface Tab {
  label: string;
  onClick: () => void;
}

interface TabProps {
  tabs: Tab[];
  selectedTabValue: string | null;
  loading?: boolean;
}

const tabStyle = (isSelected: boolean, isLoading?: boolean) => ({
  fontSize: '12px',
  backgroundColor: isSelected ? '#2B2D2F' : 'transparent',
  color: isSelected ? '#FFFFFF' : '#6E6E6E',
  borderRadius: 4,
  transition: 'color 0.5s ease',
  cursor: isLoading ? 'not-allowed' : 'pointer',
});

const styles = {
  backgroundColor: 'white',
  border: '1px solid #E3E3E3',
  borderRadius: 4,
};

const Tabs: React.FC<TabProps> = ({ tabs, selectedTabValue, loading }) => {
  return (
    <div style={styles} className="d-flex">
      {tabs.map((tab) => (
        <div
          style={tabStyle(selectedTabValue === tab.label, loading)}
          onClick={() =>
            !loading && selectedTabValue !== tab.label && tab.onClick()
          }
          title={tab.label}
          className="px-2 py-1"
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
