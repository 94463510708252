import moment from 'moment';
import { Load } from '../../types';
import { Interval } from './types';
import { DateTimeFormats } from '../../constants';

/**
 * Processes loads into intervals based on their end times.
 * @param loads Array of loads to be processed
 * @param withDate Whether to include date in interval description
 * @returns Array of intervals with load distribution
 */
export const processLoads = (loads: Load[], withDate = false): Interval[] => {
  const intervals: any = {};

  // Process each load and place it in the appropriate interval
  loads.forEach((load) => {
    const { endTime, tonnes = 0 } = load;
    const endMoment = moment(endTime);
    const intervalKey = endMoment.format('YYYY-MM-DD HH');

    if (!intervals[intervalKey]) {
      intervals[intervalKey] = {
        interval:
          `${withDate ? endMoment.format(DateTimeFormats.DATE) : ''} ${endMoment.format('hh:00 A')} - ${endMoment.add(1, 'hour').format('hh:00 A')}`.trim(),
        loadsCompleted: 1,
        totalTonnes: tonnes,
        loads: [load],
      };
    } else {
      intervals[intervalKey].loadsCompleted += 1;
      intervals[intervalKey].totalTonnes += tonnes;
      intervals[intervalKey].loads.push(load);
    }
  });

  return Object.values(intervals);
};

//temp function
export const processLoads2 = (loads: Load[]) => {
  // Initialize the result array with 24 objects for each hour interval
  const intervals = Array.from({ length: 24 }, (_, i) => {
    const startHour = String(i % 12 || 12).padStart(2, '0') + ':00';
    const endHour = String((i + 1) % 12 || 12).padStart(2, '0') + ':00';
    const startPeriod = i < 12 ? 'AM' : 'PM';
    const endPeriod = (i + 1) % 24 < 12 ? 'AM' : 'PM';
    return {
      interval: `${startHour} ${startPeriod} - ${endHour} ${endPeriod}`,
      start: `${startHour} ${startPeriod}`,
      end: `${endHour} ${endPeriod}`,
      loadsCompleted: 0,
      totalTonnes: 0,
      loads: [] as Load[],
    };
  });

  intervals.reverse();
  // Process each load and place it in the appropriate interval
  loads.forEach((load: any) => {
    const endHour = new Date(load.endTime).getHours();
    intervals[23 - endHour].loadsCompleted++;
    intervals[23 - endHour].totalTonnes += load.tonnes || 0;
    intervals[23 - endHour].loads.push(load);
  });

  return intervals;
};

/**
 * Formats the start and end time into a readable interval format.
 * @param startTime Start time of the interval
 * @param endTime End time of the interval
 * @returns Formatted interval string with duration
 */
export const formatTimeForInterval = (
  startTime: Date,
  endTime: Date,
): string => {
  const formattedStartTime = formatTimePartForInterval(startTime);
  const formattedEndTime = formatTimePartForInterval(endTime);
  const duration = formatDurationForInterval(
    endTime.getTime() - startTime.getTime(),
  );

  return `${formattedStartTime} - ${formattedEndTime} ( ${duration} h )`;
};

/**
 * Formats a given time into a readable time format with AM/PM.
 * @param time Time to be formatted
 * @returns Formatted time string in HH:mm AM/PM format
 */
export const formatTimePartForInterval = (time: Date): string => {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  return `${formattedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${period}`;
};

/**
 * Formats duration in milliseconds into HH:mm:ss format.
 * @param duration Duration in milliseconds
 * @returns Formatted duration string in HH:mm:ss format
 */
export const formatDurationForInterval = (duration: number): string => {
  const hours = Math.floor(duration / 3600000);
  const minutes = Math.floor((duration % 3600000) / 60000);
  const seconds = Math.floor((duration % 60000) / 1000);
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
