import { ChangeEvent, FC } from 'react';
import StatusDot from '../../../dustControl/components/StatusDot';
import { BIN, BINS, BIN_FILTERS } from '../../utils/constants';
import { BinWithPickup } from '../../utils/types';
import { countBinStatus } from '../../utils/helpers';
import './styles.scss';

type BinFilterProps = {
  setFilter: any;
  queries: string[];
  data: BinWithPickup[];
};
const BinFilter: FC<BinFilterProps> = (props) => {
  const handleBinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    props.setFilter(
      (prev: URLSearchParams) => {
        let binFilterQueries = new Set(...[props.queries]);
        // If the name is 'Show All', select all checkboxes
        if (value === 'Show All') {
          if (checked)
            BIN_FILTERS.forEach((filter) =>
              binFilterQueries.add(filter.name as string),
            );
          else binFilterQueries.clear();
        } else {
          // Add or remove the value from the Set based on whether the checkbox is checked or not
          if (checked) binFilterQueries.add(value);
          else binFilterQueries.delete(value);
        }
        // Convert the Set back to a string
        const binFilterArrays = Array.from(binFilterQueries)
          .filter((filter) => Boolean(filter))
          .join(',');
        // Set the new value
        prev.set('b', binFilterArrays);
        return prev;
      },
      { replace: true },
    );
  };

  return (
    <>
      {BIN_FILTERS.map(({ id, name, color }) => (
        <li className="py-1" key={id}>
          <div className="d-flex align-content-center gap-2 highlightHover">
            <input
              checked={Boolean(
                props.queries.includes(name?.toString() as string),
              )}
              value={name?.toString()}
              id={id.toString()}
              type="checkbox"
              onChange={handleBinChange}
            />
            {id === 0 ? (
              <span>{name}</span>
            ) : (
              <StatusDot style={{ background: color }} className="large">
                <span className="fw-bold">
                  {name} ({countBinStatus(props.data, name)}{' '}
                  {countBinStatus(props.data, name) > 1 ? BINS : BIN})
                </span>
              </StatusDot>
            )}
          </div>
        </li>
      ))}
    </>
  );
};

export default BinFilter;
