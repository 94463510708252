// export const REACT_APP_API_ENDPOINT = "https://bathurst-shift-api.imos.co.nz";
// export const BASE_URL = "https://bathurst-shift-api.imos.co.nz";
// const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const REACT_APP_API_ENDPOINT = '/';
// export const REACT_APP_API_ENDPOINT = "http://localhost:3002/";
export const BASE_URL = '/material-movements/api';

// export const REACT_APP_API_ENDPOINT = "http://bathurst-shift-api.imos.co.nz:3000";
// export const BASE_URL = "http://bathurst-shift-api.imos.co.nz:3000";

export const GET_USER_DETAILS = (id) => `${BASE_URL}/viewsingleuser?id=${id}`; //GET
export const GEt_ALL_USER = `${BASE_URL}/viewalluser`; // GET query param ?NUMBER_OF_ITEMS=20&PAGE_NUMBER=1
export const GEt_ALL_USER_WITH_FILTER = `${BASE_URL}/searchuser`; // POST

export const GET_USER_ACCOUNT_DETAILS = `${BASE_URL}/getmyaccount`; //GET

export const DELETE_MULTIPLE_USER = `${BASE_URL}/deletemultipleuser`; //POST
export const DEACTIVATE_MULTIPLE_USER = `${BASE_URL}/deactivateuser`; //POST

export const DELETE_USER = (id) => `${BASE_URL}/deletesingleuser?id=${id}`; //POST

export const GET_MATERIALS = `${BASE_URL}/getmaterialdetails`; //GET
export const GET_POLYGONS = ({ module, date }) =>
  `${BASE_URL}/date/${module}/loads?date=${date}`; //GET
export const GET_TRUCK_LOADS = ({ date }) =>
  `${BASE_URL}/date/loads?date=${date}`; //GET

export const GET_ASSET_PERFORMANCE = `${BASE_URL}/getAssetPerformance`; //POST
export const GET_DUMP_CYCLE_DATA = `${BASE_URL}/getDumpCycleData`; //POST

export const GET_ALL_TRUCKS_EXCAVATORS = `${BASE_URL}/getAllTrucksAndExcevators`; //GET
export const GET_ALL_SOURCE_AND_DUMPS = `${BASE_URL}/getAllSourceAndDumpPoint`; //GET
export const GET_ALL_OPERATORS = `${BASE_URL}/viewalluser`; //GET

export const SAVE_LOGIN_LOGOUT_TIME = `${BASE_URL}/availabletime`; //POST
export const GET_PRESENT_TOMMOROW_DATA = `${BASE_URL}/openweather/get-current-weather`; //GET
export const GET_PRESENT_FORECAST_DATA = `${BASE_URL}/openweather/forecast`; //GET

// Data from Webhook
export const GET_WEBHOOK_DATA = `${BASE_URL}/fetchWeatherData`; //GET

// Generate PDF
export const GENERATE_PDF = `${BASE_URL}/generatepdf`; //POST

//dumPoints
export const SAVE_SOURCE_DUMP = `${BASE_URL}/source-dump/save`; //POST
export const GET_SOURCE_DUMPS = `${BASE_URL}/getsourcedumps`; //GET
export const EDIT_SOURCE_DUMP = `${BASE_URL}/source-dump/edit`; //POST
export const DELETE_SOURCE_DUMP = `${BASE_URL}/source-dump/delete`; //POST

export const GET_HISTORY = `${BASE_URL}/date/loads`; // GET
// shift history
export const GET_SHIFT_HISTORY = `${BASE_URL}/shift-history`; // GET
// export const END_ALL_SHIFTS = `${BASE_URL}/end-shifts`; // PUT
export const END_SHIFT = `${BASE_URL}/end-shift`; // PUT
