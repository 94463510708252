import { useEffect, useState } from 'react';
import { SocketRoom, WebSocketURL } from '../utils/constants';

export const useSocket = <SocketResponse>(
  socketRoom: SocketRoom,
  handleResponseCallback: (responseData: SocketResponse) => void,
  clientName?: string,
) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    const connect = (url: string): WebSocket => {
      console.debug('Connecting to ', url);
      const newSocket = new WebSocket(url);
      setSocket(newSocket);
      newSocket.onerror = (error: Event) => {
        console.error('WebSocket error:', error);
      };
      return newSocket;
    };

    let socketUrl = WebSocketURL + socketRoom;
    if (clientName) socketUrl += `&clientName=${clientName}`;
    let createdSocket: WebSocket = connect(socketUrl);
    let heartbeatInterval: NodeJS.Timeout = setInterval(() => {
      if (createdSocket.readyState === WebSocket.OPEN) {
        createdSocket.send('heartbeat');
      } else {
        createdSocket = connect(socketUrl);
      }
    }, 25000);

    return () => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
      console.debug('Closing socket', socketUrl);

      createdSocket.close();
    };
  }, [clientName, socketRoom]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event: MessageEvent) => {
        const message = event?.data;
        const responseData: SocketResponse = JSON.parse(message);
        console.debug('Socket message received', responseData);
        handleResponseCallback(responseData);
      };
    }
  }, [handleResponseCallback, socket]);
};
