import { ChangeEvent, FC, Fragment } from 'react';
import StatusDot from '../../../dustControl/components/StatusDot';
import {
  TRUCK_FILTERS,
  TRUCK_FILTER_VALUES,
  generalBinStatusColors,
} from '../../utils/constants';
import { GetAllTrucksData } from '../../utils/types';
import { countTruckStatus, getTruckText } from '../../utils/helpers';
import './styles.scss';

type TruckFilterProps = {
  setFilter: any;
  queries: string[];
  data: GetAllTrucksData;
};

const TruckFilter: FC<TruckFilterProps> = (props) => {
  const handleTruckChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    props.setFilter(
      (prev: URLSearchParams) => {
        let truckFilterQueries = new Set(...[props.queries]);
        if (TRUCK_FILTER_VALUES[value]) {
          TRUCK_FILTER_VALUES[value].forEach((filterValue) => {
            if (checked) truckFilterQueries.add(filterValue);
            else truckFilterQueries.delete(filterValue);
          });
        } else {
          if (checked) truckFilterQueries.add(value);
          else truckFilterQueries.delete(value);
        }

        const truckFilterArrays = Array.from(truckFilterQueries)
          .filter((filter) => Boolean(filter))
          .join(',');

        prev.set('t', truckFilterArrays);
        return prev;
      },
      { replace: true },
    );
  };

  return (
    <>
      {TRUCK_FILTERS.map(({ id, name, color, value }) => (
        <Fragment key={id}>
          {id === 4 && (
            <hr
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                borderColor: 'grey',
                marginLeft: '-1rem',
                marginRight: '-0.5rem',
              }}
              className="border"
            ></hr>
          )}

          <li className="py-1" key={id}>
            <div className="d-flex align-content-center gap-2 highlightHover">
              <input
                style={{ accentColor: generalBinStatusColors['25%'] }}
                checked={Boolean(props.queries.includes(value))}
                value={value}
                id={id.toString()}
                type="checkbox"
                onChange={handleTruckChange}
              />
              {id === 0 || id === 4 ? (
                <span>{name}</span>
              ) : (
                <StatusDot style={{ background: color }} className="large">
                  <span className="fw-bold">
                    {name} ({countTruckStatus(props.data, value)}{' '}
                    {getTruckText(props.data, value)})
                  </span>
                </StatusDot>
              )}
            </div>
          </li>
        </Fragment>
      ))}
    </>
  );
};

export default TruckFilter;
